<template>
  <div style="width: 100%;margin: 10px; display: inline-block;">
    <div v-for="item in tickets.tickets" :key="item.id" class="aaa">
      <div class="red-wrap">
        <div
          :class="
            Number(item.expireTime) >= Number(new Date().getTime())
              ? 'red-left'
              : 'red-left_1'
          "
        >
          <div class="money" @click="aaa">{{ item.ticketPrice }}</div>
          <div class="circle"></div>
        </div>
        <div
          :class="
            Number(item.expireTime) >= Number(new Date().getTime())
              ? 'red-right'
              : 'red-right_1'
          "
        >
          <div class="tips">
            <p>{{ tickets.name }}--现金券</p>
            <p>用于买票抵扣</p>
            <p>
              <span>截止时间：{{ item.expireTime | dateFormat }}</span>
            </p>
          </div>
          <div
            :class="
              Number(item.expireTime) >= Number(new Date().getTime())
                ? 'wave wave-in'
                : 'wave wave-in_1'
            "
          ></div>
          <div
            :class="
              Number(item.expireTime) >= Number(new Date().getTime())
                ? 'wave wave-out'
                : 'wave wave-out_1'
            "
          ></div>
        </div>
        <el-tooltip
          effect="dark"
          content="编辑"
          placement="top"
          :enterable="false"
        >
          <!-- 修改按钮 -->
          <el-button
            type="success"
            icon="el-icon-edit"
            size="mini"
            plain
            circle
            @click="showEditDialog(item.id)"
            style="margin-left:12px;margin-top:26px;"
          ></el-button>
        </el-tooltip>
        <!-- 删除按钮 -->
        <el-tooltip
          effect="dark"
          content="删除优惠券"
          placement="top"
          :enterable="false"
        >
          <el-button
            type="danger"
            icon="fa fa-trash"
            size="mini"
            plain
            circle
            style="margin-left:12px;margin-top:4px;"
            @click="removeById(item.id)"
          ></el-button>
        </el-tooltip>
      </div>
    </div>
    <!-- 修改信息的对话框 -->
    <el-dialog
      title="编辑"
      :visible.sync="editDialogVisible"
      width="92%"
      :modal="false"
      @close="editDialogClosed"
    >
      <el-form
        :model="editFrom"
        :rules="editFromRules"
        ref="editFromRef"
        label-width="90px"
      >
        <el-form-item label="劵值">
          <el-input v-model="editFrom.ticketPrice" placeholder="必填：格式尽量0.00"></el-input>
        </el-form-item>
        <el-form-item label="过期时间" width="120px">
          <el-date-picker
            v-model="editFrom.date"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUserInfo">修 改</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    var checkPrice = (rule, value, cb) => {
      if (value >= 0 && value <= 40) {
        return cb()
      }
      cb(new Error('请输入合法的数值！'))
    }
    return {
      tickets: {},
      editDialogVisible: false,
      editFrom: {},
      editFromRules: {
        price: [
          { validator: checkPrice, trigger: 'blur' }
        ]
      }
    }
  },
  props: {
    layerid: {
      // 自动注入的layerid
      type: String,
      default: ''
    },
    iframeData: {
      // 传递的数据
      type: Object,
      default: () => {
        return {}
      }
    },
    lydata: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    async showEditDialog(id) {
      this.editFrom.id = id
      this.editDialogVisible = true
    },
    async getAll() {
      const { data: res } = await this.$http.get(
        `/users/${this.lydata.iframeData.id}`
      )
      console.log(res)
      if (res.code !== '20000') {
        return this.$layer.msg('获取参数失败')
      }
      // this.addForm.username = res.data.username
      // this.addForm.userPhone = res.data.Phone
      // this.addForm.city = res.data.city
      // this.addForm.email = res.data.email
      this.tickets = res.data.tickets
      this.$layer.msg('获取用户信息成功')
    },
    aaa() {
      console.log('--------------')
      console.log(this.lydata.iframeData.id)
    },
    async removeById(id) {
      // 弹窗展示是否删除数据
      const confirmResult = await this.$confirm(
        '此操作将永久删除优惠卷, 是否继续?',
        '提示',
        {
          confirmButtonText: '朕要删除',
          cancelButtonText: '算了',
          type: 'warning'
        }
      ).catch(err => err)

      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除！')
      }
      const { data: res } = await this.$http.delete('userTicket/' + id)
      if (res.code !== '20000') {
        this.$message.error(res.message)
      }
      this.$message.success(res.message)
      this.getAll()
    },
    async editUserInfo() {
      var date = new Date(String(this.editFrom.date).replace(/-/g, '/'))
      var time = date.getTime()
      const { data: res } = await this.$http.put(
        'userTicket/' + this.editFrom.id,
        {
          date: time,
          price: this.editFrom.ticketPrice
        }
      )
      if (res.code !== '20000') {
        this.$message.error(res.message)
      }
      this.editDialogVisible = false
      this.getAll()
      this.$message.success(res.message)
    },
    editDialogClosed() {
      this.$refs.editFromRef.resetFields()
    }
  },
  created() {
    this.getAll()
  },
  mounted() {}
}
</script>
<style lang="less" scoped>
.el-input__inner {
  width: 100px !important;
}
.el-dialog {
  z-index: 999 !important;
}
.aaa {
  margin-left: 16px;
  height: 150px;
}
* {
  margin: 0;
  padding: 0;
}
.red-wrap:before,
.red-wrap:after {
  display: table;
  content: '';
}
.red-wrap:after {
  clear: both;
}
.red-left_1,
.red-right_1 {
  float: left;
  position: relative;
  height: 110px;
  background-color: #ada8a8;
}

.red-left,
.red-right {
  float: left;
  position: relative;
  height: 110px;
  background-color: #ff7373;
}

.red-left {
  width: 80px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.red-left_1 {
  width: 80px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.money {
  text-align: right;
  color: #fff;
  padding-right: 10px;
  line-height: 110px;
  font-size: 22px;
}
.circle {
  position: absolute;
  width: 20px;
  height: 20px;
  left: -10px;
  top: 50%;
  margin-top: -10px;
  border-radius: 50%;
  background: #fff;
}
.red-right {
  width: 140px;
  padding-left: 1em;
  padding-right: 1em;
  margin-left: 1px;
}
.red-right_1 {
  width: 140px;
  padding-left: 1em;
  padding-right: 1em;
  margin-left: 1px;
}
.tips {
  max-width: 10em;
  margin-top: 0.5em;
}
.tips p {
  line-height: 1.5;
  color: #fff;
}
.tips span {
  font-size: 12px;
}
.wave {
  position: absolute;
  top: 0;
  width: 9px;
  height: 100%;
}
.wave-in {
  right: 0;
  background-size: 18px 36px;
  background-repeat: repeat-y;
  background-position: 2px -2px;
  background-image: radial-gradient(
    circle,
    #fff,
    #fff 8px,
    #fff 0,
    #ff7373 9px,
    #ff7373 0,
    #ff7373
  );
}

.wave-in_1 {
  right: 0;
  background-size: 18px 36px;
  background-repeat: repeat-y;
  background-position: 2px -2px;
  background-image: radial-gradient(
    circle,
    #fff,
    #fff 8px,
    #fff 0,
    #ada8a8 9px,
    #ada8a8 0,
    #ada8a8
  );
}
.wave-out {
  right: -9px;
  background-size: 20px 36px;
  background-position: -11px -20px;
  background-image: radial-gradient(
    circle,
    #ff7373,
    #ff7373 9px,
    transparent 10px,
    transparent 10px,
    transparent 10px,
    transparent
  );
}

.wave-out_1 {
  right: -9px;
  background-size: 20px 36px;
  background-position: -11px -20px;
  background-image: radial-gradient(
    circle,
    #ada8a8,
    #ada8a8 9px,
    transparent 10px,
    transparent 10px,
    transparent 10px,
    transparent
  );
}
</style>
