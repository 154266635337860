<template>
  <div>
    <el-popover
      v-if="isShow"
      slot="extra"
      placement="bottom"
      trigger="hover"
      style="margin-right:20px;padding:0px;"
    >
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="处理工单" name="first">
          <div style="width:380px;padding:20px;">
            <div style="display:flex;">
              <span>标题：</span
              ><el-input
                style="width:240px;"
                size="mini"
                type="text"
                placeholder=""
                v-model="workOrder.title"
                maxlength="30"
                show-word-limit
              >
              </el-input>
            </div>
            <div style="display:flex;">
              <span>内容：</span
              ><el-input
                style="width:240px;margin-top:12px;"
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 6 }"
                placeholder="请描述你的问题，管理会在当天处理回复"
                v-model="workOrder.content"
                maxlength="200"
                show-word-limit
              >
              </el-input>
            </div>
            <el-button
              type="primary"
              size="mini"
              style="margin-top:10px;"
              @click="saveWork"
              >提交</el-button
            >
          </div>
        </el-tab-pane>
        <el-tab-pane label="已提交工单" name="second">
          <div
            style="width:400px;max-height:400px;padding:20px;overflow: scroll;"
          >
            <el-table :data="works" style="width: 100%">
              <el-table-column prop="createTime" label="创建日期" width="140">
              </el-table-column>
              <el-table-column
                prop="adminName"
                label="提交用户"
                width="140"
              ></el-table-column>
              <el-table-column width="80px" label="是否处理">
                <template slot-scope="scope">
                  <!-- {{scope.row}} -->
                  <el-switch
                    v-model="scope.row.status"
                    @change="usersChanged(scope.row)"
                  >
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column
                :filter-method="filterTag"
                :filters="[
                  { text: '已处理', value: '1' },
                  { text: '未处理', value: '0' }
                ]"
                prop="status"
                label="是否处理"
                width="140"
              >
                <template slot-scope="scope">
                  <el-tag type="success" v-if="scope.row.status == 1"
                    >已处理</el-tag
                  >
                  <el-tag type="warning" v-else>未处理</el-tag>
                </template>
              </el-table-column>
              <el-table-column
                fixed="right"
                prop="title"
                label="标题"
                width="120"
              >
              </el-table-column>
              <el-table-column prop="content" label="内容" width="600">
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
      <span slot="reference" class="header-wo">
        <i class="fa fa-file-text-o"></i><font>&nbsp;处理工单</font>
      </span>
    </el-popover>
    <el-popover
      v-else
      slot="extra"
      placement="bottom"
      trigger="hover"
      style="margin-right:20px;padding:0px;"
    >
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="提交工单" name="first">
          <div style="width:380px;padding:20px;">
            <div style="display:flex;">
              <span>标题：</span
              ><el-input
                style="width:240px;"
                size="mini"
                type="text"
                placeholder=""
                v-model="workOrder.title"
                maxlength="30"
                show-word-limit
              >
              </el-input>
            </div>
            <div style="display:flex;">
              <span>内容：</span
              ><el-input
                style="width:240px;margin-top:12px;"
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 6 }"
                placeholder="请描述你的问题，管理会在当天处理回复"
                v-model="workOrder.content"
                maxlength="200"
                show-word-limit
              >
              </el-input>
            </div>
            <el-button
              type="primary"
              size="mini"
              style="margin-top:10px;"
              @click="saveWork"
              >提交</el-button
            >
          </div>
        </el-tab-pane>
        <el-tab-pane label="已提交工单" name="second">
          <div
            style="width:400px;max-height:400px;padding:20px;overflow: scroll;"
          >
            <el-table :data="works" style="width: 100%">
              <el-table-column prop="createTime" label="创建日期" width="140">
              </el-table-column>
              <el-table-column
                :filter-method="filterTag"
                :filters="[
                  { text: '已处理', value: '1' },
                  { text: '未处理', value: '0' }
                ]"
                prop="status"
                label="是否处理"
                width="140"
              >
                <template slot-scope="scope">
                  <el-tag type="success" v-if="scope.row.status == 1"
                    >已处理</el-tag
                  >
                  <el-tag type="warning" v-else>未处理</el-tag>
                </template>
              </el-table-column>
              <el-table-column
                fixed="right"
                prop="title"
                label="标题"
                width="120"
              >
              </el-table-column>
              <el-table-column prop="content" label="内容" width="600">
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
      <span slot="reference" class="header-wo">
        <i class="fa fa-file-text-o"></i><font>&nbsp;提交工单</font>
      </span>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: 'workOrder',
  props: ['isShow'],
  data() {
    return {
      activeName: 'first',
      workOrder: {
        title: '',
        content: ''
      },
      works: []
    }
  },
  mounted() {
    // this.getWork()
  },
  methods: {
    async saveWork() {
      if (this.workOrder.title === '' || this.workOrder.content === '') {
        return this.$antMessage.info('提交标题内容不能为空')
      }
      const { data: res } = await this.$http.post('work/submit', this.workOrder)
      if (res.code !== '20000') {
        return this.$antMessage.error(res.message)
      }
      this.$antMessage.success('提交成功')
    },
    async getWork() {
      const { data: res } = await this.$http.get('works')
      if (res.code !== '20000') {
        return this.$antMessage.error(res.message)
      }
      this.works = res.data.lists
      console.log(this.works)
      console.log(res)
      this.$antMessage.success('获取数据成功')
    },
    handleClick(tab, event) {
      if (this.activeName === 'second') {
        this.getWork()
      }
    },
    // 监听开关状态的改变
    async usersChanged(userInfo) {
      const { data: res } = await this.$http.put(
        `works/${userInfo.workId}/state/${userInfo.status}`
      )
      if (res.code !== '20000') {
        userInfo.status = !userInfo.status
        return this.$message.error('更新状态失败!')
      }
      this.$message.success('更新状态成功！')
    },
    filterTag(value, row) {
      // console.log(value)
      // console.log(row)
      // eslint-disable-next-line eqeqeq
      return row.status == value
    }
  }
}
</script>

<style lang="less" scoped>
.header-wo {
  cursor: pointer;
  color: rgba(83, 81, 81, 0.8);
  vertical-align: middle;
  font-size: 15px;
  line-height: 60px;
}
.head_middle {
  width: 266px;
  border-left: 2px solid rgba(194, 190, 190, 0.5);
  border-right: 2px solid rgba(194, 190, 190, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  right: 80px;
  span {
    padding-left: 0px;
  }
  img {
    /*border-radius: 50%;*/
  }
}
</style>
