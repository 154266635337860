<template>
  <div id="container" style=" width: 100%; height: 300px;"></div>
</template>

<script>
// import { getBlogContributeCount } from '@/api/index'
import * as echarts from 'echarts'

export default {
  async mounted() {
    // this.data = this.getData(2021)
    await this.getStatisyics()
    this.initDate()
  },
  data() {
    return {
      contributeDate: ['2021-01-01', '2022-01-01'],
      blogContributeCount: [],
      data: [],
      stime: '2021-01-01',
      etime: '2022-01-01'
    }
  },
  created() {
    // this.getStatisyics()
  },
  methods: {
    initDate: function() {
      // getBlogContributeCount().then(response => {
      // console.log('我来啦', response.data.blogContributeCount)
      // eslint-disable-next-line eqeqeq
      // eslint-disable-next-line no-constant-condition
      if (true) {
        // var contributeDate = response.data.contributeDate

        var blogContributeCount = this.data
        var yearRange = this.contributeDate

        const chart = echarts.init(document.getElementById('container'))

        const option = {
          // 设置背景
          // backgroundColor: '#d0d0d0',

          title: {
            top: 30,
            text: '访客(UV)数量统计',
            subtext: '一年内每天的访客数量',
            left: 'center',
            textStyle: {
              color: '#000'
            }
          },
          tooltip: {
            trigger: 'item',
            formatter: function(params) {
              return params.data[0] + '<br>访客数：' + params.data[1]
            }
          },
          legend: {
            top: '30',
            left: '100',
            data: ['访客数', 'Top 12'],
            textStyle: {
              // 设置字体颜色
              color: '#000'
            }
          },
          calendar: [
            {
              top: 100,
              left: 'center',
              range: yearRange,
              splitLine: {
                show: true,
                lineStyle: {
                  // 设置月份分割线的颜色
                  color: '#D3D3D3',
                  width: 4,
                  type: 'solid'
                }
              },
              yearLabel: { show: false },
              dayLabel: {
                nameMap: [
                  '周一',
                  '周二',
                  '周三',
                  '周四',
                  '周五',
                  '周六',
                  '周日'
                ], // 设置中文显示
                textStyle: {
                  // 设置周显示颜色
                  color: '#000'
                }
              },
              monthLabel: {
                nameMap: 'cn', // 设置中文显示
                textStyle: {
                  // 设置月显示颜色
                  color: '#000'
                }
              },
              itemStyle: {
                normal: {
                  // 设置背景颜色
                  color: '#ffffff',
                  borderWidth: 1,
                  // 设置方块分割线段颜色
                  borderColor: '#D3D3D3'
                }
              }
            }
          ],
          series: [
            {
              name: '访客数',
              type: 'scatter',
              coordinateSystem: 'calendar',
              data: blogContributeCount,
              // 根据值设置原点大小
              symbolSize: function(val) {
                // eslint-disable-next-line eqeqeq
                if (val[1] == 0) {
                  return val[1]
                } else {
                  let size = val[1] * 2

                  if (size > 18) {
                    size = 18
                  }
                  return size
                }
              },
              itemStyle: {
                normal: {
                  // 设置圆点颜色
                  // color: '#2ec7c9'
                  color: '#248826'
                }
              }
            }
          ]
        }

        chart.setOption(option)
      }
      // })
    },
    getData: function(year) {
      year = year || '2017'
      var date = +echarts.number.parseDate(year + '-01-01')
      var end = +echarts.number.parseDate(+year + 1 + '-01-01')
      var dayTime = 3600 * 24 * 1000
      var data = []
      for (var time = date; time < end; time += dayTime) {
        data.push([
          echarts.format.formatTime('yyyy-MM-dd', time),
          Math.floor(Math.random() * 100)
        ])
      }
      return data
    },
    async getStatisyics() {
      // eslint-disable-next-line no-extend-native
      Date.prototype.format = function(fmt) {
        var o = {
          'M+': this.getMonth() + 1,
          'd+': this.getDate(),
          'h+': this.getHours(),
          'm+': this.getMinutes(),
          's+': this.getSeconds(),
          'q+': Math.floor((this.getMonth() + 3) / 3),
          S: this.getMilliseconds()
        }
        // eslint-disable-next-line curly
        if (/(y+)/.test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            (this.getFullYear() + '').substr(4 - RegExp.$1.length)
          )
        // eslint-disable-next-line curly
        // eslint-disable-next-line curly
        for (var k in o)
        // eslint-disable-next-line curly
          if (new RegExp('(' + k + ')').test(fmt))
            fmt = fmt.replace(
              RegExp.$1,
              // eslint-disable-next-line eqeqeq
              RegExp.$1.length == 1
                ? o[k]
                : ('00' + o[k]).substr(('' + o[k]).length)
            )
        return fmt
      }
      const day1 = new Date()
      day1.setDate(day1.getDate() + 25)
      const start = day1.format('yyyy-MM-dd')
      day1.setDate(day1.getDate() - 365)
      const end = day1.format('yyyy-MM-dd')
      console.log(start, end)
      const { data: res } = await this.$http.get(
        `getStatisticCount/${end}/${start}`
      )
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      this.data = res.data.data
      this.contributeDate[0] = res.data.data1.stime
      this.contributeDate[1] = res.data.data1.etime
    }
  }
}
</script>
