<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商家管理</el-breadcrumb-item>
      <el-breadcrumb-item>订单列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图区域 -->
    <el-card>
      <el-row>
        <el-col :span="6">
          <el-input
            placeholder="试试搜索 订单编号"
            v-model="queryInfo.query"
            clearable
            size="small"
            @clear="getOrderList()"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              :style="active"
              @mouseover.native="mouseOver()"
              @mouseout.native="mouseLeave()"
              @click="getOrderList()"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button
            style="margin-left:6px"
            type="success"
            round
            size="mini"
            plain
            @click="bindTableHead"
            >固定表头</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-dropdown>
            <el-button type="primary" size="mini">
              导出订单<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown" class="project-dropdown">
              <el-dropdown-item v-for="item in pdflist" :key="item.goodsId"
                ><a
                  style="text-decoration: none;color: #333"
                  :href="
                    'http://api.shunhou.wandoukeji.com/api/v1/download?pid=' +
                      item.goodsId +
                      '&token=' +
                      token
                  "
                  >{{ item.placeIn + '➡' + item.placeOut }}</a
                ></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <el-col :span="2">
          <el-tag size="small" type="warning"
            >选中票数：{{ total_ticket }}</el-tag
          >
        </el-col>
        <el-col :span="2">
          <el-button
            type="success"
            size="mini"
            plain
            icon="fa fa-pencil"
            @click="batchRefund()"
            >&nbsp;批量退款</el-button
          ></el-col
        >
        <el-col :span="3">
          <el-button
            type="success"
            size="mini"
            plain
            icon="fa fa-pencil"
            @click="updateGrabStatus()"
            >&nbsp;批量更新抢票状态</el-button
          >
        </el-col>
        <el-col :span="6">
          <a-radio-group
            style="margin-left: 12px;margin-top:3px;"
            v-model="grabStatus"
          >
            <a-radio :value="1">成功</a-radio>
            <a-radio :value="2">失败</a-radio>
            <a-radio :value="3">终止</a-radio>
            <a-radio :value="4">抢票中</a-radio>
          </a-radio-group>
        </el-col>
      </el-row>
      <el-row>
        <br />
        <el-button
          type="primary"
          plain
          icon="fa fa-minus-square"
          size="mini"
          @click="toggleSelection()"
          >&nbsp;取消选择</el-button
        >
        <el-button
          type="primary"
          size="mini"
          plain
          icon="fa fa-paint-brush"
          @click="hanleCarCart()"
          >&nbsp;修改车牌号</el-button
        >
        <!-- <el-button
          type="primary"
          size="mini"
          plain
          icon="fa fa-pencil"
          @click="hanleCarLocation()"
          >&nbsp;批量重置评价状态</el-button
        > -->
        <el-button type="prmary" size="mini" plain
          ><a
            style="text-decoration: none;color:#63ADF7;"
            :href="
              multipleSelection.length == 0
                ? 'javascript:void(alert(`请先选择订单`));'
                : 'https://api.shunhou.wandoukeji.com/api/v1/downloads?ids=' +
                  multipleSelection +
                  '' +
                  '&token=' +
                  token
            "
            >导出选择文件</a
          ></el-button
        >
        <el-button type="parmary" plain size="mini" @click="queryOrderInfo()"
          >查询付款情况</el-button
        >
        <el-button type="parmary" plain size="mini" @click="resetTravelStatus()"
          >批量重置状态</el-button
        >
        <el-button type="parmary" plain size="mini" @click="saveXlsx()"
          >导出excel</el-button
        >
        <el-cascader
          style="margin-left: 12px;"
          size="small"
          placeholder="试试搜索一下"
          :options="treeList"
          v-model="queryId"
          :props="{
            expandTrigger: 'hover',
            value: 'tagId',
            label: 'name',
            emitPath: false,
            checkStrictly: false
          }"
          @change="handleTreeList"
          clearable
          filterable
        ></el-cascader>
        <a-radio-group style="margin-left: 12px;" v-model="grabMark">
          <a-radio :value="1">全部</a-radio>
          <a-radio :value="2">正常</a-radio>
          <a-radio :value="4">抢票</a-radio>
        </a-radio-group>
      </el-row>

      <!-- 订单列表数据 -->
      <el-table
        ref="multipleTable"
        :data="orderlist"
        style="width: 100%"
        border
        stripe
        id="zzz"
        :height="zzz"
        show-summary
        :summary-method="getSummaries"
        @selection-change="handleSelectionChange"
      >
        <!-- <el-table-column type="index" label="#"></el-table-column> -->
        <el-table-column type="selection" width="45"> </el-table-column>
        <!-- table 扩展区域 -->
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="创建时间（下单时间）">
                <span class="expand_text">{{ props.row.createTime }}</span>
              </el-form-item>
              <br />
              <el-form-item label="更新时间">
                <span class="expand_text">{{ props.row.updateTime }}</span>
              </el-form-item>
              <br />
              <el-form-item label="是否使用优惠券">
                <span
                  :class="
                    props.row.orderStatus ? 'expand_text1' : 'expand_text2'
                  "
                  >{{ props.row.orderStatus ? '是' : '否' }}</span
                >
              </el-form-item>
              <br />
              <el-form-item label="付款方式">
                <span
                  :class="
                    props.row.orderPay == '2' ? 'expand_text1' : 'expand_text2'
                  "
                  >{{ props.row.orderPay == '2' ? '微信' : '其他' }}</span
                >
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column label="订单编号" width="162px" prop="orderNumber"
          ><template slot-scope="scope">
            {{ scope.row.orderNumber }}
            <el-button
              class="copyBtn"
              size="small"
              data-test="test..."
              :data-clipboard-text="scope.row.orderNumber"
              icon="el-icon-copy-document"
            ></el-button> </template
        ></el-table-column>
        <el-table-column label="商品单价" prop="orderPrice" width="90px">
          <template slot-scope="scope">
            <el-tag size="medium" type="success"
              >{{ scope.row.orderPrice }}&nbsp;元</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          label="购买数量"
          prop="orderSum"
          width="70px"
        ></el-table-column>
        <!---<el-table-column
          label="PID"
          prop="productId"
          width="50px"
          :filter-method="filterTag1"
          :filters="filterArray"
        ></el-table-column>--->
        <el-table-column
          label="抵扣金额"
          prop="orderPayDiscount"
          width="70px"
        ></el-table-column>
        <el-table-column label="上车车牌号" width="108px">
          <template slot-scope="scope">
            <el-tag size="medium" type="primary">{{
              scope.row.carCard ? scope.row.carCard : '后台未设置'
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="tag名称" width="108px">
          <template slot-scope="scope">
            <el-tag size="medium" type="warning">{{
              scope.row.tagName ? scope.row.tagName : '暂无数据'
            }}</el-tag>
          </template>
        </el-table-column>
        <!-- <el-table-column label="优惠券使用" width="60px" prop="payStatus">
          <template slot-scope="scope">
            <el-button
              style="margin:0px"
              type="primary"
              size="mini"
              plain
              v-if="scope.row.orderStatus"
              circle
              >是</el-button
            >
            <el-button
              style="margin:0px"
              type="danger"
              size="mini"
              plain
              v-else
              circle
              >否</el-button
            >
          </template>
        </el-table-column> -->
        <el-table-column label="是否通过分享购买" width="80px" prop="payStatus">
          <template slot-scope="scope">
            <i
              class="el-icon-success"
              v-if="scope.row.shareId > 0"
              style="color: lightgreen; font-size: 20px;"
            ></i>
            <i
              class="el-icon-error"
              v-else
              style="color: red;font-size: 20px;"
            ></i>
          </template>
        </el-table-column>
        <el-table-column label="抢票状态" width="80px" prop="grabStatus">
          <template slot-scope="scope">
            <span v-if="scope.row.grabStatus == '1'">
              <i
                class="el-icon-success"
                style="color: green;font-size: 20px;"
              ></i>
              成功
            </span>
            <span v-else-if="scope.row.grabStatus == '2'">
              <i class="el-icon-error" style="color: red;font-size: 20px;"></i>
              失败
            </span>
            <span v-else-if="scope.row.grabStatus == '3'">终止</span>
            <span v-else-if="scope.row.grabStatus == '4'">抢票中</span>
          </template>
        </el-table-column>
        <el-table-column
          label="是否付款"
          width="90px"
          prop="payStatus"
          :filter-method="filterTag"
          :filters="[
            { text: '已付款', value: '1' },
            { text: '未付款', value: '0' },
            { text: '已退款', value: '4' },
            { text: '部分退款', value: '5' },
            { text: '退款中', value: '2' }
          ]"
        >
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.payStatus == '1'"
              >已付款</el-tag
            >
            <el-tag type="primary" v-else-if="scope.row.payStatus == '2'"
              >退款中</el-tag
            >
            <el-tag type="primary" v-else-if="scope.row.payStatus == '3'"
              >未退款</el-tag
            >
            <el-tag type="primary" v-else-if="scope.row.payStatus == '4'"
              >已退款</el-tag
            >
            <el-tag type="info" v-else-if="scope.row.payStatus == '5'"
              >部分退款</el-tag
            >
            <el-tag type="danger" v-else>未付款</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="支付方式" width="96px" prop="orderPay">
          <template slot-scope="scope">
            <el-tag type="warning" v-if="scope.row.orderPay == '4'"
              >抢票方式</el-tag
            >
            <el-tag type="primary" v-else-if="scope.row.orderPay == '1'"
              >支付宝</el-tag
            >
            <el-tag type="success" v-else-if="scope.row.orderPay == '2'"
              >微信</el-tag
            >
            <el-tag type="info" v-else>银行卡</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="总付费" width="90px">
          <template slot-scope="scope">
            <el-tag size="medium" type="primary"
              >{{
                Number(
                  scope.row.orderPrice * scope.row.orderSum -
                    scope.row.orderPayDiscount
                )
              }}&nbsp;元</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="部分退费(元)" width="90px">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.refundPrice != null && scope.row.refundPrice != ''" size="medium" type="warning"
              >{{ scope.row.refundPrice }}&nbsp;元</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="出行日期" width="100px" prop="createTime">
          <template slot-scope="scope">
            {{ scope.row.departureTime | timeFormat }}
          </template>
        </el-table-column>
        <el-table-column width="86px" label="是否出行">
          <template slot-scope="scope">
            <!-- {{scope.row}} -->
            <el-switch
              v-model="scope.row.isExpire"
              @change="usersChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column width="86px" label="是否评价">
          <template slot-scope="scope">
            <!-- {{scope.row}} -->
            <el-tag type="primary">{{scope.row.isStar?"已评价":"未评价"}}</el-tag>
            <!-- <el-switch
              v-model="scope.row.isStar"
              @change="usersChanged1(scope.row)"
            >
            </el-switch> -->
          </template>
        </el-table-column>
        <el-table-column fixed="right" width="210px" label="操作">
          <!--  -->
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              circle
              plain
              @click="showBox(scope.row.orderId)"
            ></el-button>
            <el-tooltip
              effect="dark"
              content="查看该订单班次详情"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="success"
                icon="fa fa-eye"
                size="mini"
                circle
                plain
                @click="
                  showProductDetail(scope.row.productId, scope.row.orderNumber)
                "
              ></el-button>
            </el-tooltip>
            <el-tooltip
              effect="dark"
              content="查看上车地址"
              placement="top"
              :enterable="false"
            >
              <el-button
                plain
                type="warning"
                icon="fa fa-square-o"
                size="mini"
                circle
                @click="showPeopleDetail(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              effect="dark"
              content="设置该订单购买用户为该班次核校者"
              placement="top"
              :enterable="false"
            >
              <el-button
                plain
                type="info"
                icon="fa fa-pencil-square-o"
                size="mini"
                circle
                @click="hexiao(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              effect="dark"
              content="查看该订单抢票情况"
              placement="top"
              :enterable="false"
            >
              <el-button
                v-if="scope.row.orderPay == '4'"
                plain
                type="info"
                icon="fa fa-pencil"
                size="mini"
                circle
                @click="getGrabList(scope.row.orderNumber)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[10, 20, 50, 100, 130]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 查看用户抢票列表 -->
    <a-modal
      width="70%"
      v-drag-modal
      v-model="visiblegrab"
      title="抢票日志"
      @ok="handleOk"
    >
      <template slot="footer">
        <a-button
          style="background-color: #58d1ff;border:none;"
          type="primary"
          @click="handleOk"
        >
          关闭
        </a-button>
      </template>
      <a-alert type="info" message="加速包数量 就是该好友共助力的数量" banner />
      <el-table :data="grabList" stripe border>
        <el-table-column type="index" width="50" label="#"> </el-table-column>
        <el-table-column prop="currentName" label="昵称"> </el-table-column>
        <el-table-column label="头像" prop="avatar" width="66px">
          <template slot-scope="scope">
            <el-avatar
              :size="'samll'"
              :src="scope.row.currentAvatar"
            ></el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="packageNum" label="加速包数量">
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间"> </el-table-column>
        <!-- <el-table-column prop="browser" label="浏览器"> </el-table-column>
        <el-table-column prop="os" label="操作系统"> </el-table-column>
        <el-table-column prop="type" label="登录方式"> </el-table-column> -->
      </el-table>
    </a-modal>

    <!-- 修改用户的对话框 -->
    <el-dialog
      title="修改订单信息"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <!-- 提示区域 -->
      <el-alert
        title="订单状态： 0未付款、1已付款、2退款中、3未退款、4已退款 付款方式: 2(微信) 0(未支付)"
        type="info"
        center
        show-icon
        :closable="false"
        style="margin-bottom: 10px;"
      >
      </el-alert>
      <el-form
        :model="editFrom"
        :rules="editFromRules"
        ref="editFromRef"
        label-width="90px"
      >
        <el-form-item label="商品单价" prop="orderPrice">
          <el-input v-model="editFrom.orderPrice"></el-input>
        </el-form-item>
        <el-form-item label="是否付款" prop="payStatus">
          <el-input v-model="editFrom.payStatus"></el-input>
        </el-form-item>
        <el-form-item label="支付方式" prop="orderPay">
          <el-input v-model="editFrom.orderPay"></el-input>
        </el-form-item>
        <el-form-item label="数量" prop="orderSum">
          <el-input v-model="editFrom.orderSum"></el-input>
        </el-form-item>
        <el-form-item label="上车车牌号" prop="carCard">
          <el-input v-model="editFrom.carCard"></el-input>
        </el-form-item>
        <el-form-item label="上车地址" prop="choosePlace">
          <el-input v-model="editFrom.choosePlace"></el-input>
        </el-form-item>
        <el-form-item label="下单时间">
          <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="editFrom.createTime"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUserInfo">修 改</el-button>
      </span>
    </el-dialog>

    <!-- 批量修改上车车牌号 -->
    <el-dialog
      title="批量修改上车车牌号"
      :visible.sync="editDialogVisible1"
      width="30%"
      @close="editDialogClosed1"
    >
      <!-- 提示区域 -->
      <el-alert
        title="修改你所选中的所有订单上车车牌号"
        type="info"
        center
        show-icon
        :closable="false"
        style="margin-bottom: 10px;"
      >
      </el-alert>
      <el-form
        :model="editFrom1"
        :rules="editFromRules"
        ref="editFromRef1"
        label-width="90px"
      >
        <el-form-item label="上车车牌号" prop="carCard">
          <el-input v-model="editFrom1.carCard" style="width:260px;"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="editUserInfo1">修 改</el-button>
      </span>
    </el-dialog>

    <!-- 批量修改上车地址 -->
    <el-dialog
      title="批量修改上车地址"
      :visible.sync="editDialogVisible2"
      width="30%"
      @close="editDialogClosed2"
    >
      <!-- 提示区域 -->
      <el-alert
        title="修改你所选中的所有订单上车地址"
        type="info"
        center
        show-icon
        :closable="false"
        style="margin-bottom: 10px;"
      >
      </el-alert>
      <el-form
        :model="editFrom2"
        :rules="editFromRules"
        ref="editFromRef1"
        label-width="90px"
      >
        <el-form-item label="上车地址" prop="carCard">
          <el-input
            v-model="editFrom2.choosePlace"
            style="width:260px;"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="editUserInfo2">修 改</el-button>
      </span>
    </el-dialog>

    <!-- 根据订单号查询订单结果 -->
    <el-dialog
      title="根据订单号查询支付结果"
      :visible.sync="editDialogVisible3"
      width="35%"
      @close="editDialogClosed3"
    >
      <!-- 提示区域 -->
      <el-alert
        title="输入订单号实时请求微信支付平台查询支付结果"
        type="info"
        center
        show-icon
        :closable="false"
        style="margin-bottom: 10px;"
      >
      </el-alert>
      <el-form
        :model="editFrom3"
        :rules="editFromRules"
        ref="editFromRef1"
        label-width="90px"
      >
        <el-form-item label="商家订单号" prop="orderNo">
          <el-input v-model="editFrom3.orderNo" style="width:360px;"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible3 = false">取 消</el-button>
        <el-button type="success" @click="editUserInfo3">查 询</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Qs from 'qs'
import $ from 'jquery'
import AreaJson from './area.json'
import Clipboard from 'clipboard'
export default {
  name: 'Order',
  data() {
    return {
      refunUUID: '',
      grabStatus: 4,
      grabMark: 1,
      grabList: [],
      visiblegrab: false,
      multipleSelection: [],
      filterArray: [],
      total_ticket: 0,
      editDialogVisible1: false,
      editDialogVisible2: false,
      editDialogVisible3: false,
      editFrom1: {},
      editFrom2: {},
      editFrom3: {},
      queryInfo: {
        query: '',
        pagenum: 1,
        pagesize: 10
      },
      active: '',
      total: 0,
      orderlist: [],
      pdflist: [],
      zzz: undefined,
      // 修改地址的控制
      addressVisible: false,
      editDialogVisible: false,
      addressForm: {
        address1: [],
        address2: ''
      },
      addressRules: {
        address1: [
          { required: true, message: '请选择省市区县', trigger: 'blur' }
        ],
        address2: [
          { required: true, message: '请填写详细地址', trigger: 'blur' }
        ]
      },
      editFrom: {},
      editFromRules: {
        goodsName: [
          { required: true, message: '请输入商品名称', trigger: 'blur' },
          {
            min: 2,
            max: 20,
            message: '商品名称的字符长度在 2~20 个字符之间',
            trigger: 'blur'
          }
        ],
        placeIn: [
          { required: true, message: '请输入出发地', trigger: 'blur' },
          {
            min: 1,
            max: 24,
            message: '出发地的字符长度在 1~24 个字符之间',
            trigger: 'blur'
          }
        ],
        placeOut: [
          { required: true, message: '请输入目的地', trigger: 'blur' },
          {
            min: 1,
            max: 24,
            message: '目的地的字符长度在 1~24 个字符之间',
            trigger: 'blur'
          }
        ]
      },
      areaData: [],
      progressVisible: false,
      progressInfo: [],
      token: '',
      treeList: [],
      queryId: 0,
      clipboard: null
    }
  },
  created() {
    this.getOrderList()
    this.getPdfList()
    this.token = window.sessionStorage.getItem('token')
    this.getTreeList()
    // this.areaData = AreaJson
    // for (var i = 0; i < this.areaData.length; i++) {
    //   if (this.areaData[i].children.length === 0) {
    //     delete this.areaData[i].children
    //   }
    // }
    this.clipboard = new Clipboard('.copyBtn')
    this.clipboard.on('success', this.successFunc)
    this.clipboard.on('error', this.errorFunc)
  },
  methods: {
    async batchRefund() {
      if (this.multipleSelection.length === 0) {
        return this.$message.info('请先选中操作对象')
      }
      const { data: res } = await this.$http.get('/grab/verify')
      // eslint-disable-next-line eqeqeq
      if (res.code == '20000') {
        this.$message.success(res.message)
        this.refunUUID = res.data.uuid
        const that = this
        this.$prompt('身份校验', '请输入发送手机的验证码', {
          confirmButtonText: '输入金额',
          cancelButtonText: '取消'
        })
          .then(async ({ value }) => {
            const code = value
            // 输入退款金额
            that
              .$prompt('退款金额', '什么都不填视为全款退款', {
                confirmButtonText: '批量退款',
                cancelButtonText: '取消'
              })
              .then(async ({ value }) => {
                const { data: res } = await this.$http.post(
                  `/grab/refund/batch`,
                  Qs.stringify({
                    orderIds: this.multipleSelection + '',
                    uuid: this.refunUUID,
                    code: code,
                    refundPrice: value
                  })
                )
                if (res.code !== '20000') {
                  this.$message({
                    type: 'error',
                    message: res.message
                  })
                  return false
                } else {
                  this.$message({
                    type: 'success',
                    message: res.message
                  })
                  return false
                }
              })
              .catch(() => {
                this.$message({
                  type: 'info',
                  message: '你取消了操作'
                })
              })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '你取消了操作'
            })
          })
      } else {
        this.$message.error(res.message)
      }
    },
    async updateGrabStatus() {
      if (this.multipleSelection.length === 0) {
        return this.$message.info('请先选中操作对象')
      }
      const { data: res } = await this.$http.post(
        `grab/status`,
        Qs.stringify({
          orderIds: this.multipleSelection + '',
          text: this.grabStatus
        })
        // text: this.editFrom1.carCard
      )
      // eslint-disable-next-line eqeqeq
      if (res.code == '20000') {
        this.$message.success(res.message)
      } else {
        this.$message.error(res.message)
      }
    },
    async getGrabList(orderNo) {
      // this.visiblegrab = true
      const { data: res } = await this.$http.get('/order/grab/' + orderNo)
      if (res.code === '20000') {
        this.grabList = res.data.list
        this.$message.success(res.message)
        this.visiblegrab = true
      } else {
        this.$message.error(res.message)
      }
      console.log(res)
    },
    // 对话框关闭调用事件
    handleOk(e) {
      console.log(e)
      this.visiblegrab = false
    },
    saveXlsx() {
      var fix = document.querySelector('.el-table__fixed-right')
      var wb
      if (fix) {
        // eslint-disable-next-line no-undef
        wb = XLSX.utils.table_to_book(
          document.querySelector('#zzz').removeChild(fix)
        )
        document.querySelector('#zzz').appendChild(fix)
      } else {
        // eslint-disable-next-line no-undef
        wb = XLSX.utils.table_to_book(document.querySelector('#zzz'))
      }
      // eslint-disable-next-line no-undef
      const wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array'
      })
      try {
        // eslint-disable-next-line no-undef
        saveAs(
          new Blob([wbout], { type: 'application/octet-stream' }),
          'wdkj-order.xlsx'
        )
      } catch (e) {
        if (typeof console !== 'undefined') console.log(e, wbout)
      }
    },
    async hexiao(row) {
      console.log(row)
      this.$confirm('确定要设置该订单购买用户核校该班次吗？')
        .then(async _ => {
          const { data: res } = await this.$http.post(
            `verify/${row.orderNumber}`
          )
          // eslint-disable-next-line eqeqeq
          if (res.code == '20000') {
            this.$message.success(res.message)
            this.$message.success(`共涉及${res.data.sum}条数据`)
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(_ => {})
    },
    successFunc(e) {
      // console.info('Action:', e.action)
      // console.info('Text:', e.text)
      // console.info('Trigger:', e.trigger)
      // // 可以取到目标元素上的自定义属性（可以据此再做一些处理）
      // e.trigger.dataset.test && console.log(e.trigger.dataset.test)
      // // 清除选中状态
      // e.clearSelection()

      this.$antMessage.success('恭喜您复制成功，赶快去粘贴吧~')
      // this.$notify({
      //   title: '成功',
      //   message: '恭喜您复制成功，赶快去粘贴吧~',
      //   type: 'success',
      //   showClose: false
      // })
    },
    errorFunc(e) {
      this.$antMessage.error('复制失败！！')
      // console.error('Action:', e.action)
      // console.error('Trigger:', e.trigger)

      // this.$notify.error({
      //   title: '失败',
      //   message: '操作失败，请重试！',
      //   showClose: false
      // })
    },
    async handleTreeList(value) {
      const { data: res } = await this.$http.get(
        `/tags/order/${value}/${this.grabMark}`
      )
      console.log(res)
      // eslint-disable-next-line eqeqeq
      if (res.code == '20000') {
        this.total = res.data.total
        this.orderlist = res.data.list
      }
    },
    async resetTravelStatus() {
      // eslint-disable-next-line eqeqeq
      if (this.multipleSelection.length == 0) {
        this.$message.info('你还未选中任何订单，请先选中')
        return false
      }
      // 弹窗展示是否删除数据
      const confirmResult = await this.$confirm(
        '此操作将会批量重置订单的出行状态为已出行, 是否继续操作?',
        '提示',
        {
          confirmButtonText: '是，朕已经决定了',
          cancelButtonText: '还是算了',
          type: 'warning'
        }
      ).catch(err => err)

      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除！')
      }
      const { data: res } = await this.$http.post(
        `orders/resetStatusByIds`,
        Qs.stringify({
          orderIds: this.multipleSelection + ''
        })
        // text: this.editFrom1.carCard
      )
      // eslint-disable-next-line eqeqeq
      if (res.code == '20000') {
        this.$message.success(res.message)
      } else {
        this.$message.error(res.message)
      }
      this.getOrderList()
    },
    filterTag(value, row) {
      // console.log(value)
      // console.log(row)
      // eslint-disable-next-line eqeqeq
      return row.payStatus == value
    },
    filterTag1(value, row) {
      // console.log(value)
      // console.log(row)
      // eslint-disable-next-line eqeqeq
      return row.productId == value
    },
    async editUserInfo3() {
      // eslint-disable-next-line eqeqeq
      if (this.editFrom3.orderNo == undefined || this.editFrom3.orderNo == '') {
        return this.$message.info('订单号不能为空')
      }
      const { data: res } = await this.$http.get(
        `queryPay?oid=` + this.editFrom3.orderNo
      )
      // eslint-disable-next-line eqeqeq
      if (res.code == '20000') {
        this.editDialogVisible3 = false
        var ip = res.data.data.attach
        this.$alert(
          '付款ip：' +
            ip +
            '  查询结果:' +
            res.data.data.result_code +
            '  订单状态：' +
            res.data.data.trade_state +
            ' (' +
            res.data.data.trade_state_desc +
            ')',
          '查询结果',
          {
            confirmButtonText: '确定',
            callback: action => {}
          }
        )

        return
      }
      this.$message.error(res.message)
    },
    queryOrderInfo() {
      this.editDialogVisible3 = true
    },
    async editUserInfo1() {
      // eslint-disable-next-line eqeqeq
      if (this.editFrom1.carCard == undefined || this.editFrom1.carCard == '') {
        this.$message.info('参数不能为空')
        return false
      }
      const { data: res } = await this.$http.post(
        `orders/updateByIds`,
        Qs.stringify({
          orderIds: this.multipleSelection + '',
          text: this.editFrom1.carCard
        })
        // text: this.editFrom1.carCard
      )
      // eslint-disable-next-line eqeqeq
      if (res.code == '20000') {
        this.$message.success(res.message)
      } else {
        this.$message.error(res.message)
      }
      this.editDialogVisible1 = false
      this.getOrderList()
    },
    async editUserInfo2() {
      // eslint-disable-next-line eqeqeq
      if (
        // eslint-disable-next-line eqeqeq
        this.editFrom2.choosePlace == undefined ||
        // eslint-disable-next-line eqeqeq
        this.editFrom2.choosePlace == ''
      ) {
        this.$message.info('参数不能为空')
        return false
      }
      const { data: res } = await this.$http.post(
        `orders/updatePlaceByIds`,
        Qs.stringify({
          orderIds: this.multipleSelection + '',
          text: this.editFrom2.choosePlace
        })
        // text: this.editFrom1.carCard
      )
      // eslint-disable-next-line eqeqeq
      if (res.code == '20000') {
        this.$message.success(res.message)
      } else {
        this.$message.error(res.message)
      }
      this.editDialogVisible2 = false
      this.getOrderList()
    },

    hanleCarLocation() {
      // eslint-disable-next-line eqeqeq
      if (this.multipleSelection.length == 0) {
        this.$message.info('你还未选中任何订单，请先选中')
        return false
      }
      this.editDialogVisible2 = true
    },
    hanleCarCart() {
      // eslint-disable-next-line eqeqeq
      if (this.multipleSelection.length == 0) {
        this.$message.info('你还未选中任何订单，请先选中')
        return false
      }
      this.editDialogVisible1 = true
    },
    editDialogClosed1() {
      this.editDialogVisible1 = false
    },
    editDialogClosed2() {
      this.editDialogVisible2 = false
    },
    editDialogClosed3() {
      this.editDialogVisible3 = false
    },
    // 对选择的数组进行处理
    handleSelectionChange(val) {
      var listTemp = []
      var total = 0
      val.forEach(item => {
        listTemp.push(Number(item.orderId))
        total += parseInt(item.orderSum)
      })
      this.multipleSelection = listTemp
      this.total_ticket = total
    },
    toggleSelection() {
      this.$refs.multipleTable.clearSelection()
      this.total_ticket = 0
    },
    async getOrderList() {
      const { data: res } = await this.$http.get('orders', {
        params: this.queryInfo
      })
      console.log(res)
      if (res.code !== '20000') {
        return this.$message.error('请求数据失败！')
      }
      this.total = Number(res.data.total)
      this.orderlist = res.data.lists
      this.$message.success('获取数据成功！')
      this.orderlist.forEach(item => {
        var index = this.filterArray.findIndex(v => v.text === item.productId)
        if (index < 0) {
          this.filterArray.push({
            text: item.productId,
            value: item.productId
          })
        }
      })
    },
    async getPdfList() {
      const { data: res } = await this.$http.get('getProductInfo/info')
      if (res.code !== '20000') {
        return this.$message.error('获取数据失败！')
      }
      this.pdflist = res.data.list
    },
    async getTreeList() {
      const { data: res } = await this.$http.get('products/gettree')
      console.log(res)
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      console.log('================-----', res)
      this.treeList = res.data.list
    },
    editDialogClosed() {
      this.$refs.editFromRef.resetFields()
    },
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize
      this.getOrderList()
    },
    mouseOver() {
      this.active = 'background-color: #ccc;'
      //   console.log('鼠标移动事件')
    },
    mouseLeave() {
      this.active = ''
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage
      this.getOrderList()
    },
    // 展示修改地址的对话框
    async showBox(id) {
      console.log(id)
      const { data: res } = await this.$http.get(`getOrderInfo/${id}`)
      console.log(res)
      if (res.code !== '20000') {
        return this.$message.error('查询商品失败！')
      }
      this.$message.success('查询商品成功！')
      this.editFrom = res.data.data
      // this.editFrom.city = Array(res.data.city)
      this.editDialogVisible = true
    },
    addressDialogClosed() {
      this.$refs.addressFormRef.resetFields()
    },
    async showProgressBox() {
      const { data: res } = await this.$http.get('/kuaidi/SF1326455286839')
      if (res.meta.status !== 200) {
        this.$message.error('获取物流信息失败！')
      }
      this.progressInfo = res.data
      this.progressVisible = true
    },
    bindTableHead() {
      this.zzz = '300px'
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (index === 1) {
          sums[index] = 'N/A'
          return
        }
        // if (index === 2) {
        //   sums[index] = 'N/A'
        //   return
        // }
        if (index === 4) {
          sums[index] = 'N/A'
          return
        }
        if (index === 5) {
          sums[index] = 'N/A'
          return
        }
        if (index === 6) {
          sums[index] = 'N/A'
          return
        }
        if (index === 7) {
          sums[index] = 'N/A'
          return
        }
        if (index === 8) {
          sums[index] = 'N/A'
          return
        }
        if (index === 9) {
          sums[index] = 'N/A'
          return
        }
        const values = data.map(item => parseFloat(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = parseFloat(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] += ''
        } else {
          sums[index] = 'N/A'
        }
      })

      return sums
    },
    editUserInfo() {
      this.$refs.editFromRef.validate(async valid => {
        if (!valid) {
          // eslint-disable-next-line no-useless-return
          return
        }
        // 此操作为敏感操作 弹窗展示是否修改数据
        const confirmResult = await this.$confirm('亲,确定要修改吗？', '提示', {
          confirmButtonText: '嗯',
          cancelButtonText: '不了',
          type: 'warning'
        }).catch(err => err)

        if (confirmResult !== 'confirm') {
          return this.$message.info('已取消操作！')
        }
        console.log(this.editFrom)
        // 发起请求，修改用户信息
        const { data: res } = await this.$http.put(
          'orders/' + this.editFrom.orderId,
          this.editFrom
        )
        console.log(res)
        if (res.code !== '20000') {
          return this.$message.error('更新信息失败！')
        }
        // 刷新数据列表
        this.getOrderList()
        // 提示修改成功
        this.$message.success('更新信息成功！')
        // 关闭对话框
        this.editDialogVisible = false
      })
    },
    showPeopleDetail(row) {
      this.$alert(row.choosePlace, '上车地址', {
        confirmButtonText: '确定',
        callback: action => {}
      })
    },
    async showProductDetail(id, orderNumber) {
      const { data: res } = await this.$http.get(`orders/getProducts/${id}`)
      if (res.code !== '20000') {
        return this.$message.error('获取信息失败!')
      }
      const { data: res1 } = await this.$http.get(`xContacts/${orderNumber}`)
      if (res1.code !== '20000') {
        return this.$message.error('获取信息失败!')
      }
      var content = ''
      res1.data.list.forEach(item => {
        content += item.name + '：' + item.phone + '\n'
      })
      // console.log(res.data);
      this.$alert(
        res.data.info.from + '➡' + res.data.info.to + '\n' + content,
        '详情',
        {
          confirmButtonText: '确定',
          callback: action => {}
        }
      )
    },
    // 监听开关状态的改变
    async usersChanged(userInfo) {
      const { data: res } = await this.$http.put(
        `orders/${userInfo.orderId}/state/${userInfo.isExpire}`
      )
      if (res.code !== '20000') {
        userInfo.isExpire = !userInfo.isExpire
        return this.$message.error('更新状态失败!')
      }
      this.$message.success('更新状态成功！')
    },
    // 监听开关状态的改变
    async usersChanged1(userInfo) {
      const { data: res } = await this.$http.put(
        `orders/${userInfo.orderId}/states/${userInfo.isStar}`
      )
      if (res.code !== '20000') {
        userInfo.isStar = !userInfo.isStar
        return this.$message.error('更新状态失败!')
      }
      this.$message.success('更新状态成功！')
    }
    // pdf() {
    //   this.$http.get('download')
    // }
  },
  filters: {
    timeFormat: function(data) {
      if (data == null) {
        return '暂无数据'
      }
      return data.split(' ')[0]
    }
  },
  beforeDestroy() {
    // 释放内存
    this.clipboardclipboard.off('error')
    this.clipboardclipboard.off('success')
    this.clipboardclipboard.destroy()
  }
}
</script>

<style lang="less" scoped>
.copyBtn {
  padding: 0;
  width: 8px;
  height: 8px;
  font-size: 8px;
  border: none;
  color: #58d1ff;
}
.aaaa {
  float: right !important;
}
.expand_text {
  color: grey;
  font-weight: bold;
}
.expand_text1 {
  color: rgb(24, 216, 142);
  font-weight: bold;
}
.expand_text2 {
  color: rgb(204, 22, 68);
  font-weight: bold;
}
</style>
