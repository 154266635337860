<template>
  <div class="card">
    <div class="content" v-for="(item, index) in dataList" :key="index">
      <div class="left">
        <i
          :class="item.icon"
          aria-hidden="true"
          :style="'color: ' + item.color"
        ></i>
      </div>
      <div class="right">
        <count-to
          class="number"
          :startVal="item.startVal"
          :endVal="item.endVal"
          :duration="2000"
          separator=""
        />
        <span class="text">{{ item.des }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from 'vue-count-to'
export default {
  name: 'ConsoleCard',
  components: {
    countTo
  },
  data() {
    return {
      startVal: 0,
      dataList: [
        {
          des: '管理员数量',
          icon: 'fa fa-user',
          startVal: 0,
          duration: 1000,
          endVal: 3,
          color: '#57a0eb'
        },
        {
          des: '用户总数',
          icon: 'fa fa-users',
          startVal: 0,
          duration: 1000,
          endVal: 9120,
          color: '#57a0eb'
        },
        {
          des: '订单总数',
          icon: 'fa fa-first-order',
          startVal: 0,
          duration: 1000,
          endVal: 182,
          color: '#FFA17F'
        },
        {
          des: '总交易额',
          icon: 'fa fa-money',
          startVal: 0,
          duration: 1000,
          endVal: 9520,
          color: '#FF9C96'
        },
        {
          des: '反馈信息条数',
          icon: 'fa fa-commenting-o',
          startVal: 0,
          duration: 1000,
          endVal: 156,
          color: '#C3BBF7'
        },
        {
          des: '上架商品总数',
          icon: 'fa fa-cubes',
          startVal: 0,
          duration: 1000,
          endVal: 820,
          color: '#2CC893'
        }
      ],
      endVal: 2020,
      duration: 1000,
      dataListStyle: {}
    }
  },
  mounted() {
    this.computedItemWidth()
  },
  created() {
    this.getBaseData()
  },
  methods: {
    computedItemWidth() {
      const count = this.dataList
      const width = 100 / count

      this.dataListStyle = {
        width: `calc(${width}% - 20px)`
      }
    },
    async getBaseData() {
      const { data: res } = await this.$http.get(`getBaseData`)
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      this.dataList[0].endVal = Number(res.data.data.admin)
      this.dataList[1].endVal = Number(res.data.data.user)
      this.dataList[2].endVal = Number(res.data.data.order)
      this.dataList[3].endVal = Number(res.data.data.moneyCount)
      this.dataList[4].endVal = Number(res.data.data.feedback)
      this.dataList[5].endVal = Number(res.data.data.product)
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  width: 100%;
  padding: 5px;
  display: flex;
  justify-content: space-around;
}
.content {
  background-color: #fff;
  height: 60px;
  width: 160px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
}
.content:hover {
  transform: scale(1.1, 1.1);
  cursor: pointer;
}
.left {
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.right {
  flex: 3;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.number {
  margin-right: 0px !important;
  padding-top: 1px !important;
  padding: 0 !important;
  color: #515a6e;
  font-size: 26px;
  line-height: 26px;
  bottom: 50%;
  right: 10%;
  padding-right: 16px !important;
  text-align: right;
}
.text {
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
  padding-top: 3px;
  padding-right: 16px;
  text-align: right;
  color: #b5b2af;
  font-size: 12px;
}
.fa {
  font-size: 20px;
  color: #57a0eb;
}
</style>
