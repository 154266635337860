<template>
  <div>
    <div class="toolbar">
      <draggable
        v-model="$store.state.toolBarData"
        forceFallback="true"
        @start="onStart"
        @end="onEnd"
        style="display:flex"
      >
        <div
          v-for="(item, index) in getToolData"
          :key="index"
          @contextmenu.prevent="rightClick($event, index)"
        >
          <el-tag
            class="toolItem"
            type="info"
            :disable-transitions="false"
            :closable="item.id != 0"
            effect="plain"
            :class="{ active: $route.path == item.detail }"
            @click="redirect(item)"
            @close="closeToolItem(item)"
          >
            <span class="dot" v-if="$route.path == item.detail">●</span>
            <!-- <div @contextmenu.prevent="rightClick($event)"> -->
            {{ item.name }}
            <!-- </div> -->
          </el-tag>
        </div>
      </draggable>
    </div>

    <e-vue-contextmenu ref="ctxshow" id="contextStyle">
      <div @click="other" style="text-align: center;" class="aaaa">
        关闭其他
      </div>
      <div
        style="margin-top:2px;text-align: center;"
        class="aaaa"
        @click="closeLeft"
      >
        关闭左侧
      </div>
      <div
        style="margin-top:2px;text-align: center;"
        class="aaaa"
        @click="closeRight"
      >
        关闭右侧
      </div>
    </e-vue-contextmenu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
export default {
  props: {},
  data() {
    return {
      indexSlef: 0
    }
  },
  methods: {
    closeToolItem(item, index) {
      this.$store.dispatch('clearToolBar', item)
      this.$store.dispatch('clearCache', item.componentName)
    },
    redirect(item) {
      this.$router.push({ path: item.detail })
      this.$emit('childEvent', item.detail)
    },
    onStart() {
      this.drag = true
    },
    // 拖拽结束事件
    onEnd() {
      this.drag = false
    },
    rightClick(e, index) {
      console.log('--------------')
      // console.log(index)
      this.indexSlef = index
      this.$refs.ctxshow.showMenu(e)
    },
    // 关闭其他
    other() {
      // console.log(this.index1)
      // this.$store.commit('closeOther', this.index1)
      this.$store.dispatch('closeLeft', this.indexSlef)
      this.$store.dispatch('closeLeftCache', this.indexSlef)
      this.$store.dispatch('closeRight', 0)
      this.$store.dispatch('closeRightCache', 0)
      this.$refs.ctxshow.hideMenu() // 隐藏菜单
    },
    // 关闭左侧
    closeLeft() {
      // this.$store.commit('closeLeft', this.index1)
      this.$store.dispatch('closeLeft', this.indexSlef)
      this.$store.dispatch('closeLeftCache', this.indexSlef)
      this.$refs.ctxshow.hideMenu() // 隐藏菜单
    },
    // 关闭右侧
    closeRight() {
      // this.$store.commit('closeRight', this.index1)
      this.$store.dispatch('closeRight', this.indexSlef)
      this.$store.dispatch('closeRightCache', this.indexSlef)
      this.$refs.ctxshow.hideMenu() // 隐藏菜单
    }
  },
  // eslint-disable-next-line vue/no-unused-components
  components: { draggable },
  computed: {
    ...mapGetters(['getToolData', 'getCacheView'])
  },
  watch: {
    // 监听路由变化自动dispatch一个action
    $route() {
      // 路由组件名称（自定义）['components']['default']
      // eslint-disable-next-line dot-notation
      console.log(this.$route.matched)
      // eslint-disable-next-line dot-notation
      const componentName = this.$route.matched[1]['components']['default'][
        // eslint-disable-next-line dot-notation
        'name'
      ]
      // 路由组件path
      const detail = this.$route.path
      console.log(this.$route.path)
      // eslint-disable-next-line dot-notation
      console.log(this.$route.meta['title'])
      // 当前路由需要显示到tab标签上名字，如“发布公告”
      // eslint-disable-next-line dot-notation
      const name = this.$route.meta['title']
      this.$store.dispatch('commitToolBar', { name, detail, componentName })
    }
  }
}
</script>
<style lang="less" scoped>
.toolbar {
  height: 32px;
  margin-left: 3px;
  display: flex;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

.toolItem {
  margin-left: 4px;
  color: #000 !important;
  cursor: pointer !important;
  border-radius: 0px !important;
  height: 28px;
}
.active {
  background-color: #0066ef !important;
  margin-top: 0px;
  color: #fff !important;
  line-height: 26px !important;
}
.active .el-tag--plain.el-tag--info .el-tag__close {
  color: #fff !important;
}

.dot {
  font-size: 16px;
}
.ctx-menu-container {
  min-width: 76px !important;
}
.aaaa {
  font-weight: bold;
}
.aaaa:hover {
  background-color: #0066ef;
  cursor: pointer;
}
</style>
