<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>管理员列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图区 -->
    <el-card class="box-card">
      <!-- 搜索与添加区域 -->
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input
            placeholder="请输入用户名搜索"
            v-model="queryInfo.query"
            clearable
            @clear="getUserList()"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              :style="active"
              @mouseover.native="mouseOver()"
              @mouseout.native="mouseLeave()"
              @click="getUserList()"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="addDialogVisible = true"
            >添加用户</el-button
          >
        </el-col>
      </el-row>

      <!-- 用户列表区域 -->
      <el-table :data="userlist" border stripe>
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column
          label="用户名"
          prop="username"
          width="130px"
        ></el-table-column>
        <el-table-column label="头像" prop="avatar" width="60px">
          <template slot-scope="scope">
            <el-avatar :size="'medium'" :src="scope.row.avatar"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column label="邮箱" prop="email" width="160px">
        </el-table-column>
        <el-table-column label="电话" prop="userPhone" width="120px">
          <template slot-scope="scope">
            {{ scope.row.userPhone }}
            <el-button
              class="copyBtn"
              size="small"
              data-test="test..."
              :data-clipboard-text="scope.row.userPhone"
              icon="el-icon-copy-document"
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column label="城市" prop="city"></el-table-column>
        <el-table-column label="所属权限" prop="roleId" width="120px">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.roleId == '1'">超级管理员</el-tag>
            <el-tag type="success" v-else-if="scope.row.roleId == '3'"
              >商家管理员</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="扫码绑定" width="80px" prop="openid">
          <template slot-scope="scope">
            <i
              class="el-icon-success"
              v-if="scope.row.openid != null"
              style="color: lightgreen; font-size: 20px;"
            ></i>
            <i
              class="el-icon-error"
              v-else
              style="color: red;font-size: 20px;"
            ></i>
          </template>
          <!-- <template slot-scope="scope"> -->
          <!-- <el-tag v-if="scope.row.openid == null">未绑定</el-tag>
            <el-tag type="success" v-else>已绑定</el-tag>
          </template> -->
        </el-table-column>
        <el-table-column label="扫码激活状态">
          <template slot-scope="scope">
            <!-- {{scope.row}} -->
            <el-switch
              v-model="scope.row.wxstatus"
              @change="wxstatusChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="密码登录状态">
          <template slot-scope="scope">
            <!-- {{scope.row}} -->
            <el-switch
              v-model="scope.row.status"
              @change="usersChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="230px">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              plain
              @click="showEditDialog(scope.row.username)"
            ></el-button>
            <!-- 删除按钮 -->
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              plain
              @click="removeUserById(scope.row.id)"
            ></el-button>
            <!-- 分配角色按钮 -->
            <el-tooltip
              effect="dark"
              content="更改权限"
              placement="top"
              :enterable="false"
            >
              <el-button
                plain
                type="warning"
                icon="el-icon-setting"
                size="mini"
                @click="setRole(scope.row)"
              ></el-button>
            </el-tooltip>
            <!-- 绑定微信用户 -->
            <el-tooltip
              effect="dark"
              content="绑定微信用户"
              placement="top"
              :enterable="false"
            >
              <el-button
                plain
                type="success"
                icon="fa fa-unlock-alt"
                size="mini"
                @click="setWxUser(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="操作权限" min-width="100px">
          <template slot-scope="scope">
            <!-- 修改操作权限 -->
            <el-tooltip
              v-if="scope.row.roleId == 3"
              effect="dark"
              content="更改权限"
              placement="top"
              :enterable="false"
            >
              <el-button
                plain
                type="text"
                icon="fa fa-circle-o"
                size="mini"
                @click="setPerm1(scope.row)"
                >&nbsp;接口权限</el-button
              >
            </el-tooltip>
            <el-tag type="success" v-else>最高权限</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页显示 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[5, 10, 15, 25]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 添加用户对话框区域 -->
    <el-dialog
      title="添加用户"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClosed"
    >
      <!-- 内容主体区域 -->
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="70px"
      >
        <el-form-item label="用户名" prop="username">
          <el-input
            v-model="addForm.username"
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            v-model="addForm.password"
            placeholder="请输入密码"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="addForm.email" placeholder="请输入邮箱"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="userPhone">
          <el-input
            v-model="addForm.userPhone"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改用户的对话框 -->
    <el-dialog
      title="修改用户"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <el-form
        :model="editFrom"
        :rules="editFromRules"
        ref="editFromRef"
        label-width="90px"
      >
        <el-form-item label="用户名">
          <el-input v-model="editFrom.username" disabled></el-input>
        </el-form-item>
        <el-form-item label="邮 箱" prop="email">
          <el-input v-model="editFrom.email"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="Phone">
          <el-input v-model="editFrom.Phone" disabled></el-input>
        </el-form-item>
        <el-form-item label="城 市" prop="address1">
          <!-- <el-input v-model="addressForm.address1"></el-input> -->
          <el-cascader
            placeholder="试试搜索 洛阳"
            :options="areaData"
            v-model="editFrom.city"
            :props="{ expandTrigger: 'hover', value: 'label', emitPath: false }"
            filterable
          ></el-cascader>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUserInfo">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 分配角色对话框 -->
    <el-dialog
      title="更新权限"
      :visible.sync="setRoleDialogVisible"
      width="50%"
      @close="setRoleDialogClosed"
    >
      <div>
        <p>当前用户：{{ userInfo.username }}</p>
        <p>
          当前权限：{{ userInfo.roleId === '1' ? '超级管理员' : '商家管理员' }}
        </p>
        <p>
          更改新权限：
          <el-select v-model="selectedRoleId" placeholder="请选择">
            <el-option
              v-for="item in rolesList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setRoleDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveRoleInfo">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改接口操作权限对话框 -->
    <el-dialog
      title="修改接口权限"
      :visible.sync="setPermDialogVisible"
      width="30%"
      @close="setPermDialogClosed"
    >
      <div class="prem">
        <el-tree
          :data="treeData"
          show-checkbox
          node-key="id"
          :props="customProps"
          :default-checked-keys="checkedList"
          ref="tree"
        >
        </el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setPermDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="savePermInfo">修 改</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AreaJson from './area.json'
import Clipboard from 'clipboard'
import Qs from 'qs'
export default {
  name: 'Admin',
  data() {
    // 验证邮箱的规则
    var checkEmail = (rule, value, cb) => {
      // 验证邮箱的正则表达式
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
      if (regEmail.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法的邮箱！'))
    }
    var checkMobile = (rule, value, cb) => {
      // 验证手机号规则
      const regMobile = /^([0|86|17951])?(13[0-9]|15[0123456789]|17[678]|18[0-9]|19[0-9]|14[57])[0-9]{8}$/
      if (regMobile.test(value)) {
        return cb()
      }
      cb(new Error('手机号不合法！'))
    }
    return {
      // 获取用户列表的参数
      queryInfo: {
        query: '',
        pagenum: 1,
        pagesize: 5
      },
      userlist: [],
      treeData: [],
      customProps: {
        label: 'resourceName',
        children: 'children'
      },
      checkedList: [],
      currentId: 0, // 当前操作的row id
      total: 0,
      active: '',
      // 级联选择器 城市
      areaData: [],
      //   控制对话框的显示与隐藏
      addDialogVisible: false,
      editDialogVisible: false,
      setPermDialogVisible: false,
      editFrom: {},
      // 添加表单数据
      addForm: {
        username: '',
        password: '',
        email: '',
        userPhone: ''
      },
      addFormRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          {
            min: 3,
            max: 10,
            message: '用户名的字符长度在 3~10 个字符之间',
            trigger: 'blur'
          }
        ],
        password: [
          { required: true, message: '请输入用密码', trigger: 'blur' },
          {
            min: 3,
            max: 10,
            message: '用户名的字符长度在 3~10 个字符之间',
            trigger: 'blur'
          }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' }
        ],
        userPhone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ]
      },
      // 修改表单的验证规则对象
      editFromRules: {
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' }
        ],
        Phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ],
        city: [
          { required: true, message: '请输入城市', trigger: 'blur' },
          {
            min: 2,
            max: 12,
            message: '用户名的字符长度在 3~10 个字符之间',
            trigger: 'blur'
          }
        ]
      },
      // 控制分配角色对话框的显示与隐藏
      setRoleDialogVisible: false,
      // 需要被分配角色的用户信息
      userInfo: {},
      // 所有角色的数据列表
      rolesList: [
        {
          value: '1',
          label: '超级管理员'
        },
        {
          value: '3',
          label: '商家管理员'
        }
      ],
      // 已经选中的角色id值
      selectedRoleId: '',
      clipboard: null,
      diyContent: '这是我自定义的拷贝内容！'
    }
  },
  created() {
    this.getUserList()
    this.areaData = AreaJson
    for (var i = 0; i < this.areaData.length; i++) {
      if (this.areaData[i].children.length === 0) {
        delete this.areaData[i].children
      }
    }
    this.clipboard = new Clipboard('.copyBtn')
    this.clipboard.on('success', this.successFunc)
    this.clipboard.on('error', this.errorFunc)
  },
  mounted() {},
  methods: {
    successFunc(e) {
      // console.info('Action:', e.action)
      // console.info('Text:', e.text)
      // console.info('Trigger:', e.trigger)
      // // 可以取到目标元素上的自定义属性（可以据此再做一些处理）
      // e.trigger.dataset.test && console.log(e.trigger.dataset.test)
      // // 清除选中状态
      // e.clearSelection()

      this.$antMessage.success('恭喜您复制成功，赶快去粘贴吧~')
      // this.$notify({
      //   title: '成功',
      //   message: '恭喜您复制成功，赶快去粘贴吧~',
      //   type: 'success',
      //   showClose: false
      // })
    },
    errorFunc(e) {
      this.$antMessage.error('复制失败！！')
      // console.error('Action:', e.action)
      // console.error('Trigger:', e.trigger)

      // this.$notify.error({
      //   title: '失败',
      //   message: '操作失败，请重试！',
      //   showClose: false
      // })
    },
    setWxUser(row) {
      this.$prompt('请输入要绑定微信用户的手机号', '绑定弹窗', {
        confirmButtonText: '绑定',
        cancelButtonText: '取消',
        inputPattern: /^1[3456789]\d{9}$/,
        inputErrorMessage: '手机号格式不正确'
      })
        .then(async ({ value }) => {
          const { data: res } = await this.$http.put(
            `/admins/bindWx/${value}/${row.id}`
          )
          if (res.code !== '20000') {
            this.$message({
              type: 'error',
              message: res.message
            })
            return false
          } else {
            this.$message({
              type: 'success',
              message: res.message
            })
            return false
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '你取消了绑定操作'
          })
        })
    },
    async setPerm1(row) {
      console.log('---------------------')
      console.log(row)
      await this.getResourceList()
      this.setPermDialogVisible = true
      this.checkedList = row.resourceList
      this.currentId = row.id
    },
    // 监听修改接口权限的关闭事件
    setPermDialogClosed() {
      this.checkedList = []
      this.currentId = 0
    },
    async savePermInfo() {
      // 首先判断被选中的数组和角色

      console.log(this.$refs.tree.getCheckedKeys(true))
      console.log(this.checkedList)
      const resourceList = this.checkedList || []
      const checkedList = this.$refs.tree.getCheckedKeys(true)
      if (resourceList.length === checkedList.length) {
        return this.$message.info(
          '你当前没有更改任何的权限，不能执行修改操作！'
        )
      }
      // 开始修改权限
      // 若checkedList 数组 大于 resourceList 则 说明是添加操作
      if (resourceList.length < checkedList.length) {
        var newList = checkedList
        resourceList.forEach(item => {
          // eslint-disable-next-line no-const-assign
          newList = newList.filter(x => x !== item)
        })
        const { data: res } = await this.$http.put(
          `/resources/${this.currentId}/add`,
          Qs.stringify({
            ids: newList + ''
          })
        )
        if (res.code !== '20000') {
          this.setPermDialogVisible = false
          return this.$message.error(res.message)
        } else {
          this.setPermDialogVisible = false
          this.getUserList()
          return this.$message.success('更新接口权限成功!')
        }
      }
      // 若checkedList 数组 小于 resourceList 则 说明是删除操作
      if (resourceList.length > checkedList.length) {
        var newList1 = resourceList
        checkedList.forEach(item => {
          // eslint-disable-next-line no-const-assign
          newList1 = newList1.filter(x => x !== item)
        })
        const { data: res } = await this.$http.put(
          `/resources/${this.currentId}/del`,
          Qs.stringify({
            ids: newList1 + ''
          })
        )
        if (res.code !== '20000') {
          this.setPermDialogVisible = false
          return this.$message.error(res.message)
        } else {
          this.setPermDialogVisible = false
          this.getUserList()
          return this.$message.success('更新接口权限成功!')
        }
      }
    },
    // 查询所有的资源列表
    async getResourceList() {
      const { data: res } = await this.$http.get('resources')
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      } else {
        this.treeData = res.data.list
        this.$message.success('获取资源数据成功!')
      }
    },
    async getUserList() {
      const { data: res } = await this.$http.get('admins', {
        params: this.queryInfo
      })
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      console.log(res)
      this.userlist = res.data.lists
      this.total = Number(res.data.total)
    },
    // 监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      //   console.log(newSize)
      this.queryInfo.pagesize = newSize
      this.getUserList()
    },
    // 监听 页码值改变的事件
    handleCurrentChange(newPage) {
      //   console.log(newPage)
      this.queryInfo.pagenum = newPage
      this.getUserList()
    },
    async wxstatusChanged(userInfo) {
      const { data: res } = await this.$http.put(
        `admins/${userInfo.id}/wxstate/${userInfo.wxstatus}`
      )
      if (res.code !== '20000') {
        userInfo.wxstatus = !userInfo.wxstatus
        return this.$message.error('更新状态失败!')
      }
      this.$message.success('更新状态成功！')
    },
    // 监听开关状态的改变
    async usersChanged(userInfo) {
      const { data: res } = await this.$http.put(
        `admins/${userInfo.id}/state/${userInfo.status}`
      )
      if (res.code !== '20000') {
        userInfo.status = !userInfo.status
        return this.$message.error('更新状态失败!')
      }
      this.$message.success('更新状态成功！')
    },
    mouseOver() {
      this.active = 'background-color: #ccc;'
      //   console.log('鼠标移动事件')
    },
    mouseLeave() {
      this.active = ''
    },
    addDialogClosed() {
      this.$refs.addFormRef.resetFields()
    },
    // 添加用户
    addUser() {
      this.$refs.addFormRef.validate(async valid => {
        // 表单预校验
        // eslint-disable-next-line no-useless-return
        if (!valid) return
        // 可以发起真正的请求
        const { data: res } = await this.$http.post('Register', this.addForm)
        if (res.code !== '20000') {
          return this.$message.error('添加用户失败！')
        }
        this.$message.success('添加用户成功！')
        // 隐藏添加的对话框
        this.addDialogVisible = false
        // 重新获取用户列表
        this.getUserList()
      })
    },
    // 编辑用户的对话框
    async showEditDialog(username) {
      console.log(username)
      const { data: res } = await this.$http.get(`getAdminInfo/${username}`)
      console.log(res)
      if (res.code !== '20000') {
        return this.$message.error('查询用户失败！')
      }
      this.$message.success('查询用户成功！')
      this.editFrom = res.data
      // this.editFrom.city = Array(res.data.city)
      this.editDialogVisible = true
    },
    editDialogClosed() {
      this.$refs.editFromRef.resetFields()
    },
    editUserInfo() {
      this.$refs.editFromRef.validate(async valid => {
        if (!valid) {
          // eslint-disable-next-line no-useless-return
          return
        }
        console.log(this.editFrom)
        // 发起请求，修改用户信息
        const { data: res } = await this.$http.put(
          'admins/' + this.editFrom.id,
          {
            email: this.editFrom.email,
            userPhone: this.editFrom.Phone,
            city: this.editFrom.city
          }
        )
        console.log(res)
        if (res.code !== '20000') {
          return this.$message.error('更新信息失败！')
        }
        // 关闭对话框
        this.editDialogVisible = false
        // 刷新数据列表
        this.getUserList()
        // 提示修改成功
        this.$message.success('更新信息成功！')
      })
    },
    // 根据id删除对应的用户
    async removeUserById(id) {
      // 弹窗展示是否删除数据
      const confirmResult = await this.$confirm(
        '此操作将永久删除该用户, 是否继续?',
        '提示',
        {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)

      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除！')
      }
      const { data: res } = await this.$http.delete('admins/' + id)
      if (res.code !== '20000') {
        this.$message.error('删除用户失败！')
      }
      this.$message.success('删除用户成功！')
      this.getUserList()
    },
    // 展示分配角色对话框
    async setRole(userInfo) {
      // 需要被分配角色的用户信息
      this.userInfo = userInfo
      console.log(userInfo)
      // 在展示对话框前 获取角色列表
      // const { data: res } = await this.$http.get('roles')

      // if (res.meta.status !== 200) {
      //   return this.$message.error('获取角色列表失败！')
      // }
      // this.rolesList = res.data
      this.setRoleDialogVisible = true
    },
    // 点击按钮分配角色
    async saveRoleInfo() {
      console.log('-------------')
      if (!this.selectedRoleId) {
        return this.$message.error('请选择要分配的角色！')
      }
      const { data: res } = await this.$http.put(
        `admins/${this.userInfo.id}/role`,
        {
          rid: this.selectedRoleId
        }
      )
      if (res.code !== '20000') {
        this.$message.error(res.message)
      } else {
        this.$message.success('分配角色成功！')
      }
      this.getUserList()
      this.setRoleDialogVisible = false
    },
    // 监听分配角色的关闭事件
    setRoleDialogClosed() {
      this.selectedRoleId = ''
      this.userInfo = {}
    }
  },
  beforeDestroy() {
    // 释放内存
    this.clipboardclipboard.off('error')
    this.clipboardclipboard.off('success')
    this.clipboardclipboard.destroy()
  }
}
</script>

<style lang="less" scoped>
.prem {
}
.copyBtn {
  padding: 0;
  width: 8px;
  height: 8px;
  font-size: 8px;
  border: none;
  color: #58d1ff;
}
</style>
