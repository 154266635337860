<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>小程序设置</el-breadcrumb-item>
      <el-breadcrumb-item>轮播图设置</el-breadcrumb-item>
    </el-breadcrumb>

    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="轮播图" name="1">
        <!-- 提示区域 -->
        <el-alert
          title="小程序轮播设置-先选择要设置的轮播图"
          type="info"
          center
          show-icon
          :closable="false"
          style="margin-bottom: 10px;"
        >
        </el-alert>
        <el-dropdown @command="handleCommand">
          <el-button type="warning">
            轮播图&nbsp;{{ this.params.id
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">轮播图1</el-dropdown-item>
            <el-dropdown-item command="2">轮播图2</el-dropdown-item>
            <el-dropdown-item command="3">轮播图3</el-dropdown-item>
            <el-dropdown-item command="4">轮播图4</el-dropdown-item>
            <el-dropdown-item command="5">轮播图5</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <br />
        <br />
        <el-switch
          style="display: block"
          v-model="params.status"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="开启"
          inactive-text="关闭"
        >
        </el-switch>
        <br />
        <div class="block" style="width: 260px;">
          <el-image :src="params.img"></el-image>
        </div>
        <el-upload
          style="margin-top: 18px;"
          :action="uploadURL"
          :headers="headersObj"
          :on-success="uploadImg"
          limit="1"
        >
          <el-button size="small" type="primary">点击上传轮播图片</el-button>
          <div slot="tip" class="el-upload__tip">
            只能上传jpg/png文件，且不超过500kb,图片大小尽量320px * 240px
          </div>
        </el-upload>
        <br />
        <!-- Two-way Data-Binding 富文本编辑器组件-->
        <quill-editor
          v-model="params.content"
          ref="myQuillEditor"
          :options="editorOption"
          @blur="onEditorBlur($event)"
        >
        </quill-editor>

        <el-upload
          drag
          multiple
          :headers="headersObj"
          class="quill-upload"
          :on-success="handleSuccess"
          :action="uploadURL"
          v-show="false"
        >
          <!-- <el-button size="small" type="primary">点击上传</el-button>
                          @change="onEditorChange($event)"
                @ready="onEditorReady($event)"
           -->
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将文件拖到此处，或
            <em>点击上传</em>
          </div>
          <div slot="tip" class="el-upload__tip">
            只能上传jpg/png文件，且不超过500kb
          </div>
        </el-upload>
        <!-- action 图片要上传的地址 -->
        <!-- <el-upload
          :action="uploadURL"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          list-type="picture"
          :headers="headersObj"
          :on-success="handleSuccess"
          :limit="5"
        >
          <el-button size="small" type="primary">点击上传轮播图片</el-button>
          <div slot="tip" class="el-upload__tip">
            只能上传jpg/png文件，且不超过500kb,图片大小尽量320px * 240px
          </div>
        </el-upload>  -->
        <el-button type="success" @click="save" style="margin-top: 10px;"
          >保存修改</el-button
        >
      </el-tab-pane>
    </el-tabs>

    <!-- //  图片预览
    // <el-dialog title="图片预览" :visible.sync="previewVisible" width="50%">
    //   <img :src="previewPath" alt="" class="previewImg" />
    // </el-dialog>-->
  </div>
</template>
<script>
import QS from 'qs'
import hljs from 'highlight.js'
import dedent from 'dedent'
import quillEmoji from 'quill-emoji'
import 'quill-emoji/dist/quill-emoji.css'
import 'highlight.js/styles/vs2015.css'
import Quill from 'quill'
const icons = Quill.import('ui/icons')
icons.emoji =
  '<svg t="1629989712684" class="icon" viewBox="0 0 1879 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2200" width="64" height="64"><path d="M939.209249 985.526012c260.439306 0 471.454335-211.163006 471.454335-471.454336S1199.500578 42.469364 939.209249 42.469364c-260.439306 0-471.602312 211.163006-471.602313 471.454335S678.621965 985.526012 939.209249 985.526012z m0-854.714451c211.606936 0 383.112139 171.505202 383.112138 383.112138s-171.505202 383.260116-383.112138 383.260116-383.112139-171.653179-383.112139-383.260116S727.602312 130.811561 939.209249 130.811561zM703.334104 384.295954c0-32.554913 26.339884-58.894798 58.894798-58.894798 32.554913 0 58.894798 26.339884 58.894797 58.894798 0 32.554913-26.339884 58.894798-58.894797 58.894797-32.406936 0-58.894798-26.339884-58.894798-58.894797z m353.66474 0c0-32.554913 26.339884-58.894798 58.894798-58.894798 32.554913 0 58.894798 26.339884 58.894797 58.894798 0 32.554913-26.339884 58.894798-58.894797 58.894797-32.406936 0-58.894798-26.339884-58.894798-58.894797z m6.215029 227.588439H815.944509c-17.313295 0-31.519075-14.20578-31.519075-31.519075v-3.847399c0-17.313295 14.20578-31.519075 31.519075-31.519075h247.269364c17.313295 0 31.519075 14.20578 31.519075 31.519075v3.847399c0 17.461272-14.20578 31.519075-31.519075 31.519075z" fill="#212121" p-id="2201"></path></svg>'
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ direction: 'rtl' }], // text direction

  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
  ['link', 'image', 'video'],
  ['clean'] // remove formatting button
  // ['emoji']
]

export default {
  name: 'Lunbo',
  data() {
    return {
      currentSwiper: {},
      uploadURL: 'https://api.shunhou.wandoukeji.com/api/v1/uploadImage',
      // 图片上传组件的请求头
      headersObj: {
        token: window.sessionStorage.getItem('token')
      },
      params: {
        id: 1,
        img: '',
        content: '',
        status: false
      },
      previewPath: '',
      // previewVisible: false,
      // pics: [],

      activeName: '1',
      content: '',
      editorOption: {
        theme: 'snow',
        modules: {
          'emoji-toolbar': true,
          'emoji-shortname': true,
          toolbar: {
            container: toolbarOptions,
            handlers: {
              // 重写点击组件上的图片按钮要执行的代码
              // eslint-disable-next-line quote-props
              image: function(value) {
                // console.log('---------------------')
                // console.log(value)
                if (value) {
                  document
                    .querySelector('.quill-upload .el-icon-upload')
                    .click()
                  // alert(1)
                } else {
                  this.quill.format('image', false)
                }
              },
              emoji() {
                console.log('插入表情')
              }
            }
          },
          syntax: {
            highlight: text => {
              return hljs.highlightAuto(text).value // 这里就是代码高亮需要配置的地方
            }
          }
        }
      }
    }
  },
  methods: {
    onEditorBlur(ev) {
      console.log(ev)
    },
    // 处理移除图片的操作
    uploadImg(response) {
      if (response.code === '20000') {
        this.params.img = response.data.path
        this.$essage.success('图片插入成功')
      }
    },
    handleCommand(command) {
      this.params.id = Number(command)
      this.getSiwper(this.params.id)
    },
    async getSiwper(id) {
      // 获得所有轮播
      const { data: res } = await this.$http.get(`/get/swiper/${id}`)
      console.log(res)
      if (res.code !== '20000') {
        this.$message({
          showClose: true,
          message: res.message,
          type: 'error'
        })
      } else {
        this.$antMessage.success(res.message)
        this.currentSwiper = res.data.data
        this.params.content = res.data.data.content
        this.params.status = res.data.data.status
        this.params.img = res.data.data.img
      }
    },
    // 图片上传成功时的钩子函数
    handleSuccess(response) {
      // 1.先拼接得到一个图片信息对象
      // const picInfo = { pic: response.data.path }
      // 2.push到pics数组中
      // console.log(response)
      // this.pics.push(response.data.path)
      if (response) {
        // 获取文本编辑器
        const quill = this.$refs.myQuillEditor.quill
        // 获取光标位置
        const pos = quill.getSelection().index
        // 插入图片到光标位置
        quill.insertEmbed(pos, 'image', response.data.path)
      } else {
        this.$message.error('图片插入失败')
      }
    },
    handleClick(tab, event) {
      this.params.id = Number(tab.name)
      console.log(tab)
      this.params.img = ''
      this.params.content = ''
    },
    async save() {
      console.log(this.params)
      const { data: res } = await this.$http.post('swiper', this.params)
      console.log(res)
      if (res.code !== '20000') {
        this.$message({
          showClose: true,
          message: res.message,
          type: 'error'
        })
        return false
      }
      this.$message({
        showClose: true,
        message: res.message,
        type: 'success'
      })
    }
  },
  created() {
    this.getSiwper(this.params.id)
  }
}
</script>
<style lang="less" scoped>
.el-upload-list {
  width: 450px !important;
}
</style>
