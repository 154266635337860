<template>
  <div class="content">
    <!-- 卡片视图区 -->

    <!-- 内容主体区域 -->
    <el-form
      :model="updateForm"
      :rules="updateFormRules"
      ref="addFormRef"
      label-width="110px"
    >
      <el-form-item label="旧密码" prop="oldPass">
        <el-input v-model="updateForm.oldPass" type="password"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPass">
        <el-input v-model="updateForm.newPass" type="password"></el-input>
      </el-form-item>
      <el-form-item label="确认新密码" prop="re_newPass">
        <el-input v-model="updateForm.re_newPass" type="password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">修改</el-button>
        <el-button @click="quxiao">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    var checkPass = (rule, value, cb) => {
      if (this.updateForm.newPass === value) {
        return cb()
      }
      cb(new Error('两次密码不一致'))
    }
    return {
      updateForm: {
        oldPass: '',
        newPass: '',
        re_newPass: '',
        username: ''
      },
      updateFormRules: {
        oldPass: [
          { required: true, message: '请输入原密码', trigger: 'blur' },
          {
            min: 6,
            max: 16,
            message: '用户名的字符长度在 6~16 个字符之间',
            trigger: 'blur'
          }
        ],
        newPass: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          {
            min: 6,
            max: 16,
            message: '用户名的字符长度在 6~16 个字符之间',
            trigger: 'blur'
          }
        ],
        re_newPass: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          { validator: checkPass, trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.updateForm.username = window.sessionStorage.getItem('username')
  },
  props: {
    layerid: {
      // 自动注入的layerid
      type: String,
      default: ''
    },
    iframeData: {
      // 传递的数据
      type: Object,
      default: () => {
        return {}
      }
    },
    lydata: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    async onSubmit() {
      // // 更新父对象数据信息
      this.$parent.$data.iframeData = Object.assign({}, this.updateForm)
      // console.log(this.addForm)
      const { data: res } = await this.$http.post(
        '/updateAdminPwd',
        this.$parent.$data.iframeData
      )
      console.log(res)
      // // 或者
      // this.lydata.iframeData = Object.assign(this.lydata.iframeData, this.form)
      if (res.code !== '20000') {
        this.$layer.msg(res.message)
      } else {
        this.$layer.msg(res.message)
        window.sessionStorage.clear()
        this.$layer.close(this.layerid)
      }
    },
    quxiao() {
      this.$layer.close(this.layerid)
    }
  }
}
</script>

<style lang="less" scoped>
.el-form-item__content {
  margin-left: 20px !important;
}
.content {
  width: 100%;
}
.el-form {
  margin: 50px 20%;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-icon-plus {
  border: 1px dashed #d9d9d9 !important;
  margin-bottom: 10px !important;
  margin-left: 70px !important;
}
</style>
