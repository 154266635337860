<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加商品</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 提示区域 -->
      <el-alert
        title="添加商品信息"
        type="info"
        center
        show-icon
        :closable="false"
      >
      </el-alert>

      <!-- 步骤条面板区域 -->
      <el-steps
        :space="200"
        :active="activeIndex - 0"
        finish-status="success"
        align-center
      >
        <el-step title="基本信息"></el-step>
        <el-step title="商品参数"></el-step>
        <!-- <el-step title="商品属性"></el-step> -->
        <el-step title="商品图片"></el-step>
        <el-step title="商品内容"></el-step>
        <el-step title="完成"></el-step>
      </el-steps>

      <!-- tab栏区域 -->
      <el-form
        :model="addForm"
        :rules="addRules"
        ref="addFormRef"
        label-width="100px"
        label-position="top"
      >
        <el-tabs
          :tab-position="'left'"
          v-model="activeIndex"
          :before-leave="beforeTabLeave"
          @tab-click="tabClicked"
        >
          <el-tab-pane label="商品内容" name="0">
            <el-form-item label="商品名称" prop="goodsName">
              <el-input v-model="addForm.goodsName"></el-input>
            </el-form-item>
            <el-form-item label="商品价格" prop="goodsPrice">
              <el-input
                v-model="addForm.goodsPrice"
                placeholder="格式最好填写为:0.00"
              ></el-input>
            </el-form-item>
            <a-alert
              message="提示：商品重量这一栏,保持默认就可以,不需要改动"
              type="info"
              show-icon
              closable
            />
            <el-form-item label="商品重量" prop="goodsWeight">
              <el-input v-model="addForm.goodsWeight" type="number"></el-input>
            </el-form-item>
            <a-alert
              message="提示：排序默认升序，就是数值越大，排序越靠后。这里排序在显示上会影响同级的先后显示顺序（比如市级目的地，县级目的地）。一般不需要改动，如需设置，可以调小数值(范围需在1-999之间)。"
              type="info"
              show-icon
              closable
            />
            <el-form-item label="排序" prop="sorted">
              <el-input
                v-model.number="addForm.sorted"
                type="number"
              ></el-input>
            </el-form-item>
            <a-alert
              message="提示：终点车就是直达车，过路车是中途车，（如果选过路车）需要绑定到终点车，会绑定该终点车的相关属性（比如票数，标签，当然后期也可以添加。不明白可以联系管理员）"
              type="info"
              show-icon
              closable
            />
            <el-form-item
              label="是否过路车(是过路车时才选，不是保持默认)"
              prop="isMiddleCar"
            >
              <!-- <el-radio v-model="addForm.isMiddleCar" label="1" border>终点车</el-radio>
              <el-radio v-model="addForm.isMiddleCar" label="2" border>过路车</el-radio> -->
              <el-radio-group v-model="addForm.isMiddleCar">
                <el-radio :label="0" border>终点车</el-radio>
                <el-radio :label="1" border>过路车</el-radio>
              </el-radio-group>
            </el-form-item>
            <!-- <el-alert
              title="注意：如果是县级目的地才需要选择所属市级班次分类，如果是添加的班次是市级则保持默认（若误选可以清除一下），否侧会影响班次整体市县级的包含关系"
              type="warning"
              left
              show-icon
              :closable="true"
            >
            </el-alert> -->
            <a-alert
              message="注意：如果是县级目的地才需要选择所属市级班次分类，如果是添加的班次是市级则保持默认（若误选可以清除一下），否侧会影响班次整体市县级的包含关系。下面会自动提供所有市级选项。如需选择自行选择。"
              type="info"
              show-icon
              closable
            />
            <el-form-item label="选择所属市级班次分类" prop="pLists">
              <!-- 级联选择器区域 -->
              <el-cascader
                clearable
                v-model="addForm.parentId"
                :options="pLists"
                :props="{
                  expandTrigger: 'hover',
                  label: 'placeIn',
                  value: 'goodsId',
                  children: 'children'
                }"
                @change="handleChange"
              >
              </el-cascader>
            </el-form-item>
            <el-form-item label="选择分类" prop="goods_cat">
              <!-- 级联选择器区域 -->
              <el-cascader
                clearable
                v-model="addForm.goods_cat"
                :options="cateList"
                :props="{
                  expandTrigger: 'hover',
                  label: 'catName',
                  value: 'id',
                  children: 'children'
                }"
                @change="handleChange"
              >
              </el-cascader>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="商品参数" name="1">
            <a-alert
              message="默认是三级联动选择，如需自己输入可以切换手动输入"
              type="info"
              show-icon
              closable
            />
            <br />
            <a-radio-group
              name="radioGroup"
              :default-value="1"
              @change="onChange"
            >
              出发地目的地输入模式：
              <a-radio :value="1">
                选择（默认）
              </a-radio>
              <a-radio :value="2">
                手动输入
              </a-radio>
            </a-radio-group>
            <div v-if="isValue">
              <el-form-item label="出发地" prop="placeIn">
                <el-input v-model="addForm.placeIn"></el-input>
              </el-form-item>
              <el-form-item label="目的地" prop="placeOut">
                <el-input v-model="addForm.placeOut"></el-input>
              </el-form-item>
            </div>
            <div v-else>
              <el-form-item label="出发地" prop="placeIn">
                <!-- <el-input v-model="addressForm.address1"></el-input> -->
                <el-cascader
                  placeholder="试试搜索 洛阳"
                  :options="areaData"
                  v-model="addForm.placeIn"
                  :props="{
                    expandTrigger: 'hover',
                    value: 'label',
                    emitPath: false,
                    checkStrictly: true
                  }"
                  clearable
                  filterable
                ></el-cascader>
              </el-form-item>
              <el-form-item label="目的地" prop="placeOut">
                <!-- <el-input v-model="addressForm.address1"></el-input> -->
                <el-cascader
                  placeholder="试试搜索 洛阳"
                  :options="areaData"
                  v-model="addForm.placeOut"
                  :props="{
                    expandTrigger: 'hover',
                    value: 'label',
                    emitPath: false,
                    checkStrictly: true
                  }"
                  filterable
                  clearable
                ></el-cascader>
              </el-form-item>
            </div>
            <div v-if="!addForm.isMiddleCar">
              <a-alert
                message="出发日期添加，可以在这里添加出发日期，同一车次默认只能添加5个出发日期（后续可以自己继续添加），每个日期至少添加一个标签。"
                type="info"
                show-icon
                closable
              />
              <!-- 动态表单项  开始 ----------------------- -->
              <div class="button-search">
                <el-row>
                  <span style="font-weight:bold;">出发日期添加(必填项)</span>
                </el-row>
                <i
                  style="font-size:24px; cursor: pointer;color:#4C94F4;"
                  class="el-icon-circle-plus-outline"
                  @click.prevent="addHeader()"
                ></i>
                <!-- <el-button
                slot="append"
                icon="el-icon-circle-plus-outline"
                size="large"
                @click="addHeader()"
              ></el-button> -->
              </div>
              <!-- 动态增加项目 -->
              <!-- 不止一个项目，用div包裹起来 -->
              <div
                v-for="(item, index) in addForm.headers"
                :key="index"
                style=""
              >
                <div class="div-inline">
                  <el-form-item
                    :prop="'headers.' + index + '.headerKey'"
                    :rules="{
                      required: true,
                      message: '不能为空',
                      trigger: 'blur'
                    }"
                    style="width: 100px;"
                  >
                    <!-- <el-input v-model="item.headerKey"></el-input> -->
                    <el-date-picker
                      value-format="yyyy-MM-dd"
                      v-model="item.headerKey"
                      type="date"
                      placeholder="选择日期"
                    >
                    </el-date-picker>
                  </el-form-item>
                </div>
                <!-- <span class="distance_style_10">=</span>

              <div class="div-inline distance_style_10">
                <el-form-item
                  :prop="'headers.' + index + '.headerVal'"
                  :rules="{
                    required: true,
                    message: '不能为空',
                    trigger: 'blur'
                  }"
                  class="input_width_300"
                >
                  <el-input v-model="item.headerVal"></el-input>
                </el-form-item>
              </div> -->

                <div class="div-inline distance_style_10">
                  <el-form-item>
                    <!-- <el-link type="primary" :underline="false">删除</el-link> -->
                    <i
                      style="font-size:22px; cursor: pointer;color:red;padding-top:8px;"
                      class="el-icon-remove-outline"
                      @click.prevent="removeHeader(item, index)"
                    ></i>
                  </el-form-item>
                </div>
                <!-- 动态表单项2  开始 ----------------------- -->
                <div style="margin-left:80px;">
                  <a-alert
                    v-if="index == 0"
                    message="提示：每个日期记录至少有一个标签项，这里最多允许绑定5条（随后可添加）。如果所有标签上车地点一样则可以其他直接复制就好了"
                    type="warning"
                    show-icon
                    closable
                  />
                  <div class="button-search1">
                    <el-row>
                      <span style="font-weight:bold;"
                        >添加该日期对应标签项</span
                      >
                    </el-row>
                    <i
                      style="font-size:24px; cursor: pointer;color:#58D1FF;"
                      class="el-icon-circle-plus-outline"
                      @click.prevent="addHeader2(index)"
                    ></i>
                    <!-- <el-button
                slot="append"
                icon="el-icon-circle-plus-outline"
                size="large"
                @click="addHeader()"
              ></el-button> -->
                  </div>
                  <!-- 动态增加项目 -->
                  <!-- 不止一个项目，用div包裹起来 -->
                  <!--------- ------------------------------------------------------------------------------------------- -->
                  <div
                    v-for="(itemInner, indexInner) in item.children"
                    :key="indexInner"
                  >
                    <div class="div-inline1">
                      <el-form-item
                        :prop="
                          'headers.' +
                            index +
                            '.children.' +
                            indexInner +
                            '.name'
                        "
                        :rules="{
                          required: true,
                          message: '不能为空',
                          trigger: 'blur'
                        }"
                        style="width: 100px;"
                      >
                        <el-input
                          size="mini"
                          v-model="itemInner.name"
                          placeholder="标签名称"
                        ></el-input>
                      </el-form-item>
                    </div>

                    <span class="distance_style_101">--</span>

                    <div class="div-inline1 distance_style_101">
                      <el-form-item
                        width="180px"
                        :prop="
                          'headers.' +
                            index +
                            '.children.' +
                            indexInner +
                            '.number'
                        "
                        :rules="{
                          required: true,
                          message: '不能为空',
                          trigger: 'blur'
                        }"
                        class="input_width_300"
                      >
                        <el-input
                          size="mini"
                          v-model="itemInner.number"
                          placeholder="票数"
                          type="number"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <span class="distance_style_101">--</span>

                    <div class="div-inline1 distance_style_101">
                      <el-form-item
                        :prop="
                          'headers.' +
                            index +
                            '.children.' +
                            indexInner +
                            '.timeIn'
                        "
                        :rules="{
                          required: true,
                          message: '不能为空',
                          trigger: 'blur'
                        }"
                        class="input_width_300"
                      >
                        <el-time-select
                          size="mini"
                          v-model="itemInner.timeIn"
                          :picker-options="{
                            start: '08:30',
                            step: '00:15',
                            end: '18:30'
                          }"
                          placeholder="预计出发时间"
                          style="width:180px !important;"
                        >
                        </el-time-select>
                        <!-- <el-input
                    v-model="item.headerVal"
                    placeholder="number"
                  ></el-input> -->
                      </el-form-item>
                    </div>
                    <span class="distance_style_101">--</span>

                    <div class="div-inline1 distance_style_101">
                      <el-form-item
                        :prop="
                          'headers.' +
                            index +
                            '.children.' +
                            indexInner +
                            '.timeOut'
                        "
                        :rules="{
                          required: true,
                          message: '不能为空',
                          trigger: 'blur'
                        }"
                        class="input_width_300"
                      >
                        <!-- <el-input
                    v-model="item.headerVal"
                    placeholder="number"
                  ></el-input> -->
                        <el-time-select
                          style="width:180px !important;"
                          size="mini"
                          v-model="itemInner.timeOut"
                          :picker-options="{
                            start: '08:30',
                            step: '00:15',
                            end: '18:30'
                          }"
                          placeholder="预计到达时间"
                        >
                        </el-time-select>
                      </el-form-item>
                    </div>
                    <span class="distance_style_101">--</span>

                    <div class="div-inline1 distance_style_101">
                      <el-form-item
                        :prop="
                          'headers.' +
                            index +
                            '.children.' +
                            indexInner +
                            '.longLat'
                        "
                        :rules="{
                          required: true,
                          message: '不能为空',
                          trigger: 'blur'
                        }"
                        class="input_width_300"
                      >
                        <el-input
                          size="mini"
                          v-model="itemInner.longLat"
                          placeholder="上车地点坐标"
                        ></el-input>
                      </el-form-item>
                    </div>

                    <div class="div-inline distance_style_1011">
                      <el-form-item>
                        <i
                          style="font-size:16px; cursor: pointer;color:black;padding-top:8px;margin-left:-18px;"
                          class="el-icon-position"
                          @click.prevent="
                            openCoordinate(item, index, indexInner)
                          "
                        ></i>
                      </el-form-item>
                    </div>

                    <div class="div-inline distance_style_1011">
                      <el-form-item>
                        <!-- <el-link type="primary" :underline="false">删除</el-link> -->
                        <i
                          style="font-size:20px; cursor: pointer;color:#D3D3D3;padding-top:8px;"
                          class="el-icon-remove-outline"
                          @click.prevent="
                            removeHeader2(item, index, indexInner)
                          "
                        ></i>
                      </el-form-item>
                    </div>
                  </div>
                </div>
                <!-- 动态表单项2  结束 ----------------------- -->
              </div>
            </div>
            <div v-else>
              <a-alert
                message="提示：在这里选择要绑定的班次（这里获取所有 未下架 不是过路车的所有记录），然后绑定班次对应的日期和标签"
                type="info"
                show-icon
                closable
              />
              <el-form-item label="选择要绑定的终点车班次" prop="pList">
                <!-- 级联选择器区域 -->
                <el-cascader
                  clearable
                  v-model="addForm.pid"
                  :options="pList"
                  :props="{
                    expandTrigger: 'hover',
                    label: 'placeIn',
                    value: 'goodsId',
                    children: 'children'
                  }"
                  @change="handleChange"
                >
                </el-cascader>
              </el-form-item>
              <a-button type="primary" @click="showModal">
                点击编辑绑定详细信息
              </a-button>
              <a-modal
                v-drag-modal
                width="76%"
                v-model="visible"
                title="编辑绑定详情"
                ok-text="确认"
                cancel-text="取消"
                @ok="handleOk"
                @cancel="handleCancel"
              >
                <!-- 表格区域 -->
                <tree-table
                  :data="bindlist"
                  :columns="columns"
                  :selection-type="false"
                  :expand-type="false"
                  show-index
                  index-text="#"
                  border
                >
                  <!-- 是否有效 列区域 -->
                  <template slot="isok" slot-scope="scope">
                    <i
                      class="el-icon-success"
                      v-if="scope.row.status === true"
                      style="color: lightgreen; font-size: 20px;"
                    ></i>
                    <i v-else-if="scope.row.status === null"></i>
                    <i class="el-icon-error" v-else style="color: red"></i>
                  </template>

                  <!-- 排序列 -->
                  <template slot="tagname" slot-scope="scope">
                    <el-tag size="mini" v-if="scope.row.name">{{
                      scope.row.name
                    }}</el-tag>
                    <i v-else></i>
                    <!-- <el-tag
                      type="success"
                      size="mini"
                      v-else-if="scope.row.cat_level === 1"
                      >二级</el-tag
                    >
                    <el-tag type="warning" size="mini" v-else>三级</el-tag> -->
                  </template>
                  <!-- 标签 票数数量 -->
                  <template slot="tagnumber" slot-scope="scope">
                    <!-- <el-tag size="mini"></el-tag> -->
                    <!-- <el-tag
                      type="success"
                      size="mini"
                      v-else-if="scope.row.cat_level === 1"
                      >二级</el-tag
                    >-->
                    <el-badge
                      :value="scope.row.number"
                      class="item"
                      type="warning"
                    >
                    </el-badge>
                  </template>

                  <!-- 操作列 -->
                  <template slot="opt" slot-scope="scope">
                    <div v-if="scope.row.status != null">
                      <el-button
                        type="success"
                        plain
                        icon="el-icon-edit"
                        size="mini"
                        v-if="scope.row.name != null"
                        @click="renameTag(scope.row)"
                        >重命名标签</el-button
                      >
                      <el-button
                        type="danger"
                        plain
                        icon="el-icon-delete"
                        size="mini"
                        @click="deleteTag(scope.row)"
                        >删除</el-button
                      >
                    </div>
                  </template>
                </tree-table>
              </a-modal>
            </div>
            <!-- 动态表单项  结束 ----------------------- -->
            <!-- <el-form-item label="上车地点经纬度坐标" prop="departurePlace">
              <el-input
                v-model="addForm.departurePlace"
                style="width: 300px"
              ></el-input>
              <el-button
                type="success"
                style="margin-left:10px;"
                @click="openCoordinate"
                >坐标拾取</el-button
              >
            </el-form-item> -->
            <el-form-item label="是否允许使用优惠券">
              <el-switch
                v-model="addForm.discountStatus"
                active-color="#13ce66"
                inactive-color="#d9d9d9"
              >
              </el-switch>
            </el-form-item>
            <el-form-item label="是否允许分享">
              <el-switch
                v-model="addForm.shareStatus"
                active-color="#13ce66"
                inactive-color="#d9d9d9"
              >
              </el-switch>
            </el-form-item>
            <el-form-item v-if="addForm.shareStatus" label="分享返利金额（仅当设置允许分享的时候有效）" prop="rebateModeNumber">
              <el-input
                v-model="addForm.rebateModeNumber"
                placeholder="格式最好填写为:0.00"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="选择要绑定的终点车班次" prop="pList" v-else> -->
            <!-- 级联选择器区域 -->
            <!-- <el-cascader
                clearable
                v-model="addForm.pid"
                :options="pList"
                :props="{
                  expandTrigger: 'hover',
                  label: 'placeIn',
                  value: 'goodsId',
                  children: 'children'
                }"
                @change="handleChange"
              >
              </el-cascader>
            </el-form-item> -->
          </el-tab-pane>
          <!-- <el-tab-pane label="商品属性" name="2">
            <el-form-item
              :label="item.attr_name"
              v-for="item in onlyTabData"
              :key="item.attr_id"
            >
              <el-input v-model="item.attr_vals"></el-input>
            </el-form-item>
          </el-tab-pane> -->
          <el-tab-pane label="商品图片" name="3">
            <a-alert
              message="提示：正常情况下选择默认图片就好了，因为小程序显示的图片一般是统一的，如确实需要添加新的，可以再自行上传。"
              type="info"
              show-icon
              closable
            />
            <br />
            <!-- action 图片要上传的地址 -->
            <el-checkbox v-model="checked" @change="handlePicChange"
              >使用默认图片</el-checkbox
            >
            <el-link
              :underline="false"
              type="primary"
              v-if="checked"
              @click="handlePreview1"
              >查看默认图片</el-link
            >
            <br />
            <br />
            <el-upload
              :action="uploadURL"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              list-type="picture"
              :headers="headersObj"
              :on-success="handleSuccess"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件，且不超过500kb
              </div>
            </el-upload>
          </el-tab-pane>
          <el-tab-pane label="商品内容" name="4">
            <a-alert
              message="提示：这里可以正常设置一下注意事项，详情等，此处没有集成画路线图，可以添加班次成功后在编辑中画出路线图。"
              type="info"
              show-icon
              closable
            />
            <br />
            <!-- Two-way Data-Binding 富文本编辑器组件-->
            <quill-editor
              ref="myQuillEditor"
              v-model="addForm.goodsIntroduce"
              @blur="onEditorBlur($event)"
            />
            <!-- 添加商品的文本 -->
            <el-button type="success" class="btnAdd" @click="add"
              >添加商品</el-button
            >
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </el-card>

    <!-- 图片预览 -->
    <el-dialog title="图片预览" :visible.sync="previewVisible" width="50%">
      <img :src="previewPath" alt="" class="previewImg" />
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import Loc from '@/components/goods/Loc'
import AreaJson from '../user/area.json'
export default {
  name: 'GoodsAdd',
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('排序数值不能为空'))
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error('请输入数字值'))
        } else if (value < 1) {
          callback(new Error('排序设置数字范围在1-999之间'))
        } else {
          if (value >= 1000) {
            callback(new Error('排序设置数字范围在1-999之间'))
          } else {
            callback()
          }
        }
      }, 800)
    }
    return {
      // 级联选择器 城市
      areaData: [],
      pList: [],
      pLists: [],
      activeIndex: '0',
      // 添加商品的表单数据对象
      addForm: {
        goodsName: '大巴车票',
        goodsPrice: '', // 价格
        goodsWeight: 1, // 重量
        sorted: 999, // 排序字段
        isMiddleCar: 0, // 是否过路车
        pid: 0, // 绑定商品 过路车才有此项
        parentId: [0], // 绑定市级班次id
        // departurePlace: '',
        // 商品所属的分类数组
        goods_cat: [],
        // 图片的数组
        pics: [], // 商品图片数组
        // 商品的详情描述
        goodsIntroduce: '', // 商品描述
        headers: [], // 时间 标签关系数组
        headerKey: '',
        headerVal: '',
        discountStatus: false, // 是否允许使用优惠券
        shareStatus: false, // 是否允许分享
        optName: window.sessionStorage.getItem('username'),
        rebateModeNumber: 0.00
      },
      addRules: {
        goodsName: [
          { required: true, message: '请输入商品名称', trigger: 'blur' }
        ],
        goodsPrice: [
          { required: true, message: '请输入商品价格', trigger: 'blur' }
        ],
        goodsWeight: [
          { required: true, message: '请输入商品重量', trigger: 'blur' }
        ],
        sorted: [{ validator: checkAge, trigger: 'blur' }],
        goods_cat: [
          { required: true, message: '请选择商品分类', trigger: 'blur' }
        ],
        placeIn: [
          { required: true, message: '请选择商品分类', trigger: 'blur' }
        ],
        placeOut: [
          { required: true, message: '请选择商品分类', trigger: 'blur' }
        ]
      },
      // 商品分类列表数组
      cateList: [],
      // 动态参数列表数据
      manyTabData: [],
      // 静态属性列表数据
      onlyTabData: [],
      uploadURL: 'https://api.shunhou.wandoukeji.com/api/v1/uploadImage',
      // 图片上传组件的请求头
      headersObj: {
        token: window.sessionStorage.getItem('token')
      },
      previewPath: '',
      previewVisible: false,
      isValue: false, // 出发地目的地切换
      testInfo: {
        children: [],
        name: '',
        number: '',
        timeIn: '',
        timeOut: '',
        longLat: ''
      },
      checked: false,
      visible: false,
      // 商品分类的数据列表 默认为空
      bindlist: [],
      // 为table指定列的定义
      columns: [
        {
          label: '出发地',
          prop: 'placeIn',
          width: '120px'
        },
        {
          label: '目的地',
          prop: 'placeOut',
          width: '120px'
        },
        {
          label: '出发日期',
          prop: 'departmenTime',
          width: '160px'
        },
        {
          label: '是否下架',
          // 表示 将当前列定义为模板列
          type: 'template',
          template: 'isok',
          width: '66px'
        },
        {
          label: '标签名称',
          // 表示 将当前列定义为模板列
          type: 'template',
          // 表示当前这一列使用的模板名称
          template: 'tagname',
          width: '240px'
        },
        {
          label: '票数',
          // 表示 将当前列定义为模板列
          type: 'template',
          // 表示当前这一列使用的模板名称
          template: 'tagnumber',
          width: '66px'
        },
        {
          label: '操作',
          // 表示 将当前列定义为模板列
          type: 'template',
          // 表示当前这一列使用的模板名称
          template: 'opt',
          width: '200px'
        }
      ],
      tmpform: {},
      tmpBindList: {}
    }
  },
  created() {
    this.getCateList()
    this.getLists()
    this.areaData = AreaJson
  },
  methods: {
    async deleteTag(row) {
      // 弹窗展示是否删除数据
      const confirmResult = await this.$confirm('您确定要删除？', '提示', {
        confirmButtonText: '嗯',
        cancelButtonText: '算了',
        type: 'warning'
      }).catch(err => err)

      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除操作~')
      }
      var tmp = this.bindlist[0] || []
      if (row.name == null) {
        // 说明删除的日期
        for (let index = 0; index < tmp.children.length; index++) {
          // eslint-disable-next-line eqeqeq
          if (tmp.children[index].id == row.id) {
            tmp.children.splice(index, 1)
          }
        }
        this.bindlist[0] = tmp
        this.tmpform = tmp
        console.log('--->>><<<---', this.tmpform)
        return null
      } else {
        for (let index = 0; index < tmp.children.length; index++) {
          for (
            let inner = 0;
            inner < tmp.children[index].children.length;
            inner++
          ) {
            // eslint-disable-next-line eqeqeq
            if (tmp.children[index].children[inner].tagId == row.tagId) {
              tmp.children[index].children.splice(inner, 1)
            }
          }
        }
        this.bindlist[0] = tmp
        this.tmpform = tmp
        console.log('--->>><<<---', this.tmpform)
      }
    },
    renameTag(row) {
      this.$prompt('请输入要绑定的标签名字', '重命名', {
        confirmButtonText: '重命名',
        cancelButtonText: '取消操作',
        inputPattern: /^[a-zA-Z0-9_\u4E00-\u9FA5][a-zA-Z0-9_\u4E00-\u9FA5]{0,36}$/,
        inputErrorMessage: '字数太长，36个以内'
      })
        .then(async ({ value }) => {
          var tmp = this.bindlist[0] || []
          for (let index = 0; index < tmp.children.length; index++) {
            for (
              let inner = 0;
              inner < tmp.children[index].children.length;
              inner++
            ) {
              // eslint-disable-next-line eqeqeq
              if (tmp.children[index].children[inner].tagId == row.tagId) {
                tmp.children[index].children[inner].name = value
                tmp.children[index].children[inner].isRewrite = true
              }
            }
          }
          this.bindlist[0] = tmp
          this.tmpform = tmp
          console.log('--->>><<<---', this.tmpform)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '你取消了重命名操作'
          })
        })
    },
    async showModal() {
      this.bindlist = []
      // eslint-disable-next-line eqeqeq
      if (this.addForm.pid == 0) {
        return this.$antMessage.info('请先选择要绑定的终点车班次')
      }
      const { data: res } = await this.$http.get(`products/${this.addForm.pid}`)
      if (res.code !== '20000') {
        this.$message.error(res.message)
      }
      console.log(res)
      this.bindlist.push(res.data.list)
      this.tmpBindList = res.data.list
      console.log('=====', this.bindlist)
      this.visible = true
    },
    handleOk(e) {
      console.log(e)
      this.visible = false
    },
    handleCancel() {
      this.visible = false
    },
    handlePicChange(value) {
      console.log('----|||----', value)
      if (value) {
        // 1.先拼接得到一个图片信息对象
        const picInfo = {
          pic:
            'http://api.shunhou.wandoukeji.com/upimg/images/f65546a65c7d4da0916933558c2b172e.png'
        }
        // 2.push到pics数组中
        this.addForm.pics.push(picInfo)
        this.previewPath =
          'http://api.shunhou.wandoukeji.com/upimg/images/f65546a65c7d4da0916933558c2b172e.png'
      } else {
        const filePath =
          'http://api.shunhou.wandoukeji.com/upimg/images/f65546a65c7d4da0916933558c2b172e.png'
        const i = this.addForm.pics.findIndex(x => x.pic === filePath)
        this.addForm.pics.split(i, 1)
        this.previewPath = ''
      }
    },
    // =============================动态添加  表单项方法开始
    addHeader() {
      if (this.addForm.headers.length > 4) {
        return this.$antMessage.info('最多允许添加5条日期记录')
      }
      this.addForm.headers.push({
        headerKey: '',
        children: []

        // headerVal: ''
      })
    },
    removeHeader(item, index) {
      this.addForm.headers.splice(index, 1)
    },
    addHeader2(index) {
      if (this.addForm.headers[index].children.length > 4) {
        return this.$antMessage.info('最多允许设置5条标签记录')
      }
      this.addForm.headers[index].children.push({
        name: '',
        number: 0,
        timeIn: '',
        timeOut: '',
        longLat: ''
      })
    },
    removeHeader2(item, index, indexInner) {
      this.addForm.headers[index].children.splice(indexInner, 1)
    },
    // ==============================动态添加  表单项方法结束
    onChange(e) {
      console.log('radio checked', e.target.value)
      // eslint-disable-next-line eqeqeq
      if (e.target.value == 2) {
        this.isValue = true
      } else {
        this.isValue = false
      }
    },
    // 获取所有市级的班车列表
    async getLists() {
      const { data: res } = await this.$http.get('getProductLists')
      if (res.code !== '20000') {
        this.$message.error('获取市级班次列表失败')
      }
      console.log(res)
      this.pLists = res.data.data
      this.$message.success('获取市级班次列表成功!')
    },
    openCoordinate(item, index, indexInner) {
      this.$layer.iframe({
        content: {
          content: Loc,
          parent: this,
          data: { iframeData: this.iframeData }
        },
        area: ['1100px', '700px'],
        title: '坐标拾取',
        maxmin: true,
        shade: false,
        shadeClose: false,
        cancel: () => {
          // console.log(this.$store.loc)
          try {
            this.addForm.headers[index].children[indexInner].longLat =
              this.$store.loc.lng +
              ',' +
              this.$store.loc.lat +
              ',' +
              this.$store.loc.address
          } catch (err) {}

          // 关闭弹窗事件
          // alert('关闭iframe')
        }
      })
    },
    async getCateList() {
      const { data: res } = await this.$http.get('categories')
      if (res.code !== '20000') {
        this.$message.error('收取商品分类数据失败！')
      }
      this.cateList = this.getTreeData(res.data.categorys)
      this.$message.success('获取商品分类数据成功！')
    },
    // 级联选择器 选中项变化 会触发的函数
    handleChange() {},
    beforeTabLeave(activeName, oldActiveName) {
      if (oldActiveName === '0' && this.addForm.goods_cat.length !== 2) {
        // this.$message.info('请先选择商品分类')
        this.$message({
          showClose: true,
          message: '请先选择商品分类'
        })
        return false
      }
      // console.log(this.addForm.goods_cat)
    },
    async tabClicked() {
      // 证明访问的是动态参数面板
      if (this.activeIndex === '1') {
        const { data: res } = await this.$http.get('getProductList')
        if (res.code !== '20000') {
          this.$message.error('获取终点车列表失败')
        }
        console.log(res)
        this.pList = res.data.data
        this.$message.success('获取终点车列表成功!')
        // const { data: res } = await this.$http.get(
        //   `categories/${this.cateId}/attributes`,
        //   {
        //     params: {
        //       sel: 'many'
        //     }
        //   }
        // )
        // if (res.meta.status !== 200) {
        //   return this.$message.error('获取数据失败！')
        // }
        // res.data.forEach(item => {
        //   item.attr_vals =
        //     item.attr_vals.length === 0 ? [] : item.attr_vals.split(',')
        // })
        // this.manyTabData = res.data
      } else if (this.activeIndex === '2') {
        // console.log(this.activeIndex)
        // const { data: res } = await this.$http.get(
        //   `categories/${this.cateId}/attributes`,
        //   {
        //     params: {
        //       sel: 'only'
        //     }
        //   }
        // )
        // if (res.meta.status !== 200) {
        //   return this.$message.error('获取数据失败！')
        // }
        // this.onlyTabData = res.data
      }
    },
    // 处理图片预览效果
    handlePreview(file) {
      // console.log(file)
      this.previewPath = file.response.data.path
      this.previewVisible = true
    },
    handlePreview1() {
      this.previewVisible = true
    },
    // 处理移除图片的操作
    handleRemove(file) {
      const filePath = file.response.data.tem_path
      const i = this.addForm.pics.findIndex(x => x.pic === filePath)
      this.addForm.pics.split(i, 1)
    },
    // 图片上传成功时的钩子函数
    handleSuccess(response) {
      console.log(response)
      // 1.先拼接得到一个图片信息对象
      const picInfo = { pic: response.data.path }
      // 2.push到pics数组中
      this.addForm.pics.push(picInfo)
      console.log(this.addForm.pics)
    },
    onEditorBlur(ev) {
      console.log(ev)
      console.log(this.addForm.goodsIntroduce)
    },
    // 添加商品
    add() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) {
          this.$message.error('请填写必要的表单项！')
        }
        console.log(this.addForm)
        if (this.addForm.isMiddleCar) {
          // 证明是过路车
          var tmp = this.bindlist[0]
          for (let index = 0; index < tmp.children.length; index++) {
            this.addForm.headers.push({
              headerKey: tmp.children[index].id,
              children: tmp.children[index].children
            })
          }
        }

        // 校验是否tag合法及至少有一个标签
        for (let index = 0; index < this.addForm.headers.length; index++) {
          if (this.addForm.headers[index].children.length < 1) {
            return this.$antMessage.info(
              '每一个出行日期至少有一个标签,请重新编辑'
            )
          }
        }
        // const from = _.cloneDeep(this.addForm)
        // from.pics = ''
        // this.addForm.pics.forEach(v => {
        //   console.log(v.pic)
        //   from.pics += v.pic + ','
        // })
        // // 执行添加的业务逻辑
        // from.catId = from.goods_cat.join(',')
        // from.goods_cat = null
        // console.log(from)
        // // var date1 = new Date(String(from.date[0]).replace(/-/g, '/'))
        // // var time1 = date1.getTime()
        // // var date2 = new Date(String(from.date[1]).replace(/-/g, '/'))
        // // var time2 = date2.getTime()
        // if (from.parentId.length > 0) {
        //   from.parentId = parseInt(from.parentId[0])
        // } else {
        //   from.parentId = 0
        // }
        // if (from.pid.length > 0) {
        //   from.pid = parseInt(from.pid[0])
        // } else {
        //   from.date1 = from.date[0] + ''
        //   from.date2 = from.date[1] + ''
        // }
        // from.isMiddleCar = from.isMiddleCar + ''

        // // // 处理动态参数
        // // this.manyTabData.forEach(item => {
        // //   const newInfo = {
        // //     attr_id: item.attr_id,
        // //     attr_value: item.attr_vals.join(',')
        // //   }
        // //   this.addForm.attrs.push(newInfo)
        // // })
        // // // 处理静态属性
        // // this.onlyTabData.forEach(item => {
        // //   const newInfo = {
        // //     attr_id: item.attr_id,
        // //     attr_value: item.attr_vals
        // //   }
        // //   this.addForm.attrs.push(newInfo)
        // // })

        // // from.attrs = this.addForm.attrs
        // // 发起请求 添加商品
        // console.log(from)
        const from = _.cloneDeep(this.addForm)
        from.pid = this.addForm.pid[0] || 0
        from.pics = ''
        this.addForm.pics.forEach(v => {
          console.log(v.pic)
          from.pics += v.pic + ','
        })
        const { data: res } = await this.$http.post('goods', from)
        console.log(res)
        if (res.code !== '20000') {
          return this.$message.error(res.message)
        }
        this.$message.success(res.message)
        // 关闭add页面
        var item = {
          componentName: 'GoodsAdd',
          detail: '/goods/add',
          name: '添加商品'
        }
        this.$store.dispatch('clearToolBar', item)
        this.$store.dispatch('clearCache', item.componentName)
        // 编程式导航 跳转到列表页
        this.$router.push('/goods')
      })
    },

    getTreeData(data) {
      // 循环遍历json数据 解决空childrenbug问题
      for (var i = 0; i < data.length; i++) {
        if (data[i].children.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].children = undefined
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.getTreeData(data[i].children)
        }
      }
      return data
    }
  },
  computed: {
    cateId() {
      if (this.addForm.goods_cat.length === 3) {
        return this.addForm.goods_cat[2]
      }
      return null
    }
  }
}
</script>

<style lang="less" scoped>
.el-step__icon is-text {
  color: aqua !important;
}
.el-step__icon-inner {
  color: blue;
}

.el-step__head.is-process {
  border-color: blue;
}
.el-checkbox {
  margin-right: 5px !important;
}
.previewImg {
  width: 100%;
}
.btnAdd {
  margin-top: 15px;
}
.div-inline {
  display: inline-block;
}

.button-search {
  display: flex;
  justify-content: space-between;
  margin-right: 42em;
  margin-bottom: 14px;
  margin-top: 18px;
  span {
  }
}
.distance_style_10 {
  margin-left: 10em;
  text-align: center;
  line-height: 62px;
}

.div-inline1 {
  display: inline-block;
}

.button-search1 {
  display: flex;
  justify-content: space-between;
  margin-right: 32em;
  margin-bottom: 14px;
  margin-top: 18px;
  span {
  }
}
.distance_style_1011 {
  text-align: center;
  margin-left: 2em;
}
.distance_style_101 {
  width: 180px;
}
.input_width_300 .el-input {
}
</style>
