<template>
  <div id="app">
    <!-- App根组件 -->
    <!-- 路由占位符 -->
    <!--渲染标签的地方-->
    <router-view />
    <div
      style="z-index:10000;position:fixed;text-align:center;bottom:6px;margin:0 auto;width:100%;color: #939393;"
    >©2022-2023&nbsp;顺猴出行
      <a target="_blank" style="color: #939393;text-decoration:none;" href="https://beian.miit.gov.cn"
        >豫ICP备2021024300号</a
      >&nbsp;|&nbsp;  <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41058102000240">
          <img src="https://wandoukeji.com/images/%E5%A4%87%E6%A1%88%E5%9B%BE%E6%A0%87.png" style="height:20px;width:20px;"/><p style="height:20px;line-height:20px;color:#939393;display:inline-block;">豫公网安备 41058102000240号</p></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style lang="less">
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.el-form-item.is-success .el-input__inner,
.el-form-item.is-success .el-input__inner:focus,
.el-form-item.is-success .el-textarea__inner,
.el-form-item.is-success .el-textarea__inner:focus {
  border-color: #67c23a;
}
.el-form-item.is-success .el-input-group__append .el-input__inner,
.el-form-item.is-success .el-input-group__prepend .el-input__inner {
  border-color: transparent;
}
.el-form-item.is-success .el-input__validateIcon {
  color: #67c23a;
}
</style>
