import { render, staticRenderFns } from "./Test.vue?vue&type=template&id=21b84e34&scoped=true&"
import script from "./Test.vue?vue&type=script&lang=js&"
export * from "./Test.vue?vue&type=script&lang=js&"
import style0 from "./Test.vue?vue&type=style&index=0&id=21b84e34&lang=less&scoped=true&"
import style1 from "./Test.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21b84e34",
  null
  
)

export default component.exports