<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图区 -->
    <el-card class="box-card">
      <!-- 搜索与添加区域 -->
      <el-row :gutter="40">
        <el-col :span="6">
          <el-input
            placeholder="试试搜索 微信名称"
            v-model="queryInfo.query"
            clearable
            @clear="getUserList()"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              :style="active"
              @mouseover.native="mouseOver()"
              @mouseout.native="mouseLeave()"
              @click="getUserList()"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="12"
          >搜索条件：
          <a-radio-group
            default-value="1"
            button-style="solid"
            @change="onChange"
          >
            <a-radio-button value="1">
              用户名
            </a-radio-button>
            <a-radio-button value="2">
              手机号
            </a-radio-button>
          </a-radio-group>
        </el-col>
      </el-row>
      <!-- 用户列表区域 -->
      <el-table
        :data="userlist"
        border
        stripe
        height="610px"
        style="width: 100%"
      >
        <el-table-column type="index" fixed="left" label="#"></el-table-column>
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="邮箱:">
                <span>{{ props.row.email }}</span>
              </el-form-item>
              <br />
              <el-form-item label="电话:">
                <span>{{ props.row.userPhone }}</span>
              </el-form-item>
              <el-form-item label="更新时间:">
                <span>{{ props.row.updateTime }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column width="100px" label="微信名称" prop="name">
          <template slot-scope="scope">
            <el-tag size="medium">{{ scope.row.name }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="头像" prop="avatar" width="66px">
          <template slot-scope="scope">
            <el-avatar :size="'samll'" :src="scope.row.avatar"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column
          width="80px"
          label="真实姓名"
          prop="realName"
        ></el-table-column>
        <!-- <el-table-column
          label="邮箱"
          prop="email"
          width="130px"
        ></el-table-column>
        <el-table-column
          label="电话"
          prop="userPhone"
          width="100px"
        ></el-table-column> -->
        <el-table-column
          width="90px"
          label="城市"
          prop="city"
        ></el-table-column>
        <el-table-column width="100px" label="分享返利" prop="rebatePrice">
          <template slot-scope="scope">
            <el-tag type="success" size="medium"
              >{{ scope.row.rebatePrice }}&nbsp;元</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column width="80px" label="积分数量" prop="rebateScore">
          <template slot-scope="scope">
            <!-- <el-tag type="warning" size="medium">{{
              scope.row.ticketSum
            }}</el-tag> -->
            <el-badge
              style="margin-top:1em;"
              :value="scope.row.rebateScore"
              class="item"
              type="red"
            >
            </el-badge>
          </template>
        </el-table-column>
        <el-table-column width="80px" label="购票次数" prop="ticketSum">
          <template slot-scope="scope">
            <!-- <el-tag type="warning" size="medium">{{
              scope.row.ticketSum
            }}</el-tag> -->
            <el-badge
              style="margin-top:1em;"
              :value="scope.row.ticketSum"
              class="item"
              type="primary"
            >
            </el-badge>
          </template>
        </el-table-column>
        <!--
        <el-table-column label="优惠券是否使用" prop="roleId">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.roleId === '1'">超级管理员</el-tag>
            <el-tag type="success" v-else-if="scope.row.roleId === '2'"
              >普通管理员</el-tag
            >
          </template>
        </el-table-column> -->
        <el-table-column label="优惠卷状态" width="90px">
          <template slot-scope="scope">
            <!-- {{scope.row}} -->
            <el-switch
              v-model="scope.row.status"
              @change="usersChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="核校权限" width="70px">
          <template slot-scope="scope">
            <!-- {{scope.row}} -->
            <el-switch
              v-model="scope.row.verifyStatus"
              @change="verifyStatusChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          label="创建日期"
          prop="createTime"
          width="134px"
        ></el-table-column>
        <el-table-column label="管理权限" width="100px">
          <template slot-scope="scope">
            <!-- {{scope.row}} -->
            <el-switch
              v-model="scope.row.manageStatus"
              @change="managerChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="280px">
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              content="编辑信息"
              placement="top"
              :enterable="false"
            >
              <!-- 修改按钮 -->
              <el-button
                type="success"
                icon="el-icon-edit"
                size="mini"
                plain
                circle
                @click="showEditDialog(scope.row.id)"
              ></el-button>
            </el-tooltip>
            <!-- 删除按钮 -->
            <el-button
              type="danger"
              icon="fa fa-trash"
              size="mini"
              plain
              circle
              @click="removeUserById(scope.row.id)"
            ></el-button>
            <!-- 分配角色按钮 -->
            <el-tooltip
              effect="dark"
              content="查看优惠券"
              placement="top"
              :enterable="false"
            >
              <el-button
                plain
                type="primary"
                icon="fa fa-eye"
                size="mini"
                circle
                @click="showTicketDiscount(scope.row)"
              ></el-button>
            </el-tooltip>

            <!-- 显示该用户所有订单 -->
            <el-tooltip
              effect="dark"
              content="显示该用户所有订单"
              placement="top"
              :enterable="false"
            >
              <el-button
                plain
                type="warning"
                icon="fa fa-circle-o"
                size="mini"
                circle
                @click="setRole(scope.row)"
              ></el-button>
            </el-tooltip>
            <!-- 显示该用户所有订单 -->
            <el-tooltip
              effect="dark"
              content="设置权限班次"
              placement="top"
              :enterable="false"
            >
              <el-button
                plain
                type="warning"
                icon="fa fa-cog"
                size="mini"
                circle
                @click="setPrem(scope.row)"
              ></el-button>
            </el-tooltip>
            <!-- 查看该用户的分享情况 -->
            <el-tooltip
              effect="dark"
              content="查看用户分享详情"
              placement="top"
              :enterable="false"
            >
              <el-button
                plain
                type="info"
                icon="fa fa-gg"
                size="mini"
                circle
                @click="setPrem(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              v-if="scope.row.verifyStatus"
              effect="dark"
              content="查看该用户核校详细"
              placement="top"
              :enterable="false"
            >
              <el-button
                plain
                type=""
                icon="el-icon-edit-outline"
                size="mini"
                circle
                @click="verifyLog(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页显示 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[20, 40, 60, 80]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 修改用户的对话框 -->
    <el-dialog
      title="修改微信用户信息"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <el-form
        :model="editFrom"
        :rules="editFromRules"
        ref="editFromRef"
        label-width="90px"
      >
        <el-form-item label="用户名称">
          <el-input v-model="editFrom.name"></el-input>
        </el-form-item>
        <el-form-item label="邮 箱" prop="email">
          <el-input v-model="editFrom.email"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="Phone">
          <el-input v-model="editFrom.Phone"></el-input>
        </el-form-item>
        <el-form-item label="城 市" prop="city">
          <el-input v-model="editFrom.city"></el-input>
        </el-form-item>
        <el-form-item label="分享返利" prop="rebatePrice">
          <el-input v-model="editFrom.rebatePrice"></el-input>
        </el-form-item>
        <el-form-item label="购买次数" prop="ticketSum">
          <el-input v-model="editFrom.ticketSum"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUserInfo">修 改</el-button>
      </span>
    </el-dialog>

    <!-- 分配角色对话框 -->
    <el-dialog
      title="哒哒哒~"
      :visible.sync="setRoleDialogVisible"
      width="50%"
      @close="setRoleDialogClosed"
    >
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="createTime" label="下单日期" width="180">
        </el-table-column>
        <el-table-column prop="orderNumber" label="订单号" width="180">
        </el-table-column>
        <el-table-column prop="orderSum" label="数量"> </el-table-column>
        <el-table-column prop="orderPrice" label="单价"> </el-table-column>
        <el-table-column prop="payStatus" label="是否付款">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.payStatus == '1'"
              >已付款</el-tag
            >
            <el-tag type="primary" v-else-if="scope.row.payStatus == '2'"
              >退款中</el-tag
            >
            <el-tag type="primary" v-else-if="scope.row.payStatus == '3'"
              >未退款</el-tag
            >
            <el-tag type="primary" v-else-if="scope.row.payStatus == '4'"
              >已退款</el-tag
            >
            <el-tag type="danger" v-else>未付款</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="isExpire" label="是否(待出行)历史订单">
          <template slot-scope="scope">
            <el-tag type="primary" v-if="scope.row.isExpire">是</el-tag>
            <el-tag type="info" v-else>否</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!--更新权限对话框-->
    <el-dialog
      title="权限设置"
      :visible.sync="editDialogVisible1"
      width="30%"
      @close="editDialogClosed1"
    >
      <!-- 提示区域 -->
      <el-alert
        title="选择授权班次，更新权限"
        type="info"
        center
        show-icon
        :closable="false"
        style="margin-bottom: 10px;"
      >
      </el-alert>
      <el-dropdown style="margin-bottom: 80px;" @command="handleCommand">
        <el-button type="primary">
          选择授权班次<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="item in pdflist"
            :key="item.goodsId"
            :command="item.goodsId"
            >{{ item.placeIn + '➡' + item.placeOut }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
      <el-tag type="warning">{{ goodsId }}</el-tag>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="savePrem">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 显示核校详细 -->
    <el-dialog
      title="核校详情"
      :visible.sync="setRoleDialogVisible6"
      width="54%"
      @close="setRoleDialogClosed6"
    >
      <el-button type="primary" mini @click="saveXlsx"
        >下载为excel文件</el-button
      >
      <el-table id="table-excel" :data="tableData6" stripe style="width: 100%">
        <el-table-column prop="createTime" label="创建时间" width="180">
        </el-table-column>
        <el-table-column prop="createTime" label="更新时间" width="180">
        </el-table-column>
        <el-table-column prop="name" label="名字" width="180">
        </el-table-column>
        <el-table-column prop="phone" label="电话号" width="180">
        </el-table-column>
        <el-table-column width="80px" label="核校状态">
          <template slot-scope="scope">
            <i
              class="el-icon-success"
              v-if="scope.row.status"
              style="color: lightgreen; font-size: 20px;"
            ></i>
            <i
              class="el-icon-error"
              v-else
              style="color: red;font-size: 20px;"
            ></i>
          </template>
        </el-table-column>
        <el-table-column prop="status" width="70px" label="核校状态">
          <template slot-scope="scope">
            {{ scope.row.status ? '已核校' : '未核校' }}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import AreaJson from './area.json'
import Tickets from '@/components/user/Tickets'
export default {
  name: 'User',
  data() {
    // 验证邮箱的规则
    var checkEmail = (rule, value, cb) => {
      // 验证邮箱的正则表达式
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
      if (regEmail.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法的邮箱！'))
    }
    var checkMobile = (rule, value, cb) => {
      // 验证手机号规则
      const regMobile = /^([0|86|17951])?(13[0-9]|15[0123456789]|17[678]|18[0-9]|19[0-9]|14[57])[0-9]{8}$/
      if (regMobile.test(value)) {
        return cb()
      }
      cb(new Error('手机号不合法！'))
    }

    // 验证邮箱的规则
    var checkPrice = (rule, value, cb) => {
      if (value >= 0 && value <= 40) {
        return cb()
      }
      cb(new Error('请输入合法的数值！'))
    }
    return {
      // 获取用户列表的参数
      queryInfo: {
        query: '',
        type: 1,
        pagenum: 1,
        pagesize: 20
      },
      userlist: [],
      tableData: [],
      tableData6: [],
      total: 0,
      active: '',
      //   控制对话框的显示与隐藏
      addDialogVisible: false,
      editDialogVisible: false,
      editDialogVisible1: false,
      editFrom: {},
      editFromRules: {
        name: [
          { required: true, message: '请输入用户名称', trigger: 'blur' },
          {
            min: 2,
            max: 10,
            message: '用户名称的字符长度在 3~10 个字符之间',
            trigger: 'blur'
          }
        ],
        city: [
          { required: true, message: '请输入城市', trigger: 'blur' },
          {
            min: 1,
            max: 10,
            message: '城市的字符长度在 3~10 个字符之间',
            trigger: 'blur'
          }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' }
        ],
        Phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ],
        rebatePrice: [
          { required: true, message: '请输入金额', trigger: 'blur' },
          { validator: checkPrice, trigger: 'blur' }
        ]
      },
      // 控制分配角色对话框的显示与隐藏
      setRoleDialogVisible: false,
      setRoleDialogVisible6: false,
      // 需要被分配角色的用户信息
      userInfo: {},
      // 所有角色的数据列表
      rolesList: [
        {
          value: '1',
          label: '超级管理员'
        },
        {
          value: '2',
          label: '普通管理员'
        }
      ],
      // 已经选中的角色id值
      selectedRoleId: '',
      pdflist: [],
      goodsId: 0,
      userId: 0
    }
  },
  created() {
    this.getUserList()
  },
  methods: {
    saveXlsx() {
      // eslint-disable-next-line no-undef
      const wb = XLSX.utils.table_to_book(
        document.querySelector('#table-excel')
      )
      // eslint-disable-next-line no-undef
      const wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array'
      })
      try {
        // eslint-disable-next-line no-undef
        saveAs(
          new Blob([wbout], { type: 'application/octet-stream' }),
          'wdkj.xlsx'
        )
      } catch (e) {
        if (typeof console !== 'undefined') console.log(e, wbout)
      }
    },
    onChange(e) {
      console.log('radio checked', e.target.value)
      this.queryInfo.type = e.target.value
    },
    async verifyStatusChanged(userInfo) {
      const { data: res } = await this.$http.put(
        `users/${userInfo.id}/verifyState/${userInfo.verifyStatus}`
      )
      if (res.code !== '20000') {
        userInfo.status = !userInfo.status
        return this.$message.error('更新核校状态失败!')
      }
      this.$message.success('更新核校状态成功！')
    },
    async getPdfList() {
      const { data: res } = await this.$http.get('getProductInfo/info')
      if (res.code !== '20000') {
        return this.$message.error('获取数据失败！')
      }
      this.pdflist = res.data.list
      // console.log(res.data.list)
    },
    async getPdfList1() {
      const { data: res } = await this.$http.get(`/verify/${this.userId}`)
      if (res.code !== '20000') {
        return this.$message.error('获取数据失败！')
      }
      this.tableData6 = res.data.data
      // console.log(res.data.list)
    },
    handleCommand(command) {
      // console.log('-------------', command)
      this.goodsId = command
    },
    async setPrem(row) {
      this.userId = row.id
      await this.getPdfList()
      this.editDialogVisible1 = true
    },
    async verifyLog(row) {
      this.userId = row.id
      await this.getPdfList1()
      this.setRoleDialogVisible6 = true
    },
    async savePrem() {
      // eslint-disable-next-line eqeqeq
      if (this.goodsId == undefined || this.goodsId == '') {
        this.$message.info('请先选择班次信息')
        return false
      }
      const { data: res } = await this.$http.put(
        `users/${this.userId}/permission/${this.goodsId}`
      )
      if (res.code !== '20000') {
        return this.$message.error('设置权限失败!')
      }
      this.editDialogVisible1 = false
      this.$message.success('设置权限成功！')
    },
    editDialogClosed1() {
      this.editDialogVisible1 = false
    },
    async getUserList() {
      const { data: res } = await this.$http.get('users', {
        params: this.queryInfo
      })
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      console.log(res)
      this.userlist = res.data.lists
      this.total = Number(res.data.total)
    },
    // 监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      //   console.log(newSize)
      this.queryInfo.pagesize = newSize
      this.getUserList()
    },
    // 监听 页码值改变的事件
    handleCurrentChange(newPage) {
      //   console.log(newPage)
      this.queryInfo.pagenum = newPage
      this.getUserList()
    },
    async managerChanged(userInfo) {
      const { data: res } = await this.$http.put(
        `users/${userInfo.id}/managerState/${userInfo.status}`
      )
      if (res.code !== '20000') {
        userInfo.status = !userInfo.status
        return this.$message.error('更新权限失败!')
      }
      this.$message.success('更新权限成功！')
    },
    // 监听开关状态的改变
    async usersChanged(userInfo) {
      const { data: res } = await this.$http.put(
        `users/${userInfo.id}/state/${userInfo.status}`
      )
      if (res.code !== '20000') {
        userInfo.status = !userInfo.status
        return this.$message.error('更新优惠券状态失败!')
      }
      this.$message.success('更新优惠券状态成功！')
    },
    mouseOver() {
      this.active = 'background-color: #ccc;'
      //   console.log('鼠标移动事件')
    },
    mouseLeave() {
      this.active = ''
    },
    // 编辑用户的对话框
    async showEditDialog(id) {
      console.log(id)
      const { data: res } = await this.$http.get(`getUserInfo/${id}`)
      console.log(res)
      if (res.code !== '20000') {
        return this.$message.error('查询用户失败！')
      }
      this.$message.success('查询用户成功！')
      this.editFrom = res.data
      // this.editFrom.city = Array(res.data.city)
      this.editDialogVisible = true
    },
    showTicketDiscount(data) {
      console.log(data.id)
      // this.$bus.$emit('id', data.id)
      // this.$bus.$on('id', val => {
      //   console.log('========>', val)
      // })
      this.$layer.iframe({
        content: {
          // eslint-disable-next-line no-undef
          content: Tickets,
          parent: this,
          // eslint-disable-next-line dot-notation
          data: { iframeData: { id: data.id } }
        },
        area: ['360px', '600px'],
        title: '优惠券操作',
        maxmin: true,
        shade: false,
        shadeClose: false,
        cancel: () => {
          // 关闭弹窗事件
          // alert('关闭iframe')
        }
      })
    },
    editDialogClosed() {
      this.$refs.editFromRef.resetFields()
    },
    editUserInfo() {
      this.$refs.editFromRef.validate(async valid => {
        if (!valid) {
          // eslint-disable-next-line no-useless-return
          return
        }
        // 此操作为敏感操作 弹窗展示是否修改数据
        const confirmResult = await this.$confirm(
          '微信用户信息是用户登录自动获得,确定要修改吗？',
          '提示',
          {
            confirmButtonText: '嗯',
            cancelButtonText: '不了',
            type: 'warning'
          }
        ).catch(err => err)

        if (confirmResult !== 'confirm') {
          return this.$message.info('已取消操作！')
        }
        console.log(this.editFrom)
        // 发起请求，修改用户信息
        const { data: res } = await this.$http.put(
          'users/' + this.editFrom.id,
          {
            email: this.editFrom.email,
            userPhone: this.editFrom.Phone,
            city: this.editFrom.city,
            name: this.editFrom.name,
            rebatePrice: this.editFrom.rebatePrice,
            ticketSum: this.editFrom.ticketSum,
            version: this.editFrom.version
          }
        )
        console.log(res)
        if (res.code !== '20000') {
          return this.$message.error('更新信息失败！')
        }
        // 关闭对话框
        this.editDialogVisible = false
        // 刷新数据列表
        this.getUserList()
        // 提示修改成功
        this.$message.success('更新信息成功！')
      })
    },
    // 根据id删除对应的用户
    async removeUserById(id) {
      // 弹窗展示是否删除数据
      const confirmResult = await this.$confirm(
        '此操作将永远删除账户，并不是逻辑删除, 是否继续操作?',
        '提示',
        {
          confirmButtonText: '是，朕已经决定了',
          cancelButtonText: '还是算了',
          type: 'warning'
        }
      ).catch(err => err)

      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除！')
      }
      const { data: res } = await this.$http.delete('users/' + id)
      if (res.code !== '20000') {
        this.$message.error('删除用户失败！')
      }
      this.$message.success('删除用户成功！')
      this.getUserList()
    },
    // 展示分配角色对话框
    async setRole(userInfo) {
      // 需要被分配角色的用户信息
      this.userInfo = userInfo
      // 在展示对话框前 获取角色列表
      const { data: res } = await this.$http.get(
        `users/getOrder/${userInfo.id}`
      )

      if (res.code !== '20000') {
        return this.$message.error('获取订单列表失败！')
      }
      this.tableData = res.data.list
      this.$message.success('获取订单列表成功！')
      this.setRoleDialogVisible = true
    },
    // 点击按钮分配角色
    async saveRoleInfo() {
      if (!this.selectedRoleId) {
        return this.$message.error('请选择要分配的角色！')
      }
      const { data: res } = await this.$http.put(
        `users/${this.userInfo.id}/role`,
        {
          rid: this.selectedRoleId
        }
      )
      if (res.meta.status !== 200) {
        this.$message.error('分配角色失败！')
      }
      this.$message.success('分配角色成功！')
      this.getUserList()
      this.setRoleDialogVisible = false
    },
    // 监听分配角色的关闭事件
    setRoleDialogClosed() {
      this.selectedRoleId = ''
      this.userInfo = {}
    },
    // 监听分配角色的关闭事件
    setRoleDialogClosed6() {
      // this.selectedRoleId = ''
      // this.userInfo = {}
    }
  }
}
</script>

<style lang="less" scoped></style>
