<template>
  <div class="min">
    <div id="min-box">
      <div id="min-img">
        <div id="Seconds">0</div>
      </div>
      <div class="min-card" v-if="isShow">
        <div class="min-name">正在跳转</div>
        <div class="min-txt">微信扫码成功！正在跳转到管理面板主页......</div>
        <div class="min-button">
          <a class="min-Jump" id="jumUrl">立即前往</a>
        </div>
      </div>
      <div class="min-card" v-else>
        <div class="min-name">正在跳转</div>
        <div class="min-txt">扫码失败！正在跳转到登录页面......</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'callback',
  data() {
    return {
      isShow: true
    }
  },
  methods: {},
  async mounted() {
    const token = await this.$route.query.token
    const message = await this.$route.query.message
    const name = await this.$route.query.name
    console.log(token)
    console.log(name)
    console.log(message)
    // eslint-disable-next-line eqeqeq
    if (message != undefined || token == undefined || name == undefined) {
      this.isShow = false
      this.$message.error({
        showClose: true,
        message: message
      })
      this.$router.push('/login')
      return
    }
    var that = this

    await window.sessionStorage.setItem('token', token)
    await window.sessionStorage.setItem('username', name)
    // 2.通过编程是导航跳转到后台主页 路由地址是/home
    // this.$bus.emit('name1', this.loginFrom.username)
    var t = 3
    document.getElementById('Seconds').innerHTML = t
    const jump = document.getElementById('jumUrl')
    jump.click = function() {
      that.$router.push('/home')
    }
    setInterval(function() {
      if (t === 0) {
        // location.href = getQueryVariable('site')
        that.$router.push('/home')
        t -= 1
      } else if (t > 0) {
        t -= 1
        document.getElementById('Seconds').innerHTML = t
      }
    }, 970)
    function getQueryVariable(variable) {
      var query = window.location.search.substring(1)
      var vars = query.split('&')
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        // eslint-disable-next-line eqeqeq
        if (pair[0] == variable) {
          return pair[1]
        }
      }
      return false
    }

    this.$message.success({
      showClose: true,
      message: '登陆成功！'
    })
    // window.location.replace = 'http://localhost:8080/#/home'
  }
}
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
a,
a:hover {
  text-decoration: none;
}
body {
  margin: 0;
  padding: 0;
  color: #324e63;
  font-family: Quicksand, sans-serif;
}
.min {
  display: flex;
  padding: 50px 20px;
  width: 100%;
  height: auto;
  min-height: 100vh;
  /* background-image: url(https://cdn.jsdelivr.net/gh/Moe-dog/prprpr.love@0.6/statics/img/1.jpg); */
  background-image: url(https://api.shunhou.wandoukeji.com/upimg/images/redirect.jpeg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
#min-box {
  position: relative;
  margin: auto;
  width: 20pc;
  max-width: 700px;
  border-radius: 9pt;
  background: rgba(255, 255, 255, 0.44);
  box-shadow: 0 8px 60px -10px rgba(13, 28, 39, 0.6);
}
#min-img {
  position: relative;
  z-index: 4;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.44);
  box-shadow: 0 5px 50px 0 #ddd, 0 0 0 7px rgba(255, 255, 255, 0);
  transform: translateY(-50%);
}
.min-card {
  margin-top: -35px;
  padding: 0 20px;
  padding-bottom: 40px;
  text-align: center;
  transition: all 0.3s;
}
.min-name {
  margin-bottom: 15px;
  color: #333;
  font-weight: 700;
  font-size: 24px;
}
.min-txt {
  margin-bottom: 15px;
  color: #324e63;
  font-weight: 500;
  font-size: 18px;
}
.min-button {
  display: flex;
  margin-top: 40px;
  justify-content: center;
  align-items: center;
}
.min-Jump {
  margin: 15px 35px;
  margin-right: 0;
  margin-left: 0;
  padding: 15px 40px;
  min-width: 201px;
  min-height: 55px;
  border: none;
  border-radius: 50px;
  background: linear-gradient(45deg, #1da1f2, #0e71c8);
  box-shadow: 0 4px 30px rgba(19, 127, 212, 0.4);
  color: #fff;
  font-weight: 700;
  font-size: 19px;
  cursor: pointer;
  transition: all 0.3s;
  backface-visibility: hidden;
}
.min-Jump:focus {
  outline: 0 !important;
}
.min-Jump:hover {
  transform: translateY(-5px);
}
#Seconds {
  margin-top: 30px;
  color: #000;
  text-align: center;
  font-size: 68px;
}
@media screen and (max-width: 767px) {
  .min {
    padding-top: 75pt;
    height: auto;
    min-height: 100vh;
  }
  .min-Jump {
    margin: 15px 25px;
    min-width: 170px;
  }
  #Seconds {
    margin-top: 20px;
  }
}
@media screen and (max-width: 576px) {
  #min-img {
    width: 90pt;
    height: 90pt;
  }
  #min-box {
    width: 100%;
  }
  .min-button {
    flex-wrap: wrap;
  }
  .min-Jump {
    margin: 0;
    margin-bottom: 1pc;
    width: 100%;
    max-width: 300px;
    min-width: inherit;
  }
  .min-Jump:last-child {
    margin-bottom: 0;
  }
}
</style>
