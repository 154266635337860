<template>
  <div style="width: 100%;margin: 18px;">
    <el-button type="success" round plain @click="openSocket"
      >开启日志</el-button
    >
    <el-button type="danger" round plain @click="closeSocket"
      >关闭日志</el-button
    >
    <h3 style="color: #ccc;">jvm进程内的日志</h3>
    <div
      id="log-container"
      style="height: 200px; overflow-y: scroll; background: #333; color: #aaa; padding: 10px;margin-top: 12px;"
    >
      <div></div>
    </div>
    <h3 style="color: #ccc;">指定监听日志文件的日志</h3>
    <div
      id="filelog-container"
      style="height: 200px; overflow-y: scroll; background: #333; color: #aaa; padding: 10px;margin-top: 12px;"
    >
      <div></div>
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
var stompClient = null
export default {
  data() {
    return {}
  },
  props: {
    layerid: {
      // 自动注入的layerid
      type: String,
      default: ''
    },
    iframeData: {
      // 传递的数据
      type: Object,
      default: () => {
        return {}
      }
    },
    lydata: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    closeSocket() {
      if (stompClient != null) {
        stompClient.disconnect()
        stompClient = null
      }
    },

    openSocket() {
      var token = window.sessionStorage.getItem('token')
      if (stompClient == null) {
        // eslint-disable-next-line no-undef
        var socket = new SockJS(
          'https://api.shunhou.wandoukeji.com/websocket?token=' + token
        )
        // eslint-disable-next-line no-undef
        // var socket = new SockJS('https://api.shunhou.wandoukeji.com/websocket?token=' + token)
        // eslint-disable-next-line no-undef
        stompClient = Stomp.over(socket)
        stompClient.debug = () => {}
        stompClient.connect({ token: token }, function(frame) {
          stompClient.subscribe(
            '/topic/pullLogger',
            function(event) {
              var content = JSON.parse(event.body)
              $('#log-container div')
                .append(
                  content.timestamp +
                    ' ' +
                    content.level +
                    ' --- [' +
                    content.threadName +
                    '] ' +
                    content.className +
                    '   :' +
                    content.body
                )
                .append('<br/>')
              $('#log-container').scrollTop(
                $('#log-container div').height() - $('#log-container').height()
              )
            },
            {
              token: token
            }
          )
          stompClient.subscribe(
            '/topic/pullFileLogger',
            function(event) {
              var content = event.body
              $('#filelog-container div')
                .append(content)
                .append('<br/>')
              $('#filelog-container').scrollTop(
                $('#filelog-container div').height() -
                  $('#filelog-container').height()
              )
            },
            {
              token: token
            }
          )
        })
      }
    }
  },
  mounted() {
    this.openSocket()
  }
}
</script>
<style lang="less" scoped></style>
