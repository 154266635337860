<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>退款订单</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图区域 -->
    <el-card>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input
            placeholder="请输入商家订单号进行搜索"
            v-model="queryinfo.query"
            clearable
            @clear="getRefundList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getRefundList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="text" @click="queryRefundOrderInfo()"
            >实时查询订单退款情况</el-button
          >
        </el-col>
      </el-row>
      <!-- 提示区域 -->
      <el-alert
        title="注意：退款订单号 只有批准后才会生成 然后调起微信退款进行相关退款逻辑处理  且该操作不可逆 请谨慎处理"
        type="error"
        center
        :closable="false"
        style="margin-bottom: 10px;margin-top: 8px;"
      >
      </el-alert>
      <!-- table 表格区域 -->
      <el-table :data="goodsList" border stripe>
        <el-table-column type="index" label="#"></el-table-column>
        <!-- table 扩展区域 -->
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="创建时间">
                <span>{{ props.row.createTime }}</span>
              </el-form-item>
              <br />
              <el-form-item label="更新时间">
                <span>{{ props.row.updateTime }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column label="商家订单号" prop="outTradeNo" width="160px">
        </el-table-column>
        <el-table-column label="退款订单号" prop="outRefundNo" width="160px">
        </el-table-column>
        <el-table-column label="出发地" prop="placeIn" min-width="120px">
        </el-table-column>
        <el-table-column label="目的地" prop="placeOut" min-width="120px">
        </el-table-column>
        <el-table-column label="出发日期" prop="departTime" width="160px">
        </el-table-column>
        <el-table-column label="标签名称" prop="tagName" min-width="100px">
        </el-table-column>
        <el-table-column label="班次上架者" prop="optName" min-width="100px">
        </el-table-column>
        <el-table-column label="实付金额" prop="refundFee" width="92px">
          <template slot-scope="scope">
            <el-tag size="medium" type="success"
              >{{ scope.row.refundFee }}&nbsp;元</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          label="优惠券金额"
          prop="orderPayDiscount"
          width="88px"
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.orderPayDiscount"
              size="medium"
              type="success"
              >{{ scope.row.orderPayDiscount }}&nbsp;元</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="手续费" prop="procedureFee" width="88px">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.procedureFee" size="medium" type="success"
              >{{ scope.row.procedureFee }}&nbsp;元</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="总退款金额" prop="refundSum" width="92px">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.refundSum" size="medium" type="success"
              >{{ scope.row.refundSum }}&nbsp;元</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="处理状态" prop="refundStatus" width="110px">
          <template slot-scope="scope">
            <div v-if="scope.row.hour != null">
              <el-tag
                v-if="scope.row.refundStatus == '2'"
                size="medium"
                type="success"
                >系统处理中</el-tag
              >
              <el-tag
                v-if="scope.row.refundStatus == '1'"
                size="medium"
                type="success"
                >同意退款</el-tag
              >
              <el-tag
                v-if="scope.row.refundStatus == '3'"
                size="medium"
                type="error"
                >已撤销</el-tag
              >
              <el-tag
                v-if="scope.row.refundStatus == '0' && scope.row.refundSum"
                size="medium"
                type="success"
                >不同意退款</el-tag
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column label="hour" prop="hour" width="92px">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.hour" size="medium" type="success">{{
              scope.row.hour
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="退款原因" prop="" width="92px">
          <template slot-scope="scope">
            <el-button
              type="warning"
              size="mini"
              icon="fa fa-eye"
              plain
              circle
              @click="showReason(scope.row.description)"
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column label="退款状态" prop="description" width="80px"
          ><template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              @change="usersChanged(scope.row)"
            >
            </el-switch> </template
        ></el-table-column>
        <el-table-column label="操作" width="130px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              icon="fa fa-pencil"
              plain
              circle
              @click="showEditDialog(scope.row)"
            ></el-button>
            <el-button
              type="danger"
              size="mini"
              icon="el-icon-delete"
              plain
              circle
              @click="removeRefundById(scope.row.refundId)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 页面分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pagenum"
        :page-sizes="[1, 5, 10, 20]"
        :page-size="queryinfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      >
      </el-pagination>
      <el-dialog
        title="安全校验"
        :visible.sync="dialogFormVisible"
        width="500px"
      >
        <el-form :model="valid">
          <el-form-item label="请输入登录密码" :label-width="'120px'">
            <el-input
              v-model="valid.pwd"
              type="password"
              autocomplete="off"
              style="width:240px"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="success" @click="validPwd">校 验</el-button>
        </div>
      </el-dialog>
      <el-dialog
        title="退款审核"
        :visible.sync="dialogFormVisible1"
        width="500px"
      >
        <el-alert
          title="请在5分钟之内完成操作，否则需要重新进行安全校验"
          type="error"
          center
          :closable="false"
          style="margin-bottom: 10px;margin-top: 8px;"
        >
        </el-alert>
        <el-input v-model="row.refundSum" prefix-icon="el-icon-coin"></el-input>
        <div style="margin-top:24px;">
          <el-radio v-model="radio1" label="1" border>同意退款</el-radio>
          <el-radio v-model="radio1" label="2" border>不同意退款</el-radio>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible1 = false">取 消</el-button>
          <el-button type="warning" @click="refund()">确定</el-button>
        </div>
      </el-dialog>
      <!-- 根据订单号查询订单结果 -->
      <el-dialog
        title="根据商家订单号查询退款结果"
        :visible.sync="editDialogVisible3"
        width="35%"
        @close="editDialogClosed3"
      >
        <!-- 提示区域 -->
        <el-alert
          title="输入订单号实时请求微信支付平台查询退款结果"
          type="error"
          center
          :closable="false"
          style="margin-bottom: 10px;"
        >
        </el-alert>
        <el-form
          :model="editFrom3"
          :rules="editFromRules"
          ref="editFromRef1"
          label-width="90px"
        >
          <el-form-item label="商家订单号" prop="orderNo">
            <el-input
              v-model="editFrom3.refundNo"
              style="width:360px;"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible3 = false">取 消</el-button>
          <el-button type="success" @click="editUserInfo3">查 询</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>
<script>
import Qs from 'qs'
export default {
  name: 'Refund',
  data() {
    return {
      radio1: '1',
      editDialogVisible3: false,
      editFrom3: {},
      // 查询参数对象
      queryinfo: {
        query: '',
        pagenum: 1,
        pagesize: 5
      },
      valid: {
        pwd: ''
      },
      row: {},
      dialogFormVisible: false,
      dialogFormVisible1: false,
      // 保存商品列表
      goodsList: [],
      // 保存商品总条数
      total: 0,
      uuid: ''
    }
  },
  methods: {
    async editUserInfo3() {
      // eslint-disable-next-line eqeqeq
      if (
        // eslint-disable-next-line eqeqeq
        this.editFrom3.refundNo == undefined ||
        // eslint-disable-next-line eqeqeq
        this.editFrom3.refundNo == ''
      ) {
        return this.$message.info('订单号不能为空')
      }
      const { data: res } = await this.$http.get(
        `queryRefundPay?oid=` + this.editFrom3.refundNo
      )
      // eslint-disable-next-line eqeqeq
      if (res.code == '20000') {
        this.editDialogVisible3 = false
        this.$alert(
          '退款状态: ' +
            res.data.data.refund_status_0 +
            '   退款账户：' +
            res.data.data.refund_recv_accout_0 +
            '   退款成功时间：' +
            res.data.data.refund_success_time_0,
          '查询结果',
          {
            confirmButtonText: '确定',
            callback: action => {}
          }
        )

        return
      }
      this.$message.error(res.message)
    },
    // 根据id删除对应的用户
    async removeRefundById(id) {
      console.log(id)
      // 弹窗展示是否删除数据
      const confirmResult = await this.$confirm(
        '此操作将逻辑删除退款订单数据，并不是真正删除（数据库依然有记录）, 是否继续操作?',
        '提示',
        {
          confirmButtonText: '是，朕已经决定了',
          cancelButtonText: '还是算了',
          type: 'warning'
        }
      ).catch(err => err)

      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除！')
      }
      const { data: res } = await this.$http.delete('refund/' + id)
      if (res.code !== '20000') {
        this.$message.error('删除用户失败！')
      }
      this.$message.success('删除用户成功！')
      this.getUserList()
    },

    queryRefundOrderInfo() {
      this.editDialogVisible3 = true
    },
    editDialogClosed3() {
      this.editDialogVisible3 = false
    },
    // 退款
    async refund() {
      const { data: res } = await this.$http.post(
        'xcx/refund',
        Qs.stringify({
          orderNo: this.row.outTradeNo,
          uuid: this.uuid,
          fee: this.row.refundSum + '',
          type: this.radio1
        })
      )
      if (res.code !== '20000') {
        this.dialogFormVisible1 = false
        return this.$message.error(res.message)
      } else {
        this.dialogFormVisible1 = false
        return this.$message.success(res.message)
      }
    },
    async validPwd() {
      var username = window.sessionStorage.getItem('username')
      const pwd = this.valid.pwd
      const { data: res } = await this.$http.put(
        `/admins/validPwd/${username}/${pwd}`
      )
      if (res.code !== '20000') {
        this.$message.error(res.message)
        return false
      }
      this.dialogFormVisible = false
      this.$message.success(res.message)
      this.uuid = res.data.valid
      this.dialogFormVisible1 = true
      this.valid.pwd = ''
    },
    showEditDialog(row) {
      this.dialogFormVisible = true
      this.row = row
    },
    showReason(description) {
      this.$alert(description, '退款原因', {
        confirmButtonText: '确定',
        callback: action => {}
      })
    },
    // 根据分页获取商品列表的全部数据
    async getRefundList() {
      const { data: res } = await this.$http.get('refunds', {
        params: this.queryinfo
      })
      if (res.code !== '20000') {
        this.$message.error('获取退款订单列表失败！')
      }
      this.$message.success('获取退款订单列表成功！')
      console.log(res.data)
      this.goodsList = res.data.lists
      this.total = Number(res.data.total)
    },
    handleSizeChange(newSize) {
      this.queryinfo.pagesize = newSize
      this.getRefundList()
    },
    handleCurrentChange(newPage) {
      this.queryinfo.pagenum = newPage
      this.getRefundList()
    }
  },
  mounted() {
    this.getRefundList()
  }
}
</script>
<style lang="less" scoped></style>
