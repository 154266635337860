<template>
  <div id="container" style=" width: 100%; height: 300px;"></div>
</template>

<script>
// import { getBlogContributeCount } from '@/api/index'
import * as echarts from 'echarts'

export default {
  async mounted() {
    // this.data = this.getData(2021)
    await this.getStatisyics()
    this.initDate()
  },
  data() {
    return {
      contributeDate: ['2021-01-01', '2022-01-01'],
      blogContributeCount: [],
      data: [],
      stime: '2021-01-01',
      etime: '2022-01-01'
    }
  },
  created() {
    // this.getStatisyics()
  },
  methods: {
    initDate: function() {
      var x = []
      var y = []
      this.data.forEach(item => {
        x.push(item[0])
        y.push(item[1])
      })
      // getBlogContributeCount().then(response => {
      // console.log('我来啦', response.data.blogContributeCount)
      // eslint-disable-next-line eqeqeq
      // eslint-disable-next-line no-constant-condition
      if (true) {
        // var contributeDate = response.data.contributeDate

        var blogContributeCount = this.data
        var yearRange = this.contributeDate

        const chart = echarts.init(document.getElementById('container'))

        const options = {
          title: {
            top: 30,
            text: '访客(UV)走势统计',
            subtext: '一年内每月的访客数量',
            left: 'center',
            textStyle: {
              color: '#000'
            }
          },
          tooltip: {
            trigger: 'axis'
          },
          xAxis: [
            {
              type: 'category',
              data: x,
              axisLine: {
                lineStyle: {
                  color: '#999'
                }
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              splitNumber: 4,
              splitLine: {
                lineStyle: {
                  type: 'dashed',
                  color: '#DDD'
                }
              },
              axisLine: {
                show: false,
                lineStyle: {
                  color: '#333'
                }
              },
              nameTextStyle: {
                color: '#999'
              },
              splitArea: {
                show: false
              }
            }
          ],
          series: [
            {
              name: '访客数量',
              type: 'line',
              data: y,
              lineStyle: {
                normal: {
                  width: 8,
                  color: {
                    type: 'linear',

                    colorStops: [
                      {
                        offset: 0,
                        color: '#A9F387' // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: '#48D8BF' // 100% 处的颜色
                      }
                    ],
                    globalCoord: false // 缺省为 false
                  },
                  shadowColor: 'rgba(72,216,191, 0.3)',
                  shadowBlur: 10,
                  shadowOffsetY: 20
                }
              },
              itemStyle: {
                normal: {
                  color: '#fff',
                  borderWidth: 10,
                  //   /*shadowColor: 'rgba(72,216,191, 0.3)',
                  // shadowBlur: 100,*/
                  borderColor: '#A9F387'
                }
              },
              smooth: true
            }
          ]
        }

        chart.setOption(options)
      }
      // })
    },
    getData: function(year) {
      year = year || '2017'
      var date = +echarts.number.parseDate(year + '-01-01')
      var end = +echarts.number.parseDate(+year + 1 + '-01-01')
      var dayTime = 3600 * 24 * 1000
      var data = []
      for (var time = date; time < end; time += dayTime) {
        data.push([
          echarts.format.formatTime('yyyy-MM-dd', time),
          Math.floor(Math.random() * 100)
        ])
      }
      return data
    },
    async getStatisyics() {
      // eslint-disable-next-line no-extend-native
      Date.prototype.format = function(fmt) {
        var o = {
          'M+': this.getMonth() + 1,
          'd+': this.getDate(),
          'h+': this.getHours(),
          'm+': this.getMinutes(),
          's+': this.getSeconds(),
          'q+': Math.floor((this.getMonth() + 3) / 3),
          S: this.getMilliseconds()
        }
        // eslint-disable-next-line curly
        if (/(y+)/.test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            (this.getFullYear() + '').substr(4 - RegExp.$1.length)
          )
        // eslint-disable-next-line curly
        // eslint-disable-next-line curly
        // eslint-disable-next-line curly
        for (var k in o)
          if (new RegExp('(' + k + ')').test(fmt))
            fmt = fmt.replace(
              RegExp.$1,
              // eslint-disable-next-line eqeqeq
              RegExp.$1.length == 1
                ? o[k]
                : ('00' + o[k]).substr(('' + o[k]).length)
            )
        return fmt
      }
      const day1 = new Date()
      day1.setDate(day1.getDate() + 25)
      const start = day1.format('yyyy-MM-dd')
      day1.setDate(day1.getDate() - 365)
      const end = day1.format('yyyy-MM-dd')
      console.log(start, end)
      const { data: res } = await this.$http.get(
        `getStatisticCountLine/${end}/${start}`
      )
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      this.data = res.data.data
      this.contributeDate[0] = res.data.data1.stime
      this.contributeDate[1] = res.data.data1.etime
    }
  }
}
</script>
