<template>
  <div id="relationG" style=" width: 98%; height:320px;"></div>
</template>

<script>
// import { getBlogContributeCount } from '@/api/index'
import * as echarts from 'echarts'

export default {
  async mounted() {
    await this.getStatisyics()
    this.initDate()
  },
  data() {
    return {
      datas: []
    }
  },
  created() {
    // this.getStatisyics()
  },
  methods: {
    initDate: function() {
      // getBlogContributeCount().then(response => {
      // console.log('我来啦', response.data.blogContributeCount)
      // eslint-disable-next-line eqeqeq
      // eslint-disable-next-line no-constant-condition
      if (true) {
        // var contributeDate = response.data.contributeDate

        var x = Object.getOwnPropertyNames(this.datas)
        x.pop('__ob__')
        var y = []
        x.sort().forEach(item => {
          y.push(this.datas[item])
        })
        const chart = echarts.init(document.getElementById('relationG'))

        // const option = {
        //   title: {
        //     top: 2,
        //     text: '用户增长曲线',
        //     subtext: '以月为单位',
        //     left: 'center',
        //     textStyle: {
        //       color: '#000'
        //     }
        //   },
        //   grid: {
        //     left: '55',
        //     right: '15',
        //     bottom: '15%',
        //     top: '10%'
        //   },
        //   tooltip: {
        //     trigger: 'axis',
        //     axisPointer: {
        //       // 坐标轴指示器，坐标轴触发有效
        //       type: 'line' // 默认为直线，可选为：'line' | 'shadow'
        //     }
        //   },
        //   xAxis: {
        //     type: 'category',
        //     data: x,
        //     // axisLine: {
        //     //   show: true //隐藏X轴轴线
        //     // },
        //     axisLine: {
        //       lineStyle: {
        //         color: '#0E4986'
        //       }
        //     },
        //     splitLine: {
        //       // 网格线
        //       show: true,
        //       lineStyle: {
        //         color: '#DEE5FB'
        //       }
        //     },
        //     axisLabel: {
        //       show: true,
        //       textStyle: {
        //         fontSize: 14,
        //         color: '#000' // X轴文字颜色
        //       }
        //     }
        //   },
        //   yAxis: {
        //     type: 'value',
        //     axisLine: {
        //       lineStyle: {
        //         color: '#0E4986'
        //       }
        //     },
        //     axisTick: {
        //       show: false
        //     },
        //     splitLine: {
        //       // 网格线
        //       show: false
        //     },
        //     axisLabel: {
        //       show: true,
        //       textStyle: {
        //         fontSize: 14,
        //         color: '#000' // X轴文字颜色
        //       }
        //     }
        //   },
        //   series: [
        //     {
        //       data: y,

        //       type: 'line',
        //       // symbol: "circle", //折线点设置为实心点
        //       symbolSize: 12, // 折线点的大小
        //       itemStyle: {
        //         normal: {
        //           color: '#58D1FF',
        //           width: '15px',
        //           lineStyle: {
        //             color: '#58D1FF',
        //             width: 5
        //           }
        //         }
        //       }
        //     }
        //   ]
        //   // dataZoom: [
        //   //   // 前面显示
        //   //   {
        //   //     textStyle: false,
        //   //     show: true,
        //   //     startValue: 0, //数据窗口范围的起始数值
        //   //     endValue: 100,
        //   //     fillerColor: "#297ACC", //选中背景颜色
        //   //     backgroundColor: "#314D70", //没选中背景颜色
        //   //     height: 10,
        //   //     bottom: "10",
        //   //     left: 30,
        //   //     width: "93%",
        //   //     borderColor: "#314D70",
        //   //     handleIcon:
        //   //       "M-292,322.2c-3.2,0-6.4-0.6-9.3-1.9c-2.9-1.2-5.4-2.9-7.6-5.1s-3.9-4.8-5.1-7.6c-1.3-3-1.9-6.1-1.9-9.3c0-3.2,0.6-6.4,1.9-9.3c1.2-2.9,2.9-5.4,5.1-7.6s4.8-3.9,7.6-5.1c3-1.3,6.1-1.9,9.3-1.9c3.2,0,6.4,0.6,9.3,1.9c2.9,1.2,5.4,2.9,7.6,5.1s3.9,4.8,5.1,7.6c1.3,3,1.9,6.1,1.9,9.3c0,3.2-0.6,6.4-1.9,9.3c-1.2,2.9-2.9,5.4-5.1,7.6s-4.8,3.9-7.6,5.1C-285.6,321.5-288.8,322.2-292,322.2z",
        //   //     handleStyle: {
        //   //       color: "#fff",
        //   //       shadowBlur: 2
        //   //     },
        //   //     handleSize: 15,
        //   //     dataBackground: {
        //   //       lineStyle: {
        //   //         opacity: 0
        //   //       },
        //   //       areaStyle: {
        //   //         opacity: 0
        //   //       }
        //   //     }
        //   //   }
        //   // ]
        // }

        const option = {
          title: {
            top: 2,
            text: '平台月交易单数',
            subtext: '以月为单位',
            left: 'center',
            textStyle: {
              color: '#000'
            }
          },
          backgroundColor: '#fff',
          grid: {
            left: '5%',
            right: '10%',
            top: '20%',
            bottom: '15%',
            containLabel: true
          },
          tooltip: {
            show: true,
            trigger: 'axis'
          },
          legend: {
            show: true,
            x: '75%',
            y: '35',
            icon: 'stack',
            itemWidth: 15,
            itemHeight: 10,
            textStyle: {
              color: '#646464'
            },
            data: ['月交易单数']
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: 1,
              axisLine: {
                show: false
              },
              axisLabel: {
                color: '#A1A7B3'
              },
              splitLine: {
                show: false
              },
              axisTick: {
                show: false
              },

              data: x
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: '成交单数',
              padding: 5,
              // max: 1000,
              splitLine: {
                show: true,
                lineStyle: {
                  color: '#A1A7B3',
                  type: 'dashed'
                }
              },
              axisLine: {
                show: false
              },
              axisLabel: {
                show: true,
                margin: 10,
                textStyle: {
                  color: '#A1A7B3'
                }
              },
              axisTick: {
                show: false
              }
            }
          ],
          series: [
            {
              name: '月交易单数',
              type: 'line',
              smooth: true,
              stack: '总量',
              symbolSize: 0,
              itemStyle: {
                normal: {
                  color: '#4293FD',
                  lineStyle: {
                    color: '#4293FD',
                    width: 2
                  }
                }
              },
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: 'rgba(19, 95, 172, 1)'
                      },
                      {
                        offset: 1,
                        color: 'rgba(112, 154, 195, 0.4)'
                      }
                    ],
                    false
                  )
                }
              },
              data: y
            }
          ]
        }

        chart.setOption(option)
      }
      // })
    },
    async getStatisyics() {
      const { data: res } = await this.$http.get(`getStatisticOrderCount`)
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      this.datas = res.data.data
    }
  }
}
</script>
