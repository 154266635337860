<template>
  <div class="yyy">
    <el-card class="zzz">
     <el-steps :active="activeIndex - 0" finish-status="success" simple style="margin-top: 20px">
        <el-step title="身份验证" >
        </el-step>
        <el-step title="密码重置" ></el-step>
        <el-step title="重置完成" ></el-step>
      </el-steps>
        <el-tabs
          :tab-position="'top'"
          v-model="activeIndex"
          :before-leave="beforeTabLeave"
        >
          <el-tab-pane name="0">
            <el-form
            :model="forgetForm"
            :rules="forgetFormRules"
            class="from_0"
            ref="forgetFormRefs" label-width="10px">
              <el-form-item prop="email">
                <el-input prefix-icon="fa fa-user"
                placeholder="请输入管理员合法邮箱" v-model="forgetForm.email"></el-input>
              </el-form-item>
              <el-form-item prop="validCode" style="width: 340px;display: flex;">
                <el-input
                style="width:280px;"
                v-model="forgetForm.validCode"
                prefix-icon="fa fa-envelope"
                placeholder="请输入验证码"
                >
                // eslint-disable-next-line vue/no-parsing-error
                <el-button :disabled="flag" @click="getAuthCode" style="padding-right:10px" slot="suffix" type="text">{{msg}}</el-button>
                </el-input>
                <el-button v-if="isTrue" style="margin-left: 6px;" size="mini" type="success" icon="el-icon-check" circle plain></el-button>
                <el-button v-if="isFalse" style="margin-left: 6px;" size="mini" type="danger" icon="el-icon-close" circle plain></el-button>
              </el-form-item>
            </el-form>
            <div class="btu">
            <el-button type="primary" @click="next">下一步</el-button>
            <el-button type="info" @click="resetFrom">重置</el-button>
            </div>
          </el-tab-pane>
          <el-tab-pane name="1">
                <el-form
              :model="updateForm"
              :rules="updateFormRules"
              ref="addFormRef"
              label-width="110px"
            >
              <el-form-item label="新密码" prop="newPass">
                <el-input v-model="updateForm.newPass" type="password"></el-input>
              </el-form-item>
              <el-form-item label="确认新密码" prop="re_newPass">
                <el-input v-model="updateForm.re_newPass" type="password"></el-input>
              </el-form-item>
            </el-form>
            <div class="btu">
            <el-button type="primary" @click="next">下一步</el-button>
            <el-button type="info" @click="resetFrom">重置</el-button>
            </div>
          </el-tab-pane>
           <el-tab-pane name="2">
            <div class="btu">
            <el-button type="success" @click="submit">提交</el-button>
            <el-button type="info" @click="shangyibu">上一步</el-button>
            </div>
          </el-tab-pane>
        </el-tabs>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    // 验证邮箱的规则
    var checkEmail = (rule, value, cb) => {
      // 验证邮箱的正则表达式
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
      if (regEmail.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法的邮箱！'))
    }
    var checkPass = (rule, value, cb) => {
      if (this.updateForm.newPass === value) {
        return cb()
      }
      cb(new Error('两次密码不一致'))
    }
    return {
      fullscreenLoading: false,
      isTrue: false,
      isFalse: false,
      msg: '获取验证码',
      activeIndex: '0',
      forgetForm: {},
      forgetFormRules: {
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' }
        ]
      },
      active: 0,
      flag: false,
      updateForm: {
        newPass: '',
        re_newPass: '',
        validCode: ''
      },
      updateFormRules: {
        newPass: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          {
            min: 6,
            max: 16,
            message: '用户名的字符长度在 6~16 个字符之间',
            trigger: 'blur'
          }
        ],
        re_newPass: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          { validator: checkPass, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    resetFrom() {
      this.$refs.forgetFormRefs.resetFields()
      this.isFalse = false
    },
    next() {
      // eslint-disable-next-line eqeqeq
      if (this.activeIndex == '0') {
        // eslint-disable-next-line eqeqeq
        if (this.forgetForm.validCode == '' || this.forgetForm.validCode == undefined) {
          this.$message({
            showClose: true,
            message: '验证码不能为空',
            type: 'error'
          })
          return false
        }
        this.$http.get(`admins/validPwdCode/${this.updateForm.uid}/${this.forgetForm.validCode}`).then(res => {
          if (res.data.code !== '20000' || !res.data.data.isTrue) {
            this.$message({
              showClose: true,
              message: '请输入有效验证码',
              type: 'error'
            })
            this.isFalse = true
            return false
          } else {
            this.updateForm.validCode = this.forgetForm.validCode
            this.activeIndex = '1'
            return true
          }
        }).catch(err => {
          console.log(err)
        })
      // eslint-disable-next-line eqeqeq
      } else if (this.activeIndex == '1') {
        this.$refs.addFormRef.validateField('re_newPass', async (errMsg) => {
          if (errMsg) {
            this.$message({
              showClose: true,
              message: '两次密码不一致',
              type: 'warning'
            })
            return false
          } else {
            this.activeIndex = '2'
          }
        })
      }
      // if (String(this.activeIndex++) > 2) {
      //   this.activeIndex = '0'
      // }
    },
    beforeTabLeave(activeName, oldActiveName) {
      // if (oldActiveName === '0' && this.updateForm.validCode === '') {
      //   // this.$message.info('请先选择商品分类')
      //   this.$message({
      //     showClose: true,
      //     message: ''
      //   })
      return true
      // }
      // console.log(this.addForm.goods_cat)
    },
    shangyibu () {
      this.activeIndex = '1'
    },
    submit () {
      this.$http.put('admins/upPwd', this.updateForm).then(async res => {
        if (res.data.code !== '20000') {
          this.$message({
            showClose: true,
            message: res.data.message,
            type: 'error'
          })
          return false
        } else {
          this.activeIndex = '3'
          this.$message({
            showClose: true,
            message: res.data.message,
            type: 'success'
          })
          await this.openFullScreen()
        }
      // eslint-disable-next-line padded-blocks
      }).catch(err => {
        console.log(err)
      })
    },
    openFullScreen() {
      const loading = this.$loading({
        lock: true,
        text: '修改密码成功，正在跳转登录页面...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      setTimeout(() => {
        loading.close()
        this.$router.push('/login')
      }, 3000)
    },
    getAuthCode() {
    // 对输入的学号格式进行校验
      this.$refs.forgetFormRefs.validateField('email', async (errMsg) => {
        if (errMsg) {
          this.$message({
            showClose: true,
            message: '请输入合法邮箱',
            type: 'warning'
          })
        } else {
          var postData = {
            // uid: this.form1.uid
          }
          // 后端先判断该学号是否存在，再给北航邮箱发送验证码
          var i = 0
          await this.$http.put(`admins/sendEmail/${this.forgetForm.email}`).then(res => {
            if (res.data.code !== '20000') {
              this.$message({
                showClose: true,
                message: res.data.message,
                type: 'error'
              })
              i = 1
              return false
            } else {
              this.updateForm.uid = res.data.data.uuid
              this.updateForm.email = this.forgetForm.email
              this.$message({
                showClose: true,
                message: res.data.message,
                type: 'success'
              })
            }
          // eslint-disable-next-line padded-blocks
          }).catch(err => {
            console.log(err)
          })
          // 获取验证码按钮倒计时功能的实现
          if (i !== 0) {
            return false
          }
          const _this = this// ！！坑！setInterval中的this指向问题
          this.flag = true // ！按钮不可重复点击
          var time = 150// 定义时间变量 150s
          var timer = null// 定义定时器
          timer = setInterval(function() {
            // eslint-disable-next-line eqeqeq
            if (time == 0) {
              _this.msg = '重新获取验证码'
              _this.flag = false
              clearInterval(timer)// 清除定时器
            } else {
              _this.msg = time + '秒后重新获取'
              time--
            }
          }, 1000)
          // }).catch(error => {
          //   console.log(error)
          // })
        }
      })
    },
    sendAuthCode(data) {
      // console.log(data)
    }
  }
}
</script>

<style lang="less" scoped>
.btu{
  text-align: center;
}
.from_0{
  margin: 0 auto;
  width: 360px;
}
.zzz{
  width: 660px;
  height: 500px;
  margin: 10px auto;
}
.v-stepper__step--active{
  font: blue !important;
}
.v-application .primary{
  background-color: #1867c0 !important;
  border-color: #1867c0 !important;
}
.el-tabs__header{
  display: none !important;
}
.yyy{
  background-color: #ccc;
  height: 100%;
  overflow: hidden;
}
</style>
