const nodeTypeList = [
  {
    type: 'carStart',
    typeName: '起点',
    nodeName: '起点',
    logImg: require('@/assets/svg/起点.svg'),
    log_bg_color: 'rgba(0, 128, 0, 0.2)'
  },
  {
    type: 'carEnd',
    typeName: '终点',
    nodeName: '终点',
    logImg: require('@/assets/svg/终点.svg'),
    log_bg_color: 'rgba(255, 0, 0, 0.2)'
  },
  {
    type: 'dataSet',
    typeName: '大巴',
    nodeName: '大巴',
    logImg: require('@/assets/svg/大巴.svg'),
    log_bg_color: 'rgba(0, 128, 0, 0.2)'
  },
  {
    type: 'encode',
    typeName: '交通灯',
    nodeName: '交通灯',
    logImg: require('@/assets/svg/交通灯.svg'),
    log_bg_color: 'rgba(163, 117, 233, 0.2)'
  },
  {
    type: 'personService',
    typeName: '公交站',
    nodeName: '公交站',
    logImg: require('@/assets/svg/公交站.svg'),
    log_bg_color: 'rgba(132, 166, 251, 0.2)'
  },
  {
    type: 'arrange',
    typeName: '高速',
    nodeName: '高速',
    logImg: require('@/assets/svg/高速.svg'),
    log_bg_color: 'rgba(250, 205, 81, 0.2)'
  },
  {
    type: 'station',
    typeName: '公交站',
    nodeName: '公交站',
    logImg: require('@/assets/svg/公交站I.svg'),
    log_bg_color: 'rgba(250, 205, 81, 0.2)'
  },
  {
    type: 'carStation',
    typeName: '汽车站',
    nodeName: '汽车站',
    logImg: require('@/assets/svg/汽车站.svg'),
    log_bg_color: 'rgba(250, 205, 81, 0.2)'
  },
  {
    type: 'spend',
    typeName: '时长',
    nodeName: '时长',
    logImg: require('@/assets/svg/时长.svg'),
    log_bg_color: 'rgba(250, 205, 81, 0.2)'
  },
  {
    type: 'villsges',
    typeName: '乡镇',
    nodeName: '乡镇',
    logImg: require('@/assets/svg/乡镇.svg'),
    log_bg_color: 'rgba(250, 205, 81, 0.2)'
  },
  {
    type: 'destination',
    typeName: '目的地',
    nodeName: '目的地',
    logImg: require('@/assets/svg/目的地.svg'),
    log_bg_color: 'rgba(250, 205, 81, 0.2)'
  },
  {
    type: 'desc',
    typeName: '提示信息',
    nodeName: '提示信息',
    logImg: require('@/assets/svg/提示.svg'),
    log_bg_color: 'rgba(250, 205, 81, 0.2)'
  }
]

console.log(nodeTypeList)

export { nodeTypeList }
