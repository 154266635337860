<template>
  <div id="view">
    <div
      style="width: 100%;display: flex;align-items: center;justify-content: center"
    >
      <h2>
        班次层级关系
      </h2>
    </div>
    <div style="width: 100%;">
      <!--使用draggable组件-->
      <div class="itxst">
        <div v-for="(item, index) in list" :key="index" class="col">
          <div class="title">{{ item.placeIn }}</div>
          <draggable
            v-model="item.tree"
            group="site"
            animation="300"
            dragClass="dragClass"
            ghostClass="ghostClass"
            chosenClass="chosenClass"
            @start="start"
            @end="onEnd"
            @add="onAdd"
            @sort="onSort"
            @change="log"
          >
            <transition-group :style="style">
              <div class="item" v-for="item1 in item.tree" :key="item1.goodsId">
                <i class="fa fa-list-ul" style="margin-right:2px;"></i>
                {{ item1.placeIn }}
              </div>
            </transition-group>
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
  name: 'clevel',
  components: {
    draggable
  },
  props: {
    // eslint-disable-next-line vue/require-prop-type-constructor
    menu: ''
  },
  data() {
    return {
      drag: false,
      style: 'min-height:90px;display: block;',
      // 定义要被拖拽对象的数组
      list: [],
      parentid: 0,
      currentDragId: 0
    }
  },
  mounted() {
    this.goLevelRelation()
  },
  methods: {
    // 开始拖拽事件
    start(e) {
      this.drag = true
    },
    // 拖拽结束事件
    async onEnd(e) {
      console.log('end-------------')
      this.list.forEach(item => {
        if (item.treeNum + 1 === item.tree.length) {
          this.parentid = item.goodsId
        }
      })
      this.drag = false
      if (this.parentid !== 0) {
        await this.updateLevel()
      } else {
        this.$layer.msg('同一层级下不支持更新')
        this.parentid = 0
        this.currentDragId = 0
        this.goLevelRelation()
      }
    },
    onAdd(e) {},
    onSort(e) {},
    onUpdate() {},
    log: function(evt) {
      console.log('---------------------------------------------')
      if (evt.removed) {
        this.currentDragId = evt.removed.element.goodsId
      }
      console.log(evt)
    },
    async goLevelRelation() {
      const { data: res } = await this.$http.get('products/trees')
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      console.log(res)
      this.$message.success('获取数据成功!')
      res.data.list.forEach(item => {
        item.treeNum = item.tree.length
      })
      this.list = res.data.list.sort()
    },
    async updateLevel() {
      const { data: res } = await this.$http.put(
        `products/updateTree/${this.parentid}/${this.currentDragId}`
      )
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      this.$layer.msg(res.message)
    }
  }
}
</script>

<style lang="less" scoped>
#view {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  margin-top: 0.9375rem;
  overflow: scroll;
}

/*定义要拖拽元素的样式*/
.ghostClass {
  background-color: blue !important;
}
.chosenClass {
  background-color: #58d1ff !important;
  opacity: 1 !important;
}
.dragClass {
  background-color: #58ffa3 !important;
  opacity: 1 !important;
  box-shadow: none !important;
  outline: none !important;
  background-image: none !important;
}
.itxst {
  margin: 10px;
}
.title {
  padding: 6px 12px;
}
.col {
  min-width: 180px;
  flex: 1;
  padding: 10px;
  border: solid 1px #eee;
  background-color: #ccc;
  border-radius: 5px;
  float: left;
  margin-top: 10px;
}
.col:nth-child(n) {
  margin-left: 10px;
}
.col:last-child {
  margin-bottom: 36px;
}

.item {
  padding: 6px 12px;
  margin: 0px 10px 0px 10px;
  border: solid 1px #eee;
  background-color: #f1f1f1;
  border-radius: 3px;
  color: #696969;
}
.item:hover {
  background-color: #fdfdfd;
  cursor: move;
}
.item + .item {
  border-top: none;
  margin-top: 6px;
}
</style>
