<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>优惠券</el-breadcrumb-item>
      <el-breadcrumb-item>添加优惠券</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input
            placeholder="请输入上架者用户名搜索"
            v-model="queryinfo.query"
            clearable
            size="small"
            @clear="getGoodList()"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @mouseover.native="mouseOver()"
              @mouseout.native="mouseLeave()"
              @click="getGoodList()"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" size="small" plain @click="showModal">
            添加优惠券
          </el-button>
        </el-col>
      </el-row>
      <el-row>
        <br />
        <!-- table 表格区域
        <el-button type="primary" size="mini" icon="fa fa-check" plain
          >&nbsp;批量置为无效</el-button
        >-->
      </el-row>
      <!-- table 表格区域 -->
      <el-table
        :data="goodsList"
        border
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column type="index" label="#"></el-table-column>
        <!-- table 扩展区域 -->
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="创建时间">
                <span>{{ props.row.createTime }}</span>
              </el-form-item>
              <br />
              <el-form-item label="更新时间">
                <span>{{ props.row.updateTime }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column label="操作者" prop="name"> </el-table-column>
        <el-table-column label="劵值" prop="couponPrice">
          <template slot-scope="scope">
            <el-tag size="medium">{{ scope.row.couponPrice }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="兑换码" min-width="200px" prop="couCode">
          <template slot-scope="scope">
            <el-tag size="medium" type="warning">{{
              scope.row.couCode
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="劵类型" width="95px" prop="couType">
          <template slot-scope="scope">
            <el-tag size="medium" type="success">{{
              scope.row.couType == 1 ? '通用劵' : '特定劵'
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="劵值(元)" width="95px" prop="couponPrice">
          <template slot-scope="scope">
            <el-tag size="medium" type="success"
              >{{ scope.row.couponPrice }}&nbsp;元</el-tag
            >
          </template>
        </el-table-column>
        <!-- <el-table-column label="商品数量" width="70px" prop="goodsNumber">
          <template slot-scope="scope">
            <el-tag size="medium" type="warning">{{
              scope.row.goodsNumber
            }}</el-tag>
          </template>
        </el-table-column> -->
        <el-table-column label="剩余兑换次数" width="100px" prop="couSum">
          <template slot-scope="scope">
            <!-- <el-tag type="warning" size="medium">{{
              scope.row.ticketSum
            }}</el-tag> -->
            <el-badge
              style="margin-top:1em;"
              :value="scope.row.couSum"
              class="item"
              type="primary"
            >
            </el-badge>
          </template>
        </el-table-column>
        <el-table-column label="有效期到" width="160px" prop="expireTime">
          <template slot-scope="scope">
            <el-tag size="medium" type="info">{{
              scope.row.expireTime | dateFormat
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="80px" label="是否有效">
          <template slot-scope="scope">
            <!-- {{scope.row}} -->
            <el-switch
              v-model="scope.row.status"
              @change="usersChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <!-- <el-table-column label="出发时间" width="100px" prop="departureTime">
          <template slot-scope="scope">
            {{ scope.row.departureTime | jiequ }}
          </template>
        </el-table-column>
        <el-table-column label="创建时间" width="140px" prop="createTime">
        </el-table-column>
        <el-table-column label="更新时间" width="140px" prop="updateTime">
        </el-table-column>-->
        <el-table-column v-if="false" fixed="right" label="操作" width="180px">
          <el-button
            type="primary"
            size="mini"
            icon="fa fa-pencil"
            plain
            circle
            @click="showEditDialog(scope.row.goodsId)"
          ></el-button>
        </el-table-column>
      </el-table>

      <!-- 页面分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pagenum"
        :page-sizes="[5, 10, 20, 50]"
        :page-size="queryinfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      >
      </el-pagination>
    </el-card>

    <a-modal v-drag-modal width="60%" v-model="visible" title="编辑">
      <template slot="footer">
        <a-button key="back" @click="handleClose">
          取消
        </a-button>
      </template>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="140px"
        class="demo-ruleForm"
        size="mini"
      >
        <el-form-item label="优惠券类型" prop="region">
          <el-select v-model="ruleForm.region" placeholder="请选择优惠券类型">
            <el-option label="通用劵" value="1"></el-option>
            <el-option label="特定劵" value="2"></el-option>
          </el-select>
          <a-radio-group
            style="margin-left: 12px;margin-top:3px;"
            v-model="ruleForm.mark"
          >
            <a-radio :value="1">手动添加</a-radio>
            <a-radio :value="2">批量添加</a-radio>
          </a-radio-group>
        </el-form-item>
        <el-form-item label="兑换码" prop="name">
          <el-input
            placeholder="兑换码4-16个字符(如果为批量操作，填写生成个数,大于100按100个)"
            v-model="ruleForm.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="劵值" prop="price">
          <el-input type="number" v-model.number="ruleForm.price"></el-input>
        </el-form-item>
        <el-form-item label="允许兑换次数" prop="number">
          <el-input type="number" v-model.number="ruleForm.number"></el-input>
        </el-form-item>
        <el-form-item label="优惠券有效期" required>
          <el-col :span="11">
            <el-form-item prop="date1">
              <el-date-picker
                type="datetime"
                placeholder="选择日期"
                v-model="ruleForm.date1"
                style="width: 100%;"
                value-format="timestamp"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="是否有效" prop="delivery">
          <el-switch v-model="ruleForm.delivery"></el-switch>
        </el-form-item>
        <el-form-item
          v-if="ruleForm.region == '2'"
          label="选择特定班次"
          prop="ids"
        >
          <el-checkbox-group v-model="ruleForm.ids">
            <el-checkbox
              v-for="item in lists"
              :key="item.goodsId"
              :label="item.goodsId"
              :name="item.goodsId"
              >{{ item.placeIn }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item>
          <el-button type="success" plain @click="submitForm('ruleForm')"
            >提交保存</el-button
          >
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'Coupon',
  data() {
    return {
      // 查询参数对象
      queryinfo: {
        query: '',
        pagenum: 1,
        pagesize: 5
      },
      goodsList: [],
      total: 0,
      visible: false,
      ruleForm: {
        name: '',
        region: '',
        date1: '',
        number: 0,
        delivery: true,
        ids: [],
        price: 0,
        mark: 1
      },
      rules: {
        name: [
          { required: true, message: '请输入活兑换码', trigger: 'blur' },
          { min: 1, max: 16, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        region: [
          { required: true, message: '请选择优惠券类型', trigger: 'change' }
        ],
        date1: [
          {
            type: 'date',
            required: true,
            message: '请选择日期',
            trigger: 'change'
          }
        ]
      },
      lists: []
    }
  },
  methods: {
    mouseOver() {
      this.active = 'background-color: #ccc;'
      //   console.log('鼠标移动事件')
    },
    mouseLeave() {
      this.active = ''
    },
    // 监听开关状态的改变
    async usersChanged(userInfo) {
      const { data: res } = await this.$http.put(
        `coupons/${userInfo.couId}/state/${userInfo.status}`
      )
      if (res.code !== '20000') {
        userInfo.status = !userInfo.status
        return this.$message.error('更新状态失败!')
      }
      this.$message.success('更新状态成功！')
    },
    // 根据分页获取商品列表的全部数据
    async getGoodList() {
      const { data: res } = await this.$http.get('coupons', {
        params: this.queryinfo
      })
      if (res.code !== '20000') {
        this.$message.error(res.message)
        return
      }
      this.$message.success('获取优惠券列表成功！')
      console.log(res.data)
      this.goodsList = res.data.lists
      this.total = Number(res.data.total)
    },
    handleSizeChange(newSize) {
      this.queryinfo.pagesize = newSize
      this.getGoodList()
    },
    handleCurrentChange(newPage) {
      this.queryinfo.pagenum = newPage
      this.getGoodList()
    },
    handleSelectionChange(val) {
      // var listTemp = []
      // val.forEach(item => {
      //   listTemp.push(Number(item.goodsId))
      // })
      // this.goodsArr = listTemp
    },
    async getProductList() {
      const { data: res } = await this.$http.get('getProducts')
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      this.lists = res.data.list
    },
    addCoupon() {},
    showModal() {
      this.visible = true
      this.getProductList()
    },
    handleClose() {
      this.visible = false
    },
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          console.log(this.ruleForm)
          // eslint-disable-next-line eqeqeq
          if (this.ruleForm.region == '1') {
            var ids = []
            this.lists.forEach(x => {
              ids.push(x.goodsId)
            })
            this.ruleForm.ids = ids
          }

          const { data: res } = await this.$http.post('coupon/add', {
            name: window.sessionStorage.getItem('username'),
            couponPrice: this.ruleForm.price,
            status: this.ruleForm.delivery,
            couCode: this.ruleForm.name,
            couSum: this.ruleForm.number,
            expireTime: this.ruleForm.date1,
            couType: this.ruleForm.region,
            productArr: this.ruleForm.ids.join(','),
            mark: this.ruleForm.mark
          })
          if (res.code !== '20000') {
            return this.$message.error(res.message)
          }
          this.$antMessage.success(res.message)
          this.visible = false
        } else {
          alert('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  },
  mounted() {},
  created() {
    this.getGoodList()
  }
}
</script>

<style lang="less" scoped></style>
