<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>定时任务</el-breadcrumb-item>
      <el-breadcrumb-item>定时设置</el-breadcrumb-item>
    </el-breadcrumb>
    <!--选项卡卡片视图-->
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="数据库备份定时任务" name="first">
        <!-- 提示区域 -->
        <el-alert
          title="提示：系统默认周一至周三凌晨2两点备份，后面备份会覆盖前面，保留最新三份"
          type="info"
          center
          show-icon
          :closable="false"
          style="margin-bottom: 10px;"
        >
        </el-alert>
        <el-input
          type="textarea"
          placeholder="定时任务使用cron表达式（ 秒 分 时 日 月 周几）_示例数据：0 49 11 * * ?  表示每天的11点49分00秒执行 点击下面按钮查看更多示例"
          v-model="textarea"
          rows="1"
          :autosize="{ minRows: 1, maxRows: 1 }"
          maxlength="24"
          show-word-limit
        >
        </el-input>
        <el-button type="success" @click="save" style="margin-top: 10px;"
          >提交任务</el-button
        >
        <el-button @click="drawer = true" type="warning" round>📑</el-button>
      </el-tab-pane>
      <el-tab-pane label="邮件定时任务" name="second">邮件定时任务</el-tab-pane>
      <el-tab-pane label="短信定时任务" name="third">短信定时任务</el-tab-pane>
    </el-tabs>

    <el-drawer
      title="常见cron表达式"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      size="'30%'"
    >
      <span style="margin-left:20px;"
        ><br />
        （1）0/2 * * * * ? 表示每2秒 执行任务<br />
        （1）0 0/2 * * * ? 表示每2分钟 执行任务<br />
        （1）0 0 2 1 * ? 表示在每月的1日的凌晨2点调整任务<br />
        （2）0 15 10 ? * MON-FRI 表示周一到周五每天上午10:15执行作业<br />
        （3）0 15 10 ? 6L 2002-2006
        表示2002-2006年的每个月的最后一个星期五上午10:15执行作<br />
        （4）0 0 10,14,16 * * ? 每天上午10点，下午2点，4点<br />
        （5）0 0/30 9-17 * * ? 朝九晚五工作时间内每半小时<br />
        （6）0 0 12 ? * WED 表示每个星期三中午12点<br />
        （7）0 0 12 * * ? 每天中午12点触发<br />
        （8）0 15 10 ? * * 每天上午10:15触发<br />
        （9）0 15 10 * * ? 每天上午10:15触发<br />
        （10）0 15 10 * * ? 每天上午10:15触发<br />
        （11）0 15 10 * * ? 2005 2005年的每天上午10:15触发<br />
        （12）0 * 14 * * ? 在每天下午2点到下午2:59期间的每1分钟触发<br />
        （13）0 0/5 14 * * ? 在每天下午2点到下午2:55期间的每5分钟触发<br />
        （14）0 0/5 14,18 * * ?
        在每天下午2点到2:55期间和下午6点到6:55期间的每5分钟触发<br />
        （15）0 0-5 14 * * ? 在每天下午2点到下午2:05期间的每1分钟触发<br />
        （16）0 10,44 14 ? 3 WED 每年三月的星期三的下午2:10和2:44触发<br />
        （17）0 15 10 ? * MON-FRI 周一至周五的上午10:15触发<br />
        （18）0 15 10 15 * ? 每月15日上午10:15触发<br />
        （19）0 15 10 L * ? 每月最后一日的上午10:15触发<br />
        （20）0 15 10 ? * 6L 每月的最后一个星期五上午10:15触发<br />
        （21）0 15 10 ? * 6L 2002-2005
        2002年至2005年的每月的最后一个星期五上午10:15触发<br />
        （22）0 15 10 ? * 6#3 每月的第三个星期五上午10:15触发<br />
      </span>
    </el-drawer>
  </div>
</template>

<script>
const Base64 = require('js-base64').Base64
export default {
  data() {
    return {
      activeName: 'first',
      drawer: false,
      direction: 'btt',
      textarea: ''
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab.name)
    },
    async save() {
      const { data: res } = await this.$http.put(
        `/updateMysql/${Base64.encode(this.textarea)}`
      )
      if (res.code !== '20000') {
        this.$message.error({
          showClose: true,
          message: res.message
        })
      }
      this.$message.success({
        showClose: true,
        message: res.message
      })
      this.textarea = ''
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    }
  },
  created() {},
  components: {
    // eslint-disable-next-line vue/no-unused-components
  }
}
</script>

<style lang="less" scoped>
.el-drawer__body {
  overflow: scroll !important;
}
</style>
