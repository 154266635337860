import Vue from 'vue'
import {
  Button,
  message,
  Spin,
  Switch,
  Radio,
  Alert,
  Input,
  Tooltip,
  Card,
  Row,
  Col,
  Modal,
  Empty,
  Timeline,
  Icon,
  Progress,
  Select,
  Tag
} from 'ant-design-vue'
Vue.config.productionTip = false

/* v1.1.2 */
// Vue.component(Button.name, Button)
// Vue.component(Button.Group.name, Button.Group)

/* v1.1.3+ 自动注册Button下组件，如Button.Group */
Vue.use(Button)
Vue.use(Spin)
Vue.use(Switch)
Vue.use(Radio)
Vue.use(Alert)
Vue.use(Input)
Vue.use(Tooltip)
Vue.use(Card)
Vue.use(Row)
Vue.use(Col)
Vue.use(Modal)
Vue.use(Empty)
Vue.use(Timeline)
Vue.use(Icon)
Vue.use(Progress)
Vue.use(Select)
Vue.use(Tag)
// Vue.use(RadioGroup)

Vue.prototype.$antMessage = message
