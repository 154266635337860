<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图 -->
    <el-card>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input
            placeholder="请输入上架者用户名搜索"
            v-model="queryinfo.query"
            clearable
            size="small"
            @clear="getGoodList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getGoodList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" size="small" plain @click="goAddPage">
            添加商品
          </el-button>
        </el-col>
      </el-row>
      <el-row>
        <br />
        <el-button
          type="danger"
          size="mini"
          icon="fa fa-minus"
          plain
          @click="opt(2)"
          >&nbsp;批量下架</el-button
        >
        <el-button
          type="warning"
          size="mini"
          icon="fa fa-share-alt"
          plain
          @click="opt(3)"
          >&nbsp;批量允许分享</el-button
        >
      </el-row>
      <!-- table 表格区域 -->
      <el-table
        :data="goodsList"
        border
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column type="index" label="#"></el-table-column>
        <!-- table 扩展区域 -->
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="创建时间">
                <span>{{ props.row.createTime }}</span>
              </el-form-item>
              <br />
              <el-form-item label="更新时间">
                <span>{{ props.row.updateTime }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column label="商品名称" prop="goodsName"> </el-table-column>
        <el-table-column label="出发地" prop="placeIn">
          <template slot-scope="scope">
            <el-tag size="medium">{{ scope.row.placeIn }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="目的地" prop="placeOut">
          <template slot-scope="scope">
            <el-tag size="medium" type="warning">{{
              scope.row.placeOut
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="是否过路车" prop="isMiddleCar">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.isMiddleCar == 0"
              size="medium"
              type="info"
              >{{ '终点车' }}</el-tag
            >
            <el-tag v-else size="medium" type="warning">{{ '过路车' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="商品价格(元)" width="95px" prop="goodsPrice">
          <template slot-scope="scope">
            <el-tag size="medium" type="success"
              >{{ scope.row.goodsPrice }}&nbsp;元</el-tag
            >
          </template>
        </el-table-column>
        <!-- <el-table-column label="商品数量" width="70px" prop="goodsNumber">
          <template slot-scope="scope">
            <el-tag size="medium" type="warning">{{
              scope.row.goodsNumber
            }}</el-tag>
          </template>
        </el-table-column> -->
        <el-table-column label="商品重量" width="70px" prop="goodsWeight">
          <template slot-scope="scope">
            <el-tag size="medium" type="info"
              >{{ scope.row.goodsWeight }}&nbsp;kg</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="上架者用户名" width="110px" prop="goodsWeight">
          <template slot-scope="scope">
            <el-tag size="medium" type="primary">{{
              scope.row.optName ? scope.row.optName : '暂无记录'
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="80px" label="是否下架">
          <template slot-scope="scope">
            <!-- {{scope.row}} -->
            <el-switch
              v-model="scope.row.status"
              @change="usersChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column width="80px" label="优惠券">
          <template slot-scope="scope">
            <!-- {{scope.row}} -->
            <el-switch
              v-model="scope.row.discountStatus"
              @change="discountChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column width="80px" label="是否分享">
          <template slot-scope="scope">
            <!-- {{scope.row}}  share_status -->
            <el-switch
              v-model="scope.row.shareStatus"
              @change="shareChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column width="80px" label="是否允许抢票">
          <template slot-scope="scope">
            <!-- {{scope.row}} -->
            <el-switch
              v-model="scope.row.grabTicket"
              @change="grabChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <!-- <el-table-column label="出发时间" width="100px" prop="departureTime">
          <template slot-scope="scope">
            {{ scope.row.departureTime | jiequ }}
          </template>
        </el-table-column>
        <el-table-column label="创建时间" width="140px" prop="createTime">
        </el-table-column>
        <el-table-column label="更新时间" width="140px" prop="updateTime">
        </el-table-column>-->
        <el-table-column fixed="right" label="操作" width="210px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              icon="fa fa-pencil"
              plain
              circle
              @click="showEditDialog(scope.row.goodsId)"
            ></el-button>
            <el-button
              type="danger"
              size="mini"
              icon="el-icon-delete"
              plain
              circle
              @click="removeById(scope.row.goodsId)"
            ></el-button>
            <el-button
              v-if="scope.row.isMiddleCar == 1"
              type="success"
              size="mini"
              icon="fa fa-square-o"
              plain
              circle
              @click="zzz1(scope.row)"
            ></el-button>
            <el-button
              v-else
              type="success"
              size="mini"
              icon="fa fa-square-o"
              plain
              circle
              @click="zzz(scope.row)"
            ></el-button>
            <el-button
              type="success"
              size="mini"
              icon="fa fa-edit"
              plain
              circle
              @click="edit(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 页面分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pagenum"
        :page-sizes="[5, 10, 20, 50]"
        :page-size="queryinfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      >
      </el-pagination>
    </el-card>
    <!-- 修改商品信息的对话框 -->
    <el-dialog
      title="修改商品信息"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClosed"
      :modal="false"
    >
      <el-form
        :model="editFrom"
        :rules="editFromRules"
        ref="editFromRef"
        label-width="160px"
      >
        <el-form-item label="商品名称" prop="goodsName">
          <el-input v-model="editFrom.goodsName"></el-input>
        </el-form-item>
        <el-form-item label="出发地" prop="placeIn">
          <el-input v-model="editFrom.placeIn"></el-input>
        </el-form-item>
        <el-form-item label="目的地" prop="placeOut">
          <el-input v-model="editFrom.placeOut"></el-input>
        </el-form-item>

        <el-form-item label="价格" prop="goodsPrice">
          <el-input v-model="editFrom.goodsPrice"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sorted">
          <el-input type="number" v-model.number="editFrom.sorted"></el-input>
        </el-form-item>
        <el-form-item
          v-if="editFrom.shareStatus"
          label="返利金额修改"
          prop="rebateModeNumber"
        >
          <el-input
            type="number"
            v-model="editFrom.rebateModeNumber"
          ></el-input>
        </el-form-item>
        <el-form-item label="首图图片修改" prop="goodsImg">
          <el-input
            type="textarea"
            :rows="3"
            v-model="editFrom.goodsImg"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="上车地点经纬度坐标" prop="departurePlace">
          <el-input
            v-model="editFrom.departurePlace"
            style="width: 300px"
          ></el-input>
          <el-button
            type="success"
            style="margin-left:10px;"
            @click="openCoordinate"
            >坐标拾取</el-button
          >
        </el-form-item>-->
        <!-- <el-form-item label="出发时间">
          <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="editFrom.departureTime"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUserInfo">修 改</el-button>
      </span>
    </el-dialog>
    <!------------------ 出行日期列表对话框 ----------------------->
    <el-dialog
      title="出行日期列表管理（终点车）"
      :visible.sync="editDialogVisible1"
      width="88%"
      :modal="false"
    >
      <a-alert
        message="提示：终点车所有的日期标签皆可编辑，原本有的日期等属性如果修改则会同步影响绑定者，新添加的日期和对应的标签可以在下面选择是否同步给绑定者，默认不同步。"
        type="info"
        close-text="关闭"
      />
      <br />
      <a-radio-group
        style="margin-left: 60px;"
        v-model="value"
        @change="syncChange"
      >
        <a-radio :value="1">
          不同步
        </a-radio>
        <a-radio :value="2">
          同步
        </a-radio>
      </a-radio-group>
      <br />
      <br />
      <!-- 表格区域 -->
      <tree-table
        :data="tableData1"
        :columns="columns"
        :selection-type="false"
        :expand-type="false"
        show-index
        index-text="#"
        border
      >
        <!-- 是否有效 列区域 -->
        <template slot="timeFormat" slot-scope="scope">
          {{ scope.row.departmenTime | timeFormat }}
        </template>
        <!-- 是否有效 列区域 -->
        <template slot="isok" slot-scope="scope">
          <i
            class="el-icon-success"
            v-if="scope.row.status == true"
            style="color: lightgreen; font-size: 20px;"
          ></i>
          <i v-else-if="scope.row.status === null"></i>
          <i class="el-icon-error" v-else style="color: red"></i>
        </template>
        <template slot="isTicketStatus" slot-scope="scope">
          <i
            class="el-icon-success"
            v-if="scope.row.ticketStatus == true"
            style="color: lightgreen; font-size: 20px;"
          ></i>
          <i v-else-if="scope.row.ticketStatus === null || scope.row.ticketStatus === undefined"></i>
          <i class="el-icon-error" v-else style="color: red"></i>
        </template>

        <!-- 排序列 -->
        <template slot="tagname" slot-scope="scope">
          <el-tag size="mini" v-if="scope.row.name">{{
            scope.row.name
          }}</el-tag>
          <i v-else></i>
          <!-- <el-tag
                      type="success"
                      size="mini"
                      v-else-if="scope.row.cat_level === 1"
                      >二级</el-tag
                    >
                    <el-tag type="warning" size="mini" v-else>三级</el-tag> -->
        </template>
        <template slot="timeIn" slot-scope="scope">
          <el-tag size="mini" v-if="scope.row.timeIn">{{
            scope.row.timeIn
          }}</el-tag>
          <i v-else></i>
          <!-- <el-tag
                      type="success"
                      size="mini"
                      v-else-if="scope.row.cat_level === 1"
                      >二级</el-tag
                    >
                    <el-tag type="warning" size="mini" v-else>三级</el-tag> -->
        </template>
        <template slot="timeOut" slot-scope="scope">
          <el-tag size="mini" v-if="scope.row.timeOut">{{
            scope.row.timeOut
          }}</el-tag>
          <i v-else></i>
          <!-- <el-tag
                      type="success"
                      size="mini"
                      v-else-if="scope.row.cat_level === 1"
                      >二级</el-tag
                    >
                    <el-tag type="warning" size="mini" v-else>三级</el-tag> -->
        </template>
        <!-- 标签 票数数量 -->
        <template slot="tagnumber" slot-scope="scope">
          <!-- <el-tag size="mini"></el-tag> -->
          <!-- <el-tag
                      type="success"
                      size="mini"
                      v-else-if="scope.row.cat_level === 1"
                      >二级</el-tag
                    >-->
          <el-badge :value="scope.row.number" class="item" type="warning">
          </el-badge>
        </template>

        <!-- 操作列 -->
        <template slot="opt" slot-scope="scope">
          <div v-if="scope.row.placeIn == null">
            <el-button
              type="success"
              plain
              icon="el-icon-edit"
              size="mini"
              v-if="scope.row.name == null"
              @click="addTag(scope.row)"
              >添加</el-button
            >
            <el-button
              type="primary"
              plain
              icon="el-icon-edit"
              size="mini"
              @click="editTag(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              plain
              circle
              icon="el-icon-delete"
              size="mini"
              @click="deleteTag(scope.row)"
            ></el-button>
          </div>
          <el-button
            type="success"
            plain
            icon="el-icon-edit"
            size="mini"
            v-if="scope.row.placeIn != null"
            @click="addNewTime(scope.row)"
            >添加新日期</el-button
          >
        </template>
      </tree-table>
    </el-dialog>
    <!-- 出行日期列表对话框 2------------ -->
    <el-dialog
      title="出行日期列表管理（过路车）"
      :visible.sync="editDialogVisible3"
      width="90%"
      :modal="false"
    >
      <a-alert
        message="提示：过路车可以添加属于自己新的日期和删除原有绑定关系，但是不能改变被绑定班次原有的属性（比如是否下架，票数），如要改变原有属性请到终点车班次修改。"
        type="info"
        close-text="关闭"
      />
      <br />
      <!-- 表格区域 -->
      <tree-table
        :data="tableData1"
        :columns="columns"
        :selection-type="false"
        :expand-type="false"
        show-index
        index-text="#"
        border
      >
        <!-- 是否有效 列区域 -->
        <template slot="timeFormat" slot-scope="scope">
          {{ scope.row.departmenTime | timeFormat }}
        </template>
        <!-- 是否有效 列区域 -->
        <template slot="isok" slot-scope="scope">
          <i
            class="el-icon-success"
            v-if="scope.row.status === true"
            style="color: lightgreen; font-size: 20px;"
          ></i>
          <i v-else-if="scope.row.status === null"></i>
          <i class="el-icon-error" v-else style="color: red"></i>
        </template>
        <template slot="isTicketStatus" slot-scope="scope">
          <i
            class="el-icon-success"
            v-if="scope.row.ticketStatus == true"
            style="color: lightgreen; font-size: 20px;"
          ></i>
          <i v-else-if="scope.row.ticketStatus === null || scope.row.ticketStatus === undefined"></i>
          <i class="el-icon-error" v-else style="color: red"></i>
        </template>
        <!-- 排序列 -->
        <template slot="tagname" slot-scope="scope">
          <el-tag size="mini" v-if="scope.row.name">{{
            scope.row.name
          }}</el-tag>
          <i v-else></i>
          <!-- <el-tag
                      type="success"
                      size="mini"
                      v-else-if="scope.row.cat_level === 1"
                      >二级</el-tag
                    >
                    <el-tag type="warning" size="mini" v-else>三级</el-tag> -->
        </template>
        <template slot="timeIn" slot-scope="scope">
          <el-tag size="mini" v-if="scope.row.timeIn">{{
            scope.row.timeIn
          }}</el-tag>
          <i v-else></i>
          <!-- <el-tag
                      type="success"
                      size="mini"
                      v-else-if="scope.row.cat_level === 1"
                      >二级</el-tag
                    >
                    <el-tag type="warning" size="mini" v-else>三级</el-tag> -->
        </template>
        <template slot="timeOut" slot-scope="scope">
          <el-tag size="mini" v-if="scope.row.timeOut">{{
            scope.row.timeOut
          }}</el-tag>
          <i v-else></i>
          <!-- <el-tag
                      type="success"
                      size="mini"
                      v-else-if="scope.row.cat_level === 1"
                      >二级</el-tag
                    >
                    <el-tag type="warning" size="mini" v-else>三级</el-tag> -->
        </template>
        <!-- 标签 票数数量 -->
        <template slot="tagnumber" slot-scope="scope">
          <!-- <el-tag size="mini"></el-tag> -->
          <!-- <el-tag
                      type="success"
                      size="mini"
                      v-else-if="scope.row.cat_level === 1"
                      >二级</el-tag
                    >-->
          <el-badge :value="scope.row.number" class="item" type="warning">
          </el-badge>
        </template>

        <!-- 操作列 -->
        <template slot="opt" slot-scope="scope">
          <div v-if="scope.row.placeIn == null">
            <el-button
              type="success"
              plain
              icon="el-icon-edit"
              size="mini"
              v-if="scope.row.name == null"
              @click="addTag(scope.row)"
              >添加新标签</el-button
            >
            <el-button
              type="primary"
              plain
              icon="el-icon-edit"
              size="mini"
              v-else-if="scope.row.bindStatus == true"
              @click="editTag(scope.row)"
              >编辑标签</el-button
            >
            <el-button
              type="danger"
              plain
              icon="el-icon-delete"
              size="mini"
              @click="deleteTag(scope.row)"
              >删除</el-button
            >
          </div>
          <el-button
            type="success"
            plain
            icon="el-icon-edit"
            size="mini"
            v-if="scope.row.placeIn != null"
            @click="addNewTime(scope.row)"
            >添加新日期</el-button
          >
        </template>
      </tree-table>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="editTravel">修 改</el-button> -->
      <!-- </span> -->
    </el-dialog>
    <!-- 修改日期列表内容抽屉 -->
    <el-drawer
      title="编辑"
      :before-close="handleClose"
      :visible.sync="dialog"
      :direction="direction"
      custom-class="demo-drawer"
      ref="drawer"
    >
      <div class="demo-drawer__content">
        <!-- {{ form.departureTime }} -->
        <el-form :model="form">
          <el-form-item
            :prop="'.departmenTime'"
            :rules="{
              required: true,
              message: '不能为空',
              trigger: 'blur'
            }"
            style="width: 100px;"
          >
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="form.departmenTime"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="是否下架">
            <el-switch v-model="form.status"></el-switch>
            <!-- <el-select v-model="form.region" placeholder="请选择活动区域">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select> -->
          </el-form-item>
        </el-form>
        <div class="demo-drawer__footer">
          <el-button type="info" size="mini" @click="cancelForm"
            >取 消</el-button
          >
          <el-button
            type="success"
            size="mini"
            @click="$refs.drawer.closeDrawer()"
            :loading="loading"
            >{{ loading ? '提交中 ...' : '修 改' }}</el-button
          >
        </div>
      </div>
    </el-drawer>
    <!-- 添加日期列表内容抽屉 -->
    <el-drawer
      title="添加"
      :before-close="handleClose1"
      :visible.sync="dialog1"
      :direction="direction"
      custom-class="demo-drawer"
      ref="drawer1"
    >
      <div class="demo-drawer__content">
        <!-- {{ form.departureTime }} -->
        <el-form :model="form">
          <el-form-item label="出发日期" :label-width="formLabelWidth">
            <!-- <el-input
              v-model="form.departmenTime"
              autocomplete="off"
            ></el-input> -->
            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="form1.departmenTime"
              type="datetime"
              placeholder="选择出行时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="票数数量" :label-width="formLabelWidth">
            <el-input v-model="form1.number" autocomplete="off"></el-input>
            <!-- <el-select v-model="form.region" placeholder="请选择活动区域">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select> -->
          </el-form-item>
        </el-form>
        <div class="demo-drawer__footer">
          <el-button type="info" size="mini" @click="cancelForm1"
            >取 消</el-button
          >
          <el-button
            type="success"
            size="mini"
            @click="$refs.drawer1.closeDrawer()"
            :loading="loading1"
            >{{ loading ? '保存中 ...' : '添 加' }}</el-button
          >
        </div>
      </div>
    </el-drawer>
    <!-- 更新查看层级关系对话框 -->
    <el-dialog
      title="班次层级关系"
      :visible.sync="setPermDialogVisible1"
      width="15%"
      @close="setPermDialogClosed1"
      :modal="false"
    >
      <div class="prem">
        <el-tree
          :data="treeData"
          :props="customProps"
          :default-expand-all="true"
          ref="tree"
          node-key="goodsId"
          @node-drag-start="handleDragStart"
          @node-drag-enter="handleDragEnter"
          @node-drag-leave="handleDragLeave"
          @node-drag-over="handleDragOver"
          @node-drag-end="handleDragEnd"
          @node-drop="handleDrop"
          draggable
          :allow-drop="allowDrop"
          :allow-drag="allowDrag"
        >
        </el-tree>
      </div>
    </el-dialog>
    <el-dialog
      :modal="false"
      title="详情编辑"
      :visible.sync="dialogVisibleEdit"
      width="70%"
      :before-close="handleCloseEdit"
    >
      <Edit
        style="margin:8px;"
        @funcclose="handleCloseEdit1"
        :iframeData="iframeData1"
      ></Edit>
    </el-dialog>
    <!--模态框 添加新标签-->
    <a-modal
      width="80%"
      v-drag-modal
      :mask="false"
      v-model="visible"
      cancelText="取消"
      okText="添加"
      title="添加新标签"
      @ok="addTagOK"
    >
      <!-- 动态表单项2  开始 ----------------------- -->
      <div style="margin-left:80px;">
        <a-alert
          message="提示：这里添加日期和对日期添加标签没有数量限制，理论上可以无限添加,但是小程序端太多标签显示不太方面，请合理添加"
          type="warning"
          show-icon
          closable
        />
        <div class="button-search1">
          <el-row>
            <span style="font-weight:bold;">添加该日期对应标签项</span>
          </el-row>
          <i
            style="font-size:24px; cursor: pointer;color:#58D1FF;"
            class="el-icon-circle-plus-outline"
            @click.prevent="addHeader2()"
          ></i>
          <!-- <el-button
                slot="append"
                icon="el-icon-circle-plus-outline"
                size="large"
                @click="addHeader()"
              ></el-button> -->
        </div>
        <!-- 动态增加项目 -->
        <!-- 不止一个项目，用div包裹起来 -->
        <el-form
          :model="headers"
          :rules="headersRules"
          ref="headersFormRef"
          label-width="100px"
          label-position="top"
        >
          <!--------- ------------------------------------------------------------------------------------------- -->
          <div
            v-for="(itemInner, indexInner) in this.headers.children"
            :key="indexInner"
          >
            <div class="div-inline1">
              <el-form-item
                :prop="'children.' + indexInner + '.name'"
                :rules="{
                  required: true,
                  message: '不能为空',
                  trigger: 'blur'
                }"
                style="width: 100px;"
              >
                <el-input
                  size="mini"
                  v-model="itemInner.name"
                  placeholder="标签名称"
                ></el-input>
              </el-form-item>
            </div>

            <span class="distance_style_101">--</span>

            <div class="div-inline1 distance_style_101">
              <el-form-item
                width="180px"
                :prop="'children.' + indexInner + '.number'"
                :rules="{
                  required: true,
                  message: '不能为空',
                  trigger: 'blur'
                }"
                class="input_width_300"
              >
                <el-input
                  size="mini"
                  v-model="itemInner.number"
                  placeholder="票数"
                  type="number"
                ></el-input>
              </el-form-item>
            </div>
            <span class="distance_style_101">--</span>

            <div class="div-inline1 distance_style_101">
              <el-form-item
                :prop="'children.' + indexInner + '.timeIn'"
                :rules="{
                  required: true,
                  message: '不能为空',
                  trigger: 'blur'
                }"
                class="input_width_300"
              >
                <el-time-select
                  size="mini"
                  v-model="itemInner.timeIn"
                  :picker-options="{
                    start: '08:30',
                    step: '00:15',
                    end: '18:30'
                  }"
                  placeholder="预计出发时间"
                  style="width:180px !important;"
                >
                </el-time-select>
                <!-- <el-input
                    v-model="item.headerVal"
                    placeholder="number"
                  ></el-input> -->
              </el-form-item>
            </div>
            <span class="distance_style_101">--</span>

            <div class="div-inline1 distance_style_101">
              <el-form-item
                :prop="'children.' + indexInner + '.timeOut'"
                :rules="{
                  required: true,
                  message: '不能为空',
                  trigger: 'blur'
                }"
                class="input_width_300"
              >
                <!-- <el-input
                    v-model="item.headerVal"
                    placeholder="number"
                  ></el-input> -->
                <el-time-select
                  style="width:180px !important;"
                  size="mini"
                  v-model="itemInner.timeOut"
                  :picker-options="{
                    start: '08:30',
                    step: '00:15',
                    end: '18:30'
                  }"
                  placeholder="预计到达时间"
                >
                </el-time-select>
              </el-form-item>
            </div>
            <span class="distance_style_101">--</span>

            <div class="div-inline1 distance_style_101">
              <el-form-item
                :prop="'children.' + indexInner + '.longLat'"
                :rules="{
                  required: true,
                  message: '不能为空',
                  trigger: 'blur'
                }"
                class="input_width_300"
              >
                <el-input
                  size="mini"
                  v-model="itemInner.longLat"
                  placeholder="上车地点坐标"
                ></el-input>
              </el-form-item>
            </div>

            <div class="div-inline distance_style_1011">
              <el-form-item>
                <i
                  style="font-size:16px; cursor: pointer;color:black;padding-top:8px;margin-left:-18px;"
                  class="el-icon-position"
                  @click.prevent="openCoordinate1(item, indexInner)"
                ></i>
              </el-form-item>
            </div>

            <div class="div-inline distance_style_1011">
              <el-form-item>
                <!-- <el-link type="primary" :underline="false">删除</el-link> -->
                <i
                  style="font-size:20px; cursor: pointer;color:#D3D3D3;padding-top:8px;"
                  class="el-icon-remove-outline"
                  @click.prevent="removeHeader2(item, index, indexInner)"
                ></i>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
      <!-- 动态表单项2  结束 ----------------------- -->
    </a-modal>
    <a-modal
      width="50%"
      v-drag-modal
      :mask="false"
      v-model="visible1"
      cancelText="取消"
      okText="修改"
      :title="'编辑标签'"
      @ok="addTagOK1"
      @cancel="addCancel"
    >
      <el-form
        :model="editTags"
        :rules="editTagsRules"
        ref="headersFormRef"
        label-width="100px"
        label-position="top"
      >
        <el-form-item
          :prop="'.name'"
          :rules="{
            required: true,
            message: '不能为空',
            trigger: 'blur'
          }"
          style="width: 100px;"
        >
          <el-input
            size="small"
            v-model="editTags.name"
            placeholder="标签名称"
          ></el-input>
        </el-form-item>

        <el-form-item
          width="180px"
          :prop="'.number'"
          :rules="{
            required: true,
            message: '不能为空',
            trigger: 'blur'
          }"
          class="input_width_300"
        >
          <el-input
            size="small"
            v-model="editTags.number"
            placeholder="票数"
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item
          :prop="'.timeIn'"
          :rules="{
            required: true,
            message: '不能为空',
            trigger: 'blur'
          }"
          class="input_width_300"
        >
          <el-time-select
            size="small"
            v-model="editTags.timeIn"
            :picker-options="{
              start: '08:30',
              step: '00:15',
              end: '18:30'
            }"
            placeholder="预计出发时间"
            style="width:180px !important;"
          >
          </el-time-select>
          <!-- <el-input
                    v-model="item.headerVal"
                    placeholder="number"
                  ></el-input> -->
        </el-form-item>
        <el-form-item
          :prop="'.timeOut'"
          :rules="{
            required: true,
            message: '不能为空',
            trigger: 'blur'
          }"
          class="input_width_300"
        >
          <el-time-select
            style="width:180px !important;"
            size="small"
            v-model="editTags.timeOut"
            :picker-options="{
              start: '08:30',
              step: '00:15',
              end: '18:30'
            }"
            placeholder="预计到达时间"
          >
          </el-time-select>
        </el-form-item>
        <el-form-item
          label="是否下架"
          :prop="'.status'"
          :rules="{
            required: true,
            message: '不能为空',
            trigger: 'blur'
          }"
          class="input_width_300"
        >
          <el-switch v-model="editTags.status"> </el-switch>
        </el-form-item>
        <el-form-item
          label="是否允许抢票"
          :prop="'.ticketStatus'"
          :rules="{
            required: true,
            message: '不能为空',
            trigger: 'blur'
          }"
          class="input_width_300"
        >
          <el-switch v-model="editTags.ticketStatus"> </el-switch>
        </el-form-item>
        <div class="loc">
          <el-form-item
            :prop="'.longLat'"
            :rules="{
              required: true,
              message: '不能为空',
              trigger: 'blur'
            }"
            class="input_width_300"
          >
            <el-input
              size="small"
              v-model="editTags.longLat"
              placeholder="上车地点坐标"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <i
              style="font-size:16px; cursor: pointer;color:black;padding-top:14px;margin-left:-24px;"
              class="el-icon-position"
              @click.prevent="openCoordinate2()"
            ></i>
          </el-form-item>
        </div>
      </el-form>
    </a-modal>
    <a-modal
      width="50%"
      v-drag-modal
      :mask="false"
      v-model="visible3"
      cancelText="取消"
      okText="添加"
      :title="'添加新日期'"
      @ok="addTagOK3"
      @cancel="addCancel3"
    >
      <el-form
        :model="editTags1"
        :rules="editTagsRules1"
        ref="headersFormRef"
        label-width="100px"
        label-position="top"
      >
        <el-form-item
          :prop="'.departmenTime'"
          :rules="{
            required: true,
            message: '不能为空',
            trigger: 'blur'
          }"
          style="width: 100px;"
        >
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="editTags1.departmenTime"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          :prop="'.name'"
          :rules="{
            required: true,
            message: '不能为空',
            trigger: 'blur'
          }"
          style="width: 100px;"
        >
          <el-input
            size="small"
            v-model="editTags1.name"
            placeholder="标签名称"
          ></el-input>
        </el-form-item>

        <el-form-item
          width="180px"
          :prop="'.number'"
          :rules="{
            required: true,
            message: '不能为空',
            trigger: 'blur'
          }"
          class="input_width_300"
        >
          <el-input
            size="small"
            v-model="editTags1.number"
            placeholder="票数"
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item
          :prop="'.timeIn'"
          :rules="{
            required: true,
            message: '不能为空',
            trigger: 'blur'
          }"
          class="input_width_300"
        >
          <el-time-select
            size="small"
            v-model="editTags1.timeIn"
            :picker-options="{
              start: '08:30',
              step: '00:15',
              end: '18:30'
            }"
            placeholder="预计出发时间"
            style="width:180px !important;"
          >
          </el-time-select>
          <!-- <el-input
                    v-model="item.headerVal"
                    placeholder="number"
                  ></el-input> -->
        </el-form-item>
        <el-form-item
          :prop="'.timeOut'"
          :rules="{
            required: true,
            message: '不能为空',
            trigger: 'blur'
          }"
          class="input_width_300"
        >
          <el-time-select
            style="width:180px !important;"
            size="small"
            v-model="editTags1.timeOut"
            :picker-options="{
              start: '08:30',
              step: '00:15',
              end: '18:30'
            }"
            placeholder="预计到达时间"
          >
          </el-time-select>
        </el-form-item>
        <div class="loc">
          <el-form-item
            :prop="'.longLat'"
            :rules="{
              required: true,
              message: '不能为空',
              trigger: 'blur'
            }"
            class="input_width_300"
          >
            <el-input
              size="small"
              v-model="editTags1.longLat"
              placeholder="上车地点坐标"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <i
              style="font-size:16px; cursor: pointer;color:black;padding-top:14px;margin-left:-24px;"
              class="el-icon-position"
              @click.prevent="openCoordinate3()"
            ></i>
          </el-form-item>
        </div>
      </el-form>
    </a-modal>
  </div>
</template>

<script>
import Loc from '@/components/goods/Loc'
import Edit from '@/components/goods/edit'
import Qs from 'qs'
export default {
  name: 'Goods',
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('排序数值不能为空'))
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error('请输入数字值'))
        } else if (value < 1) {
          callback(new Error('排序设置数字范围在1-999之间'))
        } else {
          if (value >= 1000) {
            callback(new Error('排序设置数字范围在1-999之间'))
          } else {
            callback()
          }
        }
      }, 800)
    }
    return {
      visible: false,
      dialogVisibleEdit: false,
      iframeData1: {},
      setPermDialogVisible1: false,
      currentGoodsId: 0,
      form: { status: false },
      form1: {},
      formLabelWidth: '80px',
      timer: null,
      dialog: false,
      loading: false,
      dialog1: false,
      loading1: false,
      drawer: false,
      direction: 'rtl',
      tableData: [],
      tableData1: [],
      // 查询参数对象
      queryinfo: {
        query: '',
        pagenum: 1,
        pagesize: 5
      },
      // 保存商品列表
      goodsList: [],
      editContent: {},
      // 保存商品总条数
      total: 0,
      editDialogVisible: false,
      editDialogVisible1: false,
      editDialogVisible3: false,
      editFrom: {},
      editFromRules: {
        goodsName: [
          { required: true, message: '请输入商品名称', trigger: 'blur' },
          {
            min: 2,
            max: 20,
            message: '商品名称的字符长度在 2~20 个字符之间',
            trigger: 'blur'
          }
        ],
        placeIn: [
          { required: true, message: '请输入出发地', trigger: 'blur' },
          {
            min: 1,
            max: 24,
            message: '出发地的字符长度在 1~24 个字符之间',
            trigger: 'blur'
          }
        ],
        placeOut: [
          { required: true, message: '请输入目的地', trigger: 'blur' },
          {
            min: 1,
            max: 24,
            message: '目的地的字符长度在 1~24 个字符之间',
            trigger: 'blur'
          }
        ],
        sorted: [{ validator: checkAge, trigger: 'blur' }]
      },
      // 为table指定列的定义
      columns: [
        {
          label: '出发地',
          prop: 'placeIn',
          width: '120px'
        },
        {
          label: '目的地',
          prop: 'placeOut',
          width: '120px'
        },
        {
          label: '出发日期',
          // 表示 将当前列定义为模板列
          type: 'template',
          width: '160px',
          template: 'timeFormat'
        },
        {
          label: '是否下架',
          // 表示 将当前列定义为模板列
          type: 'template',
          template: 'isok',
          width: '66px'
        },
        {
          label: '是否抢票',
          // 表示 将当前列定义为模板列
          type: 'template',
          template: 'isTicketStatus',
          width: '66px'
        },
        {
          label: '标签名称',
          // 表示 将当前列定义为模板列
          type: 'template',
          // 表示当前这一列使用的模板名称
          template: 'tagname',
          width: '240px'
        },
        {
          label: '预计出发时间',
          // 表示 将当前列定义为模板列
          type: 'template',
          // 表示当前这一列使用的模板名称
          template: 'timeIn',
          width: '80px'
        },
        {
          label: '预计达到时间',
          // 表示 将当前列定义为模板列
          type: 'template',
          // 表示当前这一列使用的模板名称
          template: 'timeOut',
          width: '80px'
        },
        {
          label: '票数',
          // 表示 将当前列定义为模板列
          type: 'template',
          // 表示当前这一列使用的模板名称
          template: 'tagnumber',
          width: '66px'
        },
        {
          label: '操作',
          // 表示 将当前列定义为模板列
          type: 'template',
          // 表示当前这一列使用的模板名称
          template: 'opt',
          width: '200px'
        }
      ],
      currentProductId: 0,
      currentId: 0,
      headers: { children: [] },
      visible1: false,
      editTags: {},
      visible3: false,
      editTags1: {
        syncChang: 1
      },
      currentTagId: 0,
      value: 1,
      editDepartTimeId: 0,
      goodsArr: [],
      customProps: {
        children: 'tree',
        label: 'placeIn'
      },
      treeData: [],
      checkedList: [],
      currentDragId: 0
    }
  },
  created() {
    this.getGoodList()
  },
  methods: {
    handleDragStart(node, ev) {
      console.log('drag start', node)
    },
    handleDragEnter(draggingNode, dropNode, ev) {
      console.log('tree drag enter: ', dropNode.label, dropNode)
      // eslint-disable-next-line eqeqeq
      if (this.currentDragId == 0) {
        this.currentDragId = dropNode.data.goodsId
      }
    },
    handleDragLeave(draggingNode, dropNode, ev) {
      console.log('tree drag leave: ', dropNode.label)
    },
    handleDragOver(draggingNode, dropNode, ev) {
      console.log('tree drag over: ', dropNode.label)
    },
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      console.log('tree drag end: ', dropNode && dropNode.label, dropType)
    },
    async handleDrop(draggingNode, dropNode, dropType, ev) {
      console.log('tree drop: ', dropNode.label, dropType)
      // products/updateTree/{parentid}/{id}
      var parentid = 0
      // eslint-disable-next-line eqeqeq
      if (dropNode.data.parentId == 0) {
        parentid = dropNode.data.goodsId
      } else {
        parentid = dropNode.data.parentId
      }
      // console.log(parentid, this.currentDragId)
      const { data: res } = await this.$http.put(
        `products/updateTree/${parentid}/${this.currentDragId}`
      )
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      this.$layer.msg(res.message)
      this.currentDragId = 0
      this.setPermDialogVisible1 = false
    },
    allowDrop(draggingNode, dropNode, type) {
      if (dropNode.data.placeIn === '二级 3-1') {
        return type !== 'inner'
      } else {
        return true
      }
    },
    allowDrag(draggingNode) {
      return draggingNode.data.placeIn.indexOf('三级 3-2-2') === -1
    },
    async opt(status) {
      if (this.goodsArr.length < 1) {
        this.$layer.msg('请先选中操作对象')
        return
      }
      const { data: res } = await this.$http.put(
        `products/opt/${status}`,
        Qs.stringify({ goodsArr: this.goodsArr + '' })
      )
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      this.$message.success(res.message)
      this.getGoodList()
    },
    handleSelectionChange(val) {
      var listTemp = []
      val.forEach(item => {
        listTemp.push(Number(item.goodsId))
      })

      this.goodsArr = listTemp
    },
    syncChange(e) {
      console.log('radio checked', e.target.value)
      this.editTags1.syncChange = e.target.value
    },
    addHeader2() {
      this.headers.children.push({
        name: '',
        number: 0,
        timeIn: '',
        timeOut: '',
        longLat: ''
      })
    },
    removeHeader2(item, indexInner) {
      this.headers.children.splice(indexInner, 1)
    },
    async addTagOK(e) {
      const { data: res } = await this.$http.post('relation/add', {
        pid: this.currentProductId,
        id: this.currentId,
        headers: this.headers.children,
        syncChange: this.value
      })
      console.log(res)
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      this.$message.success(res.message)
      this.visible = false
      this.headers = []
      this.zzz1()
      return true
    },
    async addTagOK1(e) {
      const { data: res } = await this.$http.put('tags/editTag', this.editTags)
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      // console.log(this.editTags)
      this.editTags = {}
      this.visible1 = false
      this.editDialogVisible3 = false
      return this.$message.success(res.message)
    },
    async addTag(row) {
      this.currentId = row.id
      this.visible = true
    },
    addCancel() {},
    addCancel3() {},
    async addTagOK3(e) {
      this.editTags1.productId = this.currentProductId
      const { data: res } = await this.$http.put('tags/editTag', this.editTags1)
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      // console.log(this.editTags)
      this.editTags1 = {}
      this.visible3 = false
      this.editDialogVisible3 = false
      return this.$message.success(res.message)
    },
    async addNewTime(row) {
      // this.editTags1 = row
      this.visible3 = true
    },
    editTag(row) {
      if (row.departmenTime == null) {
        // eslint-disable-next-line eqeqeq
        if (row.tagId == this.currentTagId) {
        } else {
          this.editTags = row
          this.currentTagId = row.tagId
          this.visible1 = true
        }
      } else {
        this.form.id = row.id
        this.form.departmenTime = row.departmenTime
        this.form.status = row.status
        this.dialog = true
      }

      // eslint-disable-next-line eqeqeq
      // if (this.editTags.name == null || this.editTags.name == undefined) {
      //   this.editTags = row
      //   this.currentTagId = row.tagId
      // }
    },
    async deleteTag(row) {
      console.log(row, this.currentProductId)
      var content = ''
      if (row.departmenTime == null) {
        content = '你将要删除该条tag的绑定，是否确认删除？'
      } else if (row.departmenTime != null) {
        content = '你将要删除该条日期及其所有标签的绑定，是否确认删除？'
      }
      // 弹窗展示是否删除数据
      const confirmResult = await this.$confirm(content, '提示', {
        confirmButtonText: '嗯',
        cancelButtonText: '算了',
        type: 'warning'
      }).catch(err => err)

      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除操作~')
      }
      if (row.departmenTime == null) {
        const { data: res } = await this.$http.post(
          'relation/delete',
          Qs.stringify({
            pid: this.currentProductId,
            did: row.tagId,
            type: 'tag'
          })
        )
        console.log(res)
        if (res.code !== '20000') {
          return this.$message.error(res.message)
        }
        this.editDialogVisible3 = false
        return this.$message.success(res.message)
      } else if (row.departmenTime != null) {
        const { data: res } = await this.$http.post(
          'relation/delete',
          Qs.stringify({
            pid: this.currentProductId,
            did: row.id,
            type: 'time'
          })
        )
        console.log(res)
        if (res.code !== '20000') {
          return this.$message.error(res.message)
        }
        this.editDialogVisible3 = false
        return this.$message.success(res.message)
      }
    },
    handleCloseEdit() {
      this.$confirm('确认关闭编辑吗？')
        .then(_ => {
          this.dialogVisibleEdit = false
        })
        .catch(_ => {})
    },
    handleCloseEdit1() {
      this.$confirm('确认关闭编辑吗？')
        .then(_ => {
          this.dialogVisibleEdit = false
        })
        .catch(_ => {})
    },
    // 监听层级关系窗口的关闭事件
    setPermDialogClosed1() {
      // this.checkedList = []
      // this.currentId = 0
    },
    async verifyDepartListChanged(row) {
      const { data: res } = await this.$http.put(
        `updateDepartTimeStatus/${row.id}/${row.status}`
      )
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      this.$message.success(res.message)
    },
    async handleDelete(inex, row) {
      const { data: res } = await this.$http.delete(
        `deleteDepartTime/${row.id}`
      )
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      this.dialog = false
      this.$message.success(res.message)
    },
    handleClose(done) {
      if (this.loading) {
        return
      }
      this.$confirm('确定要提交修改吗？')
        .then(_ => {
          this.loading = true
          this.timer = setTimeout(async () => {
            done()
            const { data: res } = await this.$http.put(
              `updateDepartTimeByPid/${this.form.id}/${this.form.departmenTime}/${this.form.status}`
            )
            if (res.code !== '20000') {
              return this.$message.error(res.message)
            }
            this.$message.success(res.message)
            // 动画关闭需要一定的时间
            setTimeout(() => {
              this.loading = false
            }, 400)
          }, 2000)
        })
        .catch(_ => {})
    },
    cancelForm() {
      this.loading = false
      this.dialog = false
      clearTimeout(this.timer)
    },
    yyy() {
      this.dialog1 = true
    },
    handleClose1(done) {
      if (this.loading) {
        return
      }
      this.$confirm('确定要提交表单吗？')
        .then(_ => {
          this.loading1 = true
          this.timer = setTimeout(async () => {
            done()
            const { data: res } = await this.$http.post(
              `saveDepartTimeByPid/${this.currentGoodsId}/${this.form1.departmenTime}/${this.form1.number}`
            )
            if (res.code !== '20000') {
              return this.$message.error(res.message)
            }
            this.$message.success(res.message)
            // 动画关闭需要一定的时间
            setTimeout(() => {
              this.loading1 = false
            }, 400)
          }, 2000)
        })
        .catch(_ => {})
      this.editDialogVisible1 = false
    },
    cancelForm1() {
      this.loading1 = false
      this.dialog1 = false
      clearTimeout(this.timer)
    },
    handleEdit(index, row) {
      this.form = row
      console.log(this.form)
      this.dialog = true
    },
    edit(row) {
      this.iframeData1 = row
      this.dialogVisibleEdit = true
      // this.$layer.iframe({
      //   content: {
      //     content: Edit,
      //     parent: this,
      //     data: { iframeData: row }
      //   },
      //   area: ['1000px', '600px'],
      //   title: '修改详情内容',
      //   maxmin: true,
      //   shade: false,
      //   shadeClose: false,
      //   cancel: () => {
      //     // console.log(this.$store.loc)
      //     // this.editFrom.departurePlace =
      //     //   this.$store.loc.lng + ',' + this.$store.loc.lat
      //     // 关闭弹窗事件
      //     // alert('关闭iframe')
      //     this.getGoodList()
      //   }
      // })
    },
    openCoordinate() {
      this.$layer.iframe({
        content: {
          content: Loc,
          parent: this,
          data: { iframeData: this.iframeData }
        },
        area: ['1100px', '700px'],
        title: '坐标拾取',
        maxmin: true,
        shade: false,
        shadeClose: false,
        cancel: () => {
          // console.log(this.$store.loc)
          this.editFrom.departurePlace =
            this.$store.loc.lng + ',' + this.$store.loc.lat
          // 关闭弹窗事件
          // alert('关闭iframe')
        }
      })
    },
    // 根据分页获取商品列表的全部数据
    async getGoodList() {
      const { data: res } = await this.$http.get('products', {
        params: this.queryinfo
      })
      if (res.code !== '20000') {
        this.$message.error(res.message)
        return
      }
      this.$message.success('获取商品列表成功！')
      console.log(res.data)
      this.goodsList = res.data.lists
      this.total = Number(res.data.total)
    },
    handleSizeChange(newSize) {
      this.queryinfo.pagesize = newSize
      this.getGoodList()
    },
    handleCurrentChange(newPage) {
      this.queryinfo.pagenum = newPage
      this.getGoodList()
    },
    // 编辑用户的对话框
    async showEditDialog(id) {
      console.log(id)
      const { data: res } = await this.$http.get(`getProductInfo/${id}`)
      console.log(res)
      if (res.code !== '20000') {
        return this.$message.error('查询商品失败！')
      }
      this.$message.success('查询商品成功！')
      this.editFrom = res.data.data
      // this.editFrom.city = Array(res.data.city)
      this.editDialogVisible = true
    },
    async removeById(id) {
      const confirmResult = await this.$confirm(
        '此操作将永久删除该商品, 是否继续?',
        '提示',
        {
          confirmButtonText: '朕，允了',
          cancelButtonText: '额，算了',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除！')
      }

      const { data: res } = await this.$http.delete(`products/${id}`)
      if (res.code !== '20000') {
        this.$message.error('删除商品失败！')
      }
      this.$message.success('删除商品成功！')
      this.getGoodList()
    },
    async zzz(row) {
      // console.log(row)
      this.editDialogVisible1 = true
      const { data: res } = await this.$http.get(
        `getDepartTimeList/${row.goodsId}`
      )
      // 请求后端查询日期
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      // console.log(res.data)
      this.tableData1 = []
      this.tableData1.push(res.data.list)
      this.currentProductId = res.data.list.goodsId
      this.$layer.msg('获取数据成功')
      // this.currentGoodsId = row.goodsId
      // const { data: res } = await this.$http.get(
      //   `getDepartTimeList/${row.goodsId}`
      // )
      // // 请求后端查询日期
      // if (res.code !== '20000') {
      //   return this.$message.error(res.message)
      // }
      // console.log(res.data)
      // this.tableData = res.data.list
      // this.$message.success('获取列表成功')
    },
    async tj(row) {
      // console.log(row)
      // this.editDialogVisible1 = true
      const { data: res } = await this.$http.get(
        `getTj/${row.goodsId}`
      )
      // 请求后端查询日期
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      console.log(res.data)
      // this.tableData1 = []
      // this.tableData1.push(res.data.list)
      // this.currentProductId = res.data.list.goodsId
      // this.$layer.msg('获取数据成功')
      // this.currentGoodsId = row.goodsId
      // const { data: res } = await this.$http.get(
      //   `getDepartTimeList/${row.goodsId}`
      // )
      // // 请求后端查询日期
      // if (res.code !== '20000') {
      //   return this.$message.error(res.message)
      // }
      // console.log(res.data)
      // this.tableData = res.data.list
      // this.$message.success('获取列表成功')
    },
    async zzz1(row) {
      this.value = 1
      this.editDialogVisible3 = true
      const { data: res } = await this.$http.get(
        `getDepartTimeList/${row.goodsId}`
      )
      // 请求后端查询日期
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      console.log(res.data)
      this.tableData1 = []
      this.tableData1.push(res.data.list)
      this.currentProductId = res.data.list.goodsId
      this.$layer.msg('获取数据成功')
    },
    // 监听开关状态的改变
    async usersChanged(userInfo) {
      const { data: res } = await this.$http.put(
        `products/${userInfo.goodsId}/state/${userInfo.status}`
      )
      if (res.code !== '20000') {
        userInfo.status = !userInfo.status
        return this.$message.error('更新状态失败!')
      }
      this.$message.success('更新状态成功！')
    },
    // 监听开关状态的改变
    async discountChanged(userInfo) {
      const { data: res } = await this.$http.put(
        `products/${userInfo.goodsId}/state/${userInfo.discountStatus}/discount`
      )
      if (res.code !== '20000') {
        userInfo.discountStatus = !userInfo.discountStatus
        return this.$message.error('更新状态失败!')
      }
      this.$message.success('更新状态成功！')
    },
    // 监听开关状态的改变
    async shareChanged(userInfo) {
      const { data: res } = await this.$http.put(
        `products/${userInfo.goodsId}/state/${userInfo.shareStatus}/share`
      )
      if (res.code !== '20000') {
        userInfo.shareStatus = !userInfo.shareStatus
        return this.$message.error('更新状态失败!')
      }
      this.$message.success('更新状态成功！')
    },
    async grabChanged(userInfo) {
      const { data: res } = await this.$http.put(
        `products/${userInfo.goodsId}/state/${userInfo.grabTicket}/grab`
      )
      if (res.code !== '20000') {
        userInfo.grabTicket = !userInfo.grabTicket
        return this.$message.error('更新状态失败!')
      }
      this.$message.success('更新状态成功！')
    },
    mouseOver() {
      this.active = 'background-color: #ccc;'
      //   console.log('鼠标移动事件')
    },
    mouseLeave() {
      this.active = ''
    },
    goAddPage() {
      this.$router.push('/goods/add')
    },
    editDialogClosed() {},
    editUserInfo() {
      this.$refs.editFromRef.validate(async valid => {
        if (!valid) {
          // eslint-disable-next-line no-useless-return
          return
        }
        // 此操作为敏感操作 弹窗展示是否修改数据
        const confirmResult = await this.$confirm('亲,确定要修改吗？', '提示', {
          confirmButtonText: '嗯',
          cancelButtonText: '不了',
          type: 'warning'
        }).catch(err => err)

        if (confirmResult !== 'confirm') {
          return this.$message.info('已取消操作！')
        }
        console.log(this.editFrom)
        // 发起请求，修改用户信息
        const { data: res } = await this.$http.put(
          'products/' + this.editFrom.goodsId,
          this.editFrom
        )
        console.log(res)
        if (res.code !== '20000') {
          return this.$message.error('更新信息失败！')
        }
        // 刷新数据列表
        this.getGoodList()
        // 提示修改成功
        this.$message.success('更新信息成功！')
        // 关闭对话框
        this.editDialogVisible = false
      })
    },
    openCoordinate1(item, indexInner) {
      this.$layer.iframe({
        content: {
          content: Loc,
          parent: this,
          data: { iframeData: this.iframeData }
        },
        area: ['1100px', '700px'],
        title: '坐标拾取',
        maxmin: true,
        shade: false,
        shadeClose: false,
        cancel: () => {
          // console.log(this.$store.loc)
          try {
            this.headers.children[indexInner].longLat =
              this.$store.loc.lng +
              ',' +
              this.$store.loc.lat +
              ',' +
              this.$store.loc.address
          } catch (err) {}

          // 关闭弹窗事件
          // alert('关闭iframe')
        }
      })
    },
    openCoordinate2(item, indexInner) {
      this.$layer.iframe({
        content: {
          content: Loc,
          parent: this,
          data: { iframeData: this.iframeData }
        },
        area: ['1100px', '700px'],
        title: '坐标拾取',
        maxmin: true,
        shade: false,
        shadeClose: false,
        cancel: () => {
          // console.log(this.$store.loc)
          try {
            this.editTags.longLat =
              this.$store.loc.lng +
              ',' +
              this.$store.loc.lat +
              ',' +
              this.$store.loc.address
          } catch (err) {}

          // 关闭弹窗事件
          // alert('关闭iframe')
        }
      })
    },
    openCoordinate3(item, indexInner) {
      this.$layer.iframe({
        content: {
          content: Loc,
          parent: this,
          data: { iframeData: this.iframeData }
        },
        area: ['1100px', '700px'],
        title: '坐标拾取',
        maxmin: true,
        shade: false,
        shadeClose: false,
        cancel: () => {
          // console.log(this.$store.loc)
          try {
            this.editTags1.longLat =
              this.$store.loc.lng +
              ',' +
              this.$store.loc.lat +
              ',' +
              this.$store.loc.address
          } catch (err) {}

          // 关闭弹窗事件
          // alert('关闭iframe')
        }
      })
    }
  },
  filters: {
    timeFormat: function(data) {
      // console.log('------', data)
      // eslint-disable-next-line eqeqeq
      if (data == undefined || data == '' || data == null) {
        return ''
      }
      return data.split(' ')[0]
    }
  },
  components: {
    Edit
  }
}
</script>

<style lang="less" scoped>
.el-dialog__wrapper {
  z-index: 499 !important;
}
.demo-table-expand {
  font-size: 0 !important;
}
.demo-table-expand {
  label {
    width: 90px !important;
    color: #99a9bf !important;
  }
}
.demo-table-expand .el-form-item {
  margin-right: 0 !important;
  margin-bottom: 0 !important;
  width: 50% !important;
}
.demo-drawer__content,
.demo-drawer__footer {
  margin: 20px;
}
.div-inline1 {
  display: inline-block;
}

.button-search1 {
  display: flex;
  justify-content: space-between;
  margin-right: 32em;
  margin-bottom: 14px;
  margin-top: 18px;
  span {
  }
}
.distance_style_1011 {
  text-align: center;
  margin-left: 2em;
}
.distance_style_101 {
  width: 180px;
}
.input_width_300 .el-input {
}
.div-inline {
  display: inline-block;
}

.button-search {
  display: flex;
  justify-content: space-between;
  margin-right: 42em;
  margin-bottom: 14px;
  margin-top: 18px;
  span {
  }
}
.distance_style_10 {
  margin-left: 10em;
  text-align: center;
  line-height: 62px;
}
.loc {
  display: flex;
  flex-wrap: nowrap;
}
</style>
