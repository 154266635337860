<template>
  <div class="content">
    <!-- 内容主体区域 -->
    <el-form
      :model="addForm"
      :rules="addFormRules"
      ref="addFormRef"
      label-width="70px"
    >
      <el-form-item label="用户名" prop="username">
        <el-input v-model="addForm.username" disabled></el-input>
      </el-form-item>
      <el-form-item label="邮 箱" prop="email">
        <el-input v-model="addForm.email" placeholder="请输入邮箱"></el-input>
      </el-form-item>
      <el-form-item label="手 机" prop="userPhone">
        <el-input
          disabled
          v-model="addForm.userPhone"
          placeholder="请输入手机号"
        ></el-input>
      </el-form-item>
      <el-form-item label="城 市" prop="city">
        <el-input v-model="addForm.city" placeholder="请输入城市"></el-input>
      </el-form-item>
      <el-upload
        class="avatar-uploader"
        action=""
        :show-file-list="false"
        :before-upload="beforeAvatarUpload"
        :http-request="upload"
      >
        <img
          v-if="addForm.avatar"
          :src="addForm.avatar"
          class="avatar"
          style="margin-left: 70px;"
        />
        <i v-else class="el-icon-plus avatar-uploader-icon">头像</i>
      </el-upload>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button @click="quxiao">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    // 验证邮箱的规则
    var checkEmail = (rule, value, cb) => {
      // 验证邮箱的正则表达式
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
      if (regEmail.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法的邮箱！'))
    }
    var checkMobile = (rule, value, cb) => {
      // 验证手机号规则
      const regMobile = /^([0|86|17951])?(13[0-9]|15[0123456789]|17[678]|18[0-9]|19[0-9]|14[57])[0-9]{8}$/
      if (regMobile.test(value)) {
        return cb()
      }
      cb(new Error('手机号不合法！'))
    }
    return {
      addForm: {
        username: 'admin',
        city: '',
        email: '',
        userPhone: '',
        avatar: ''
      },
      addFormRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          {
            min: 3,
            max: 10,
            message: '用户名的字符长度在 3~10 个字符之间',
            trigger: 'blur'
          }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' }
        ],
        userPhone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ],
        city: [
          { required: true, message: '请输入城市', trigger: 'blur' },
          {
            min: 2,
            max: 10,
            message: '城市的字符长度在 3~10 个字符之间',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created() {
    this.getUserList()
  },
  props: {
    layerid: {
      // 自动注入的layerid
      type: String,
      default: ''
    },
    iframeData: {
      // 传递的数据
      type: Object,
      default: () => {
        return {}
      }
    },
    lydata: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    async getUserList() {
      console.log('----------------------')
      var name = window.sessionStorage.getItem('username')

      const { data: res } = await this.$http.get(`/getAdminInfo/${name}`)
      console.log(res)
      if (res.code !== '20000') {
        return this.$layer.msg('获取参数失败')
      }
      this.addForm.username = res.data.username
      this.addForm.userPhone = res.data.Phone
      this.addForm.city = res.data.city
      this.addForm.email = res.data.email
      this.$layer.msg('获取用户信息成功')
    },
    upload(param) {
      const formData = new FormData()
      formData.append('file', param.file)
      const url = 'https://api.shunhou.wandoukeji.com/api/v1/uploadImage'
      this.$http
        .post(url, formData)
        .then(res => {
          console.log(res)
          this.$layer.msg('上传图片成功')
          this.addForm.avatar = res.data.data.path
        })
        .catch(response => {
          this.$layer.msg('上传图片失败')
        })
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    handleAvatarSuccess(res) {
      console.log(res)
    },
    async onSubmit() {
      // // 更新父对象数据信息
      this.$parent.$data.iframeData = Object.assign({}, this.addForm)
      console.log({ ...this.$parent.$data.iframeData, add: 'aaa' })
      // console.log(this.addForm)
      const { data: res } = await this.$http.post(
        '/updateAdmin',
        this.$parent.$data.iframeData
      )
      // // 或者
      // this.lydata.iframeData = Object.assign(this.lydata.iframeData, this.form)
      if (res.code !== '20000') {
        this.$layer.msg('出现异常修改失败，请重试')
      } else {
        this.$layer.msg('修改成功')
        this.$layer.close(this.layerid)
      }
    },
    quxiao() {
      this.$layer.close(this.layerid)
    }
  }
}
</script>

<style lang="less" scoped>
.el-form-item__content {
  margin-left: 20px !important;
}
.content {
  width: 100%;
}
.el-form {
  margin: 50px 20%;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-icon-plus {
  border: 1px dashed #d9d9d9 !important;
  margin-bottom: 10px !important;
  margin-left: 70px !important;
}
</style>
