import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './plugins/antDesignVue.js'
import Print from './plugins/print.js'
// antd对话框可拖拽 自定义指令
import './common/dragModal.js'
import Contextmenu1 from 'vue-contextmenujs'
import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'
// import './theme/index.css'
// import 'ant-design-vue/dist/antd.css'
import axios from 'axios'
import layer from 'vue-layer'
import 'vue-layer/lib/vue-layer.css'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.snow.css'
import Contextmenu from 'e-vue-contextmenu'
// 导入字体样式
import './assets/fonts/css/font-awesome.min.css'
// import './assets/fonts/css/font-awesome.min.css'
// 导入全局样式
import '@/assets/css/global.css'
// import 'quill/dist/quill.core.css' // import styles
// import 'quill/dist/quill.snow.css' // for snow theme
// import 'quill/dist/quill.bubble.css' // for bubble theme
// import vuetify from '@/plugins/vuetify' // path to vuetify export
// 导入js
import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'
import VueAMap from 'vue-amap'
// jsMD5加密
import md5 from 'js-md5'

import VueIntro from 'vue-introjs'
import 'intro.js/introjs.css'
// 粒子特效
import VueParticles from 'vue-particles'

import TreeTable from 'vue-table-with-tree-grid'
Vue.component('tree-table', TreeTable)

Vue.use(VueParticles)
Vue.use(VueIntro)
Vue.use(Print)

Vue.prototype.$md5 = md5
// eslint-disable-next-line no-undef
Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
  key: '2948dcef862f641609c10e52686ec039',
  plugin: [
    'AMap.Autocomplete',
    'AMap.Geocoder',
    'AMap.PlaceSearch',
    'AMap.Scale',
    'AMap.OverView',
    'AMap.ToolBar',
    'AMap.MapType',
    'AMap.AMapManager',
    'AMap.Geolocation'
  ],
  v: '1.4.4',
  uiVersion: '1.0.11'
})

// eslint-disable-next-line no-undef
Vue.use(Contextmenu)
Vue.use(Contextmenu1)
Vue.use(ViewUI)
// eslint-disable-next-line no-undef
Vue.prototype.$layer = layer(Vue)
// eslint-disable-next-line no-undef
Vue.prototype.$http = axios
// eslint-disable-next-line no-undef
Vue.prototype.$bus = new Vue()
// axios.defaults.baseURL = 'http://127.0.0.1:8081/api/v1/'
// axios.defaults.baseURL = 'https://api.channel.sgyzyun.club/api/v1/'
// eslint-disable-next-line no-undef
axios.defaults.baseURL = 'https://api.shunhou.wandoukeji.com/api/v1/'
// eslint-disable-next-line no-undef
axios.interceptors.request.use(config => {
  // console.log(config)
  // 下面会说在什么时候存储 token
  // eslint-disable-next-line no-undef
  NProgress.start()
  // eslint-disable-next-line dot-notation
  var token = window.sessionStorage.getItem('token')
  // eslint-disable-next-line quote-props
  Object.assign(config.headers, { token: token })
  return config
})
// eslint-disable-next-line no-undef
axios.interceptors.response.use(config => {
  // eslint-disable-next-line no-undef
  NProgress.done()
  return config
})
// eslint-disable-next-line no-undef
Vue.filter('dateFormat', function(originVal) {
  // eslint-disable-next-line prefer-const
  let time = new Date(Number(originVal))
  // eslint-disable-next-line prefer-const
  let year = time.getFullYear()
  const month = (time.getMonth() + 1).toString().padStart(2, '0')
  const date = time
    .getDate()
    .toString()
    .padStart(2, '0')
  const hours = time
    .getHours()
    .toString()
    .padStart(2, '0')
  const minute = time
    .getMinutes()
    .toString()
    .padStart(2, '0')
  const second = time
    .getSeconds()
    .toString()
    .padStart(2, '0')

  return (
    year + '-' + month + '-' + date + ' ' + hours + ':' + minute + ':' + second
  )
})
// eslint-disable-next-line no-undef
Vue.filter('jiequ', function(originVal) {
  var a = String(originVal).split(' ')
  return a[0]
})
// axios.defaults.withCredentials = true
// eslint-disable-next-line no-undef
Vue.config.productionTip = false
// eslint-disable-next-line no-undef
Vue.use(VueQuillEditor)
// eslint-disable-next-line no-undef
new Vue({
  // eslint-disable-next-line no-undef
  router,
  store,
  // vuetify,
  render: h => h(App)
}).$mount('#app')
