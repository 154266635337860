<template>
  <div
    class="login_container"
    :style="
      'background-image:url(' +
        'https://api.shunhou.wandoukeji.com/upimg/images/bg-01.jpg' +
        ');background-size: cover;'
    "
  >
    <vue-particles class="lizi" color="#dedede"></vue-particles>
    <canvas
      style="pointer-events: none;"
      id="paopao"
      width="100%"
      height="100%"
    />
    <!-- <div class=" star comet" width="100%"></div> -->
    <!-- <img src="../assets/fengche.png" alt="风车图片" class="zhuan" /> -->
    <div :class="'login_box ' + test">
      <div v-if="isShowWX">
        <!-- logo区域 -->
        <div class="logo">
          <img :src="logo" alt="万斗科技" width="200px" />
        </div>
        <!-- <div class="avater_box">
        <h1>KX后台管理系统</h1>
      </div> -->
        <!-- 登录表单区域 -->
        <el-form
          ref="loginFromRef"
          :model="loginFrom"
          :rules="loginFromRules"
          label-width="0px"
          :class="'login_from '"
        >
          <!-- 用户名 -->
          <el-form-item prop="username">
            <el-input
              v-model="loginFrom.username"
              prefix-icon="fa fa-user-o"
              placeholder="请输入用户名"
            ></el-input>
          </el-form-item>
          <!-- 密码 -->
          <el-form-item prop="password">
            <el-input
              type="password"
              v-model="loginFrom.password"
              prefix-icon="fa fa-lock"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
          <!--验证码功能-->
          <el-form-item prop="yayaya">
            <el-input
              v-model="loginFrom.imgValue"
              prefix-icon="fa fa-key"
              style="float: left; width: 180px;"
            >
            </el-input>
            <div>
              <img
                @click="getImgcode()"
                style="height: 40px;padding-left:20px;"
                :src="imgVal"
              />
            </div>
          </el-form-item>

          <!-- 按钮区域 -->
          <el-form-item class="btns">
            <el-button @click="forgetPwd" type="text">忘记密码？</el-button>
            <el-button class="denglu" type="primary" @click="login"
              >登录</el-button
            >
            <!-- <el-button type="info" @click="resetLoginFrom">重置</el-button> -->
            <el-button
              class="wxdenglu"
              type="success"
              :class="weixin"
              @click="WxLogin"
              >微信</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div v-else id="weixinLogin"></div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Qs from 'qs'
export default {
  data() {
    return {
      weixin: 'iShow',
      test: '',
      loginFrom: {
        username: '',
        password: '',
        uuid: '',
        imgValue: '验证码'
      },
      isShowWX: true,
      bkImg:
        'https://api.shunhou.wandoukeji.com/upimg/images/ba1a6ed35e514cdc9d66f2b32bfeb4a2.jpg',
      imgVal: '',
      logo: '',
      // 表单验证规则对象
      loginFromRules: {
        yayaya: [
          { required: false, message: '请输入验证码', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        username: [
          { required: true, message: '请输入登录名称', trigger: 'blur' },
          { min: 3, max: 10, message: '长度在 3 到 10个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
          { min: 6, max: 15, message: '长度在 6 到 15个字符', trigger: 'blur' }
        ]
      }
    }
  },
  components: {},
  created() {
    this.getBkImg()
    this.getLogo()
    this.getIsShow()
  },
  mounted() {
    do_canvas()
    // 初始化微信js
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js'
    document.body.appendChild(script)
    // const snowjs = document.createElement('script')
    // snowjs.type = 'text/javascript'
    // snowjs.src = 'https://cdn.jsdelivr.net/gh/TRHX/CDN-for-itrhx.com@3.0.6/js/snow3.js'
    // document.body.appendChild(snowjs)
  },
  methods: {
    // 查询那些按钮显示
    async getIsShow() {
      const { data: res } = await this.$http.get(`/xkeys/login/isShow/1`)
      if (res.code !== '20000') {
        this.$message.error(res.message)
      }
      const { data: res1 } = await this.$http.get(`/xkeys/login/isShow/2`)
      if (res1.code !== '20000') {
        this.$message.error(res.message)
      }

      // eslint-disable-next-line eqeqeq
      if (res.data.value1 == 1 && res1.data.value2 == 1) {
        await this.getImgcode()
        this.weixin = ''
        return false
      }
      // eslint-disable-next-line eqeqeq
      if (res.data.value1 == 1 && res1.data.value2 == 0) {
        // 只显示用户名密码登录
        await this.getImgcode()
        this.weixin = 'isShow'
        return false
      }
      // eslint-disable-next-line eqeqeq
      if (res.data.value1 == 0 && res1.data.value2 == 1) {
        // 只显示用微信登录
        this.isShowWX = false
        this.WxLogin()
        return false
      }
      // eslint-disable-next-line eqeqeq
      if (res.data.value1 == 0 && res1.data.value2 == 0) {
        // 只显示用微信登录
        this.test = 'isShow'
        return false
      }
    },
    // 微信二维码登录
    async WxLogin() {
      this.isShowWX = false
      const { data: res } = await this.$http.get('wd/getLoginParam')
      console.log(res)
      // eslint-disable-next-line eqeqeq
      if (res.code == '20000') {
        // eslint-disable-next-line no-undef
        var obj = new WxLogin({
          self_redirect: false,
          id: 'weixinLogin', // 需要显示的容器id
          appid: res.data.appid,
          scope: res.data.scope, // 网页默认即可
          redirect_uri: res.data.redirectUri, // 授权成功后回调的url
          state: res.data.state, // 可设置为简单的随机数加session用来校验
          style: 'black', // 提供"black"、"white"可选。二维码的样式
          // href: 'https://api.shunhou.wandoukeji.com/qr.css' // 外部css文件url，需要https
          href: 'https://api.shunhou.wandoukeji.com/qr.css' // 外部css文件url，需要https
        })
      }
    },
    // 点击重置表单
    resetLoginFrom() {
      this.$refs.loginFromRef.resetFields()
    },
    async getBkImg() {
      const { data: res } = await this.$http.get('getBkImg')
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      this.bkImg = res.data.img
    },
    async getImgcode() {
      const { data: res } = await this.$http.get('getCode')
      this.imgVal = res.data.img
      this.loginFrom.uuid = res.data.key
    },
    async getLogo() {
      const { data: res } = await this.$http.get('getLogo')
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      this.logo = res.data.logo
    },
    login() {
      this.$refs.loginFromRef.validate(async valid => {
        if (!valid) return false
        const { data: res } = await this.$http.post(
          'WebLogin',
          Qs.stringify(this.loginFrom)
        )
        if (res.code !== '20000') {
          return this.$message.error(res.message)
        }
        // this.$message.success({
        //   showClose: true,
        //   message: '登陆成功~'
        // })

        // this.$http({
        //   method: 'post',
        //   url: 'WebLogin',
        //   headers: {
        //     'Content-type': 'application/x-www-form-urlencoded'
        //   },
        //   data: this.loginFrom,
        //   transformRequest: [
        //     function(data) {
        //       console.log(data)
        //     }
        //   ]
        // }).then(res => {
        //   console.log(res.data)
        // })
        // console.log(res)
        // if (res.meta.status !== 200) {
        //   return this.$message.error('登陆失败！')
        // }
        this.$message.success({
          showClose: true,
          message: '登陆成功~'
        })
        // 1.强登录之后的token，保存到客户端的sessionStorage中

        // 1.1项目中除了登录以外的窗口其他api的接口必须登录止之后才能访问
        // 1.2sessionStorage之应当在网站打开期间有效，所以讲Token保存在sessionStorage中
        window.sessionStorage.setItem('token', res.data.token)
        window.sessionStorage.setItem('username', this.loginFrom.username)
        // 2.通过编程是导航跳转到后台主页 路由地址是/home
        // this.$bus.emit('name1', this.loginFrom.username)
        this.$router.push('/home')
      })
    },
    forgetPwd() {
      this.$router.push('/forgetPwd')
    }
  }
}

/* 下面是悬浮气泡的小功能 */
let width
let height
let containerID
let canvasID
let ctx
let circles
const animateHeader = !0
let canvasload = 1
// eslint-disable-next-line camelcase
function do_canvas() {
  initHeader()
  addListeners()
}
function initHeader() {
  // eslint-disable-next-line no-unused-expressions
  containerID = $('.login_container')[0]
  canvasID = document.getElementById('paopao')
  width = containerID.offsetWidth
  height = containerID.offsetHeight
  canvasID.width = width
  canvasID.height = height
  ctx = canvasID.getContext('2d')
  circles = []
  for (let t = 0; t < 0.1 * width; t++) {
    const e = new Circle()
    circles.push(e)
  }
  // eslint-disable-next-line no-unused-expressions
  canvasload === 1 && (animate(), (canvasload = 0))
}
function animate() {
  if (animateHeader) {
    ctx.clearRect(0, 0, width, height)
    for (const t in circles) {
      circles[t].draw()
    }
  }
  requestAnimationFrame(animate)
}
function addListeners() {
  window.addEventListener('resize', resize)
}
function resize() {
  // eslint-disable-next-line no-unused-expressions
  containerID = $('.login_container')[0]
  width = containerID.offsetWidth
  height = containerID.offsetHeight
  canvasID.width = width
  canvasID.height = height
}
function Circle() {
  // eslint-disable-next-line no-unused-expressions
  const e = this
  function t() {
    e.pos.x = Math.random() * width
    e.pos.y = height + 110 * Math.random()
    e.alpha = 0.1 + 0.3 * Math.random()
    e.scale = 0.1 + 0.5 * Math.random()
    e.velocity = Math.random()
  }
  // eslint-disable-next-line no-unused-expressions
  !(function() {
    e.pos = {}
    t()
  })()
  this.draw = function() {
    e.alpha <= 0 && t()
    e.pos.y -= e.velocity
    e.alpha -= 4e-4
    ctx.beginPath()
    ctx.arc(e.pos.x, e.pos.y, 10 * e.scale, 0, 2 * Math.PI, !1)
    ctx.fillStyle = 'rgba(229,243,230,' + e.alpha + ')'
    ctx.fill()
  }
}
</script>

<style lang="less" scoped>
.login_container {
  // background-color: #2b4b6b;
  // 此处更改
  background-color: #f5f5f5;
  background-size: 100%;
  height: 100%;
}
.login_box:hover {
  transform: perspective(800px) rotateX(12deg);
}

.login_box {
  z-index: 2;
  width: 360px;
  height: 420px;
  // 此处更改
  /* background-color: rgba(255, 255, 255, 0.5);*/
  border-radius: 3px;
  position: absolute;
  left: calc(50% - 180px);
  top: calc(50% - 240px);
  /*  transform: translate(-50%, -50%);*/
  // 此处更改
  /*box-shadow: 0 0 5px #ddd;*/
  transform-style: preserve-3d;

  /* 做变形动画需要设置初始值，不设置容易出现跳变的bug */
  transform: perspective(800px) rotateX(0deg);
  transition: all 500ms ease;

  .avater_box {
    height: 24px;
    width: 80%;
    border: 1px solid #eee;

    padding: 10px;
    box-shadow: 0 0 10px #ddd;
    position: absolute;
    left: 35%;
    top: 16%;
    transform: translate(-50%, -50%);
    background-color: rgba(64, 156, 247, 0.7);

    h1 {
      text-shadow: 2px 2px 2px #fff;
      margin: 0;
      font-size: 20px;
      padding-left: 48px;
    }
  }

  .logo {
    margin-bottom: 20px;
    position: absolute;
    transform: translate(42%, 25%);
  }

  .login_from {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .btns {
    display: flex;
    justify-content: flex-start;
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.zhuan {
  z-index: 2;
  position: relative;
  left: 10px;
  width: 60px;
  height: 60px;
  margin: 10px auto 0;
  animation: rotating 2s linear infinite;
}

#fizz {
  width: 100%;
  height: 100%;
}

#paopao {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.star {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  position: absolute;
  background-color: rgba(237, 205, 163, 0.8);
  box-shadow: 0 0 40px 0 rgba(237, 205, 163, 0.8), 0 0 20px 0 #ffffff;
  animation: glow 5s infinite;
}
.star--medium {
  width: 6px;
  height: 6px;
}
.star--big {
  width: 9px;
  height: 9px;
  box-shadow: 0 0 40px 0 #edcda3, 0 0 20px 0 #ffffff, inset 0 0 4px #ffffff;
}

.comet {
  width: 6px;
  height: 6px;
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 40px 0 #edcda3, 0 0 20px 0 #ffffff,
    inset 0 0 8px rgba(255, 255, 255, 0.6);
  top: 0;
  left: 80%;
  opacity: 0.3;
  transform: rotate(-45deg) translate(0, -50px);
  animation: comet 6s infinite;
}
.comet:after {
  content: '';
  width: 20vw;
  height: 6px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 20px rgba(237, 205, 163, 0.4);
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes glow {
  0% {
    opacity: 0.9;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.9;
  }
}
@keyframes comet {
  0% {
    transform: rotate(-45deg) translateX(0);
    opacity: 0.3;
  }
  10% {
    opacity: 1;
  }
  20% {
    transform: rotate(-45deg) translateX(-100vw);
    opacity: 0;
  }
  100% {
    transform: rotate(-45deg) translateX(-100vw);
    opacity: 0;
  }
}
#weixinLogin {
  position: fixed;
  right: 8%;
  top: 30px;
}
.isShow {
  display: none !important;
}
.denglu {
  box-shadow: 0 0 8px #ddd;
  width: 8em;
  border-radius: 25px;
}
.wxdenglu {
  box-shadow: 0 0 8px #ddd;
  width: 8em;
  border-radius: 25px;
}
.lizi {
  height: 100%;
}
</style>
