<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>日志监控</el-breadcrumb-item>
      <el-breadcrumb-item>查看日志</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <!-- 提示区域 -->
      <el-alert
        title="点击按钮查看日志"
        type="info"
        center
        show-icon
        :closable="false"
        style="margin-bottom: 10px;"
      >
      </el-alert>
      <!-- 修改按钮 -->
      <el-button
        type="primary"
        icon="el-icon-position"
        plain
        @click="showDialog"
        >查看日志</el-button
      >
      <div
        id="zzzz"
        style="margin-top:20px;width: 200px;height:200px; border: 1px #000 solid;"
      >右击试试</div>
      <vue-context-menu
        class="right-menu"
        :target="Target"
        :show="Visible"
        @update:show="show => (Visible = show)"
      >
        <div class="menu-item">
          <div class="inner-item">
            <a href="javascript:" @click="paste">复制</a>
          </div>
        </div>
        <div class="menu-item">
          <div class="inner-item">
            <a href="javascript:" @click="paste">粘贴</a>
          </div>
        </div>
        <div class="menu-item">
          <div class="inner-item">
            <a href="javascript:" @click="paste">剪切</a>
          </div>
        </div>
        <!--  <div class="menu_hr" /> 选项之间的分隔线  -->
      </vue-context-menu>
    </el-card>
  </div>
</template>
<script>
import Log from '@/components/log/log'
import { component as VueContextMenu } from '@xunlei/vue-context-menu'
export default {
  name: '日志监控',
  components: {
    'vue-context-menu': VueContextMenu
  },
  data() {
    return {
      Visible: false,
      Target: '',
      selectedIndex: ''
    }
  },
  methods: {
    showDialog() {
      this.$layer.iframe({
        content: {
          content: Log,
          parent: this,
          data: { iframeData: this.iframeData }
        },
        area: ['1100px', '700px'],
        title: '日志监控',
        maxmin: true,
        shade: false,
        shadeClose: false,
        cancel: () => {
          // 关闭弹窗事件
          // alert('关闭iframe')
        }
      })
    },
    paste() {
      console.log('-----------------------------')
      this.Visible = false
    }
  },
  mounted() {
    const myChartContainer = document.getElementById('zzzz')
    // 右击显示菜单 区域位置
    this.Target = myChartContainer
    // 关闭浏览器右击默认菜单
    myChartContainer.oncontextmenu = function(e) {
      return false
    }
    // myChart.on('contextmenu', 'series', params => {
    //   params.event.event.preventDefault()
    //   this.selectedIndex = params.data.id
    // })
  }
}
</script>

<style lang="less" scoped>
.right-menu a {
  width: 90px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  display: block;
  color: #1a1a1a;
}

a {
  text-decoration: none;
}
.right-menu {
  position: fixed;
  z-index: 999;
  display: none;
  font-size: 1.1em;
  width: 120px;
  height: auto;
  padding: 5px 0px;
  border-radius: 5px;
  top: 10px;
  left: 10px;
  background-color: #fff;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24);
  color: #333;
}
.right-menu .menu-item {
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.right-menu .menu-item:hover {
  background-color: #ddd;
}
.right-menu .menu-item .inner-item {
  width: 120px;
  margin: 0px 10px;
}
.right-menu .menu-item .inner-item a {
  margin: 0 5px 0 0;
  /*font-weight: bold;*/
}
.right-menu .menu_hr {
  height: 1px;
  border-top: 1px solid #bbb;
  margin: 3px 10px;
}
</style>
