import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
// eslint-disable-next-line object-curly-spacing
// import {setTheme, defaultTheme} from '@/assets/theme/setTheme.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    toolBarData: [
      { name: '首页', detail: '/welcome', componentName: 'Welcome' }
    ], // 保存标签button的数组
    cacheView: ['Welcome'], // 保存需要缓存的数组
    loc: {
      lng: '',
      lat: ''
    }
  },
  getters: {
    getToolData(state) {
      return state.toolBarData
    },
    getCacheView(state) {
      return state.cacheView
    }
  },
  mutations: {
    setToolData(state, data) {
      // 添加标签按钮，如果当前路由已经打开，则不再重复添加
      const inToolbar = state.toolBarData.find(
        item => item.detail === data.detail
      )
      !inToolbar &&
        state.toolBarData.push({
          ...data
        })
    },
    setCacheView(state, data) {
      // 与setToolData类似
      if (state.cacheView.includes(data.componentName)) return
      state.cacheView.push(data.componentName)
    },
    clearToolItem(state, detail) {
      if (state.toolBarData.length === 1) {
        return
      }
      const index = state.toolBarData.findIndex(item => item.detail === detail)
      const isActive =
        // eslint-disable-next-line dot-notation
        // eslint-disable-next-line eqeqeq
        router.app.$route.path ==
        // eslint-disable-next-line dot-notation
        state.toolBarData[index]['detail']
      const len = state.toolBarData.length - 1
      state.toolBarData.splice(index, 1)
      // eslint-disable-next-line eqeqeq
      ;(index == len || isActive) &&
        router.push({
          // eslint-disable-next-line dot-notation
          path: state.toolBarData[state.toolBarData.length - 1]['detail']
        })
    },
    clearCacheView(state, viewName) {
      if (state.cacheView.length === 1) {
        return
      }
      // eslint-disable-next-line eqeqeq
      const index = state.cacheView.findIndex(item => item == viewName)
      state.cacheView.splice(index, 1)
    },
    closeLeftItem(state, data) {
      if (data === 0) {
        return
      }
      state.toolBarData.splice(0, data)
    },
    closeLeftCache(state, data) {
      if (data === 0) {
        return
      }
      state.cacheView.splice(0, data)
    },
    closeRightItem(state, data) {
      const len = state.toolBarData.length - 1
      if (data === len) {
        return
      }
      state.toolBarData.splice(data + 1, len - data)
    },
    closeRightCache(state, data) {
      const len = state.toolBarData.length - 1
      if (data === len) {
        return
      }
      state.cacheView.splice(data + 1, len - data)
    }
  },
  actions: {
    commitToolBar({ commit }, data) {
      commit('setToolData', data)
      commit('setCacheView', data)
    },
    clearToolBar({ commit }, data) {
      commit('clearToolItem', data.detail)
    },
    clearCache({ commit }, data) {
      commit('clearCacheView', data)
    },
    closeLeft({ commit }, data) {
      commit('closeLeftItem', data)
    },
    closeLeftCache({ commit }, data) {
      commit('closeLeftCache', data)
    },
    closeRight({ commit }, data) {
      commit('closeRightItem', data)
    },
    closeRightCache({ commit }, data) {
      commit('closeRightCache', data)
    }
  }
})
