import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/components/login'
import Home from '@/components/home'
import Welcome from '@/components/welcome'
import Admin from '@/components/user/Admin'
import AdminInfo from '@/components/user/AdminInfo'
import FeedBack from '@/components/feedback/FeedBack'
import User from '@/components/user/User'
import LogInfo from '@/components/log/LogInfo'
import Scheduled from '@/components/scheduled/Scheduled'
import Goods from '@/components/goods/Goods'
import AgentGoods from '@/components/goods/AgentGoods'
import Add from '@/components/goods/Add'
import Order from '@/components/orders/Orders'
import BusiOrder from '@/components/orders/AgentOrders'
import Refund from '@/components/orders/Refund'
import BusiRefund from '@/components/orders/AgentRefund'
import Report from '@/components/report/Report'
import Settings from '@/components/setting/Settings'
import ForgetPwd from '@/components/Forget'
import Lunbo from '@/components/xiaochengxu/Lunbo'
import Callback from '@/components/weixin/callback'
import Public from '@/components/xiaochengxu/Public'
import Coupon from '@/components/coupon/Coupon'
import orderstar from '@/components/orderstar/orderstar'
import cLevel from '@/components/goods/changedlevel'
import axios from 'axios'
// eslint-disable-next-line no-undef
Vue.use(VueRouter)

const routes = [
  // 重定向路由地址
  { path: '/', redirect: '/login' },
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
  { path: '/login', component: Login },
  {
    path: '/weixin/callback',
    component: Callback,
    name: 'callback',
    meta: { title: '回调页面' }
  },
  {
    path: '/home',
    component: Home,
    name: 'Home',
    meta: { title: '主页' },
    redirect: '/welcome',
    children: [
      {
        path: '/welcome',
        component: Welcome,
        name: 'Welcome',
        meta: { title: '欢迎' }
      },
      {
        path: '/admins',
        component: Admin,
        name: 'Admins',
        meta: { title: '管理员管理' }
      },
      {
        path: '/adInfo',
        component: AdminInfo,
        name: 'Adinfo',
        meta: { title: 'admin信息' }
      },
      {
        path: '/feedback',
        component: FeedBack,
        name: 'Feedback',
        meta: { title: '用户反馈' }
      },
      {
        path: '/users',
        component: User,
        name: 'Users',
        meta: { title: '用户管理' }
      },
      {
        path: '/loginfo',
        component: LogInfo,
        name: 'Loginfo',
        meta: { title: '日志监控' }
      },
      {
        path: '/scheduled',
        component: Scheduled,
        name: 'scheduled',
        meta: { title: '定时任务' }
      },
      {
        path: '/goods',
        component: Goods,
        name: 'Goods',
        meta: { title: '商品界面' }
      },
      {
        path: '/goods/add',
        component: Add,
        name: 'add',
        meta: { title: '添加商品' }
      },
      {
        path: '/orders',
        component: Order,
        name: 'orders',
        meta: { title: '订单详情' }
      },
      {
        path: '/BusiOrder',
        component: BusiOrder,
        name: 'BusiOrder',
        meta: { title: '订单管理' }
      },
      {
        path: '/refund',
        component: Refund,
        name: 'refund',
        meta: { title: '退款订单' }
      },
      {
        path: '/BusiRefund',
        component: BusiRefund,
        name: 'BusiRefund',
        meta: { title: '退款订单' }
      },
      {
        path: '/reports',
        component: Report,
        name: 'reports',
        meta: { title: '数据统计' }
      },
      {
        path: '/settings',
        component: Settings,
        name: 'Settings',
        meta: { title: '常规设置' }
      },
      {
        path: '/images',
        component: Lunbo,
        name: 'Images',
        meta: { title: '轮播图设置' }
      },
      {
        path: '/public',
        component: Public,
        name: 'Public',
        meta: { title: '公告设置' }
      },
      // },
      {
        path: '/test',
        component: resolve => require(['../views/Test.vue'], resolve),
        name: 'Test',
        meta: { title: '路线图' }
      },
      {
        path: '/coupon',
        component: resolve =>
          require(['../components/coupon/Coupon.vue'], resolve),
        name: 'Coupon',
        meta: { title: '优惠券' }
      },
      {
        path: '/BusiDiscount',
        component: resolve =>
          require(['../components/coupon/AgentCoupon.vue'], resolve),
        name: 'BusiDiscount',
        meta: { title: '优惠券' }
      },
      {
        path: '/orderstar',
        component: resolve =>
          require(['../components/orderstar/orderstar.vue'], resolve),
        name: 'orderstar',
        meta: { title: '订单评价' }
      },
      {
        path: '/orderChanged',
        component: resolve =>
          require(['../components/orderChanged/orderChanged.vue'], resolve),
        name: 'orderChanged',
        meta: { title: '订单改签' }
      },
      {
        path: '/BusiOrderChange',
        component: resolve =>
          require(['../components/orderChanged/AgentorderChanged.vue'], resolve),
        name: 'BusiOrderChange',
        meta: { title: '订单改签' }
      },
      {
        path: '/verifyLog',
        component: resolve =>
          require(['../components/verifyLog/verifyLog.vue'], resolve),
        name: 'verifyLog',
        meta: { title: '核校日志' }
      },
      {
        path: '/operationLog',
        component: resolve =>
          require(['../components/verifyLog/optLog.vue'], resolve),
        name: 'operationLog',
        meta: { title: '操作日志' }
      },
      {
        path: '/movie',
        component: resolve =>
          require(['../components/movie/movie.vue'], resolve),
        name: 'movie',
        meta: { title: '电影订单' }
      },
      {
        path: '/clevel',
        component: cLevel,
        name: 'clevel',
        meta: { title: '编辑层级关系' }
      },
      {
        path: '/BusiProduct',
        component: AgentGoods,
        name: 'Goods',
        meta: { title: '商品页面' }
      },
      {
        path: '/BusiPerson',
        component: resolve =>
          require(['../components/mine/Mine.vue'], resolve),
        name: 'BusiPerson',
        meta: { title: '我的页面' }
      },
      {
        path: '/online',
        component: resolve =>
          require(['../components/online/online.vue'], resolve),
        name: 'online',
        meta: { title: '在线商家' }
      },
    ]
  },
  { path: '/forgetPwd', component: ForgetPwd },
  {
    path: '*',
    component: resolve => require(['../views/404.vue'], resolve)
  }
]
// eslint-disable-next-line no-undef
const router = new VueRouter({
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.path === '/login') return next()
  if (to.path === '/forgetPwd') return next()
  if (to.path === '/weixin/callback') return next()
  const tokenStr = window.sessionStorage.getItem('token')
  if (!tokenStr) return next('/login')
  // eslint-disable-next-line no-undef
  const { data: res } = await axios.get(`isExpire/${tokenStr}`)
  console.log(res.data.isExpire)
  if (res.data.isExpire) {
    next()
  } else {
    console.log('---------------------进入-----')
    return next('/login')
  }
})

export default router
