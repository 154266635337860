<template>
  <div class="content">
    <el-dialog
      title="路线图编辑"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :modal="false"
      :fullscreen="true"
    >
      <Test @func="getMsgFormTest"></Test>
    </el-dialog>
    <!-- 卡片视图区 -->
    <!-- Two-way Data-Binding 富文本编辑器组件
    <div class="ql-snow">
      <div class="ql-editor" v-html="iframeData.goodsIntroduce"></div>
    </div>-->
    <quill-editor
      v-model="iframeData.goodsIntroduce"
      ref="myQuillEditor"
      :options="editorOption"
      @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)"
      @ready="onEditorReady($event)"
    >
    </quill-editor>
    <el-upload
      drag
      multiple
      :headers="headersObj"
      class="quill-upload"
      :on-success="handleSuccess"
      :action="uploadURL"
      v-show="false"
    >
      <!-- <el-button size="small" type="primary">点击上传</el-button>
                          @change="onEditorChange($event)"
                @ready="onEditorReady($event)"
           -->
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">
        将文件拖到此处，或
        <em>点击上传</em>
      </div>
      <div slot="tip" class="el-upload__tip">
        只能上传jpg/png文件，且不超过500kb
      </div>
    </el-upload>
    <br />
    <el-button type="info" @click="quxiao">取消</el-button>
    <el-button type="success" @click="onSubmit">保存</el-button>
    <!-- 内容主体区域 -->
  </div>
</template>

<script>
import QS from 'qs'
import hljs from 'highlight.js'
import dedent from 'dedent'
import quillEmoji from 'quill-emoji'
import 'quill-emoji/dist/quill-emoji.css'
// import 'highlight.js/styles/vs2015.css'
import 'highlight.js/styles/arduino-light.css'
import Test from '@/views/Test'
import Quill from 'quill'
const icons = Quill.import('ui/icons')

icons.emoji =
  '<svg t="1629989712684" class="icon" viewBox="0 0 1879 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2200" width="64" height="64"><path d="M939.209249 985.526012c260.439306 0 471.454335-211.163006 471.454335-471.454336S1199.500578 42.469364 939.209249 42.469364c-260.439306 0-471.602312 211.163006-471.602313 471.454335S678.621965 985.526012 939.209249 985.526012z m0-854.714451c211.606936 0 383.112139 171.505202 383.112138 383.112138s-171.505202 383.260116-383.112138 383.260116-383.112139-171.653179-383.112139-383.260116S727.602312 130.811561 939.209249 130.811561zM703.334104 384.295954c0-32.554913 26.339884-58.894798 58.894798-58.894798 32.554913 0 58.894798 26.339884 58.894797 58.894798 0 32.554913-26.339884 58.894798-58.894797 58.894797-32.406936 0-58.894798-26.339884-58.894798-58.894797z m353.66474 0c0-32.554913 26.339884-58.894798 58.894798-58.894798 32.554913 0 58.894798 26.339884 58.894797 58.894798 0 32.554913-26.339884 58.894798-58.894797 58.894797-32.406936 0-58.894798-26.339884-58.894798-58.894797z m6.215029 227.588439H815.944509c-17.313295 0-31.519075-14.20578-31.519075-31.519075v-3.847399c0-17.313295 14.20578-31.519075 31.519075-31.519075h247.269364c17.313295 0 31.519075 14.20578 31.519075 31.519075v3.847399c0 17.461272-14.20578 31.519075-31.519075 31.519075z" fill="#212121" p-id="2201"></path></svg>'
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ direction: 'rtl' }], // text direction

  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
  ['link', 'image', 'video'],
  ['clean'] // remove formatting button
  // ['emoji']
]
export default {
  data() {
    var checkPass = (rule, value, cb) => {
      if (this.updateForm.newPass === value) {
        return cb()
      }
      cb(new Error('两次密码不一致'))
    }
    return {
      pos: null,
      dialogVisible: false,
      content: '',
      uploadURL: 'https://api.shunhou.wandoukeji.com/api/v1/uploadImage',
      // 图片上传组件的请求头
      headersObj: {
        token: window.sessionStorage.getItem('token')
      },
      editorOption: {
        theme: 'snow',
        modules: {
          'emoji-toolbar': true,
          'emoji-shortname': true,
          toolbar: {
            container: toolbarOptions,
            handlers: {
              // 重写点击组件上的图片按钮要执行的代码
              // eslint-disable-next-line quote-props
              image: function(value) {
                // console.log('---------------------')
                // console.log(value)
                if (value) {
                  document
                    .querySelector('.quill-upload .el-icon-upload')
                    .click()
                  // alert(1)
                } else {
                  this.quill.format('image', false)
                }
              },
              emoji() {
                console.log('插入表情')
              }
            }
          },
          syntax: {
            highlight: text => {
              return hljs.highlightAuto(text).value // 这里就是代码高亮需要配置的地方
            }
          }
        }
      }
    }
  },
  created() {
    // console.log(this.$parent.$data.iframeData)
    // this.updateForm.username = window.sessionStorage.getItem('username')
  },
  props: {
    // layerid: {
    //   // 自动注入的layerid
    //   type: String,
    //   default: ''
    // },
    iframeData: {
      // 传递的数据
      type: Object,
      default: () => {
        return {}
      }
    }
    // lydata: {
    //   type: Object,
    //   default: () => {
    //     return {}
    //   }
    // }
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    async onSubmit() {
      // // 更新父对象数据信息
      // this.$parent.$data.iframeData = Object.assign({}, this.updateForm)
      // 获取纯文本
      // const temptext = quill.getText() // 获取文本
      // const text = temptext.trim().replace(/\s/g, '') // 去掉多余的空格
      // console.log(this.iframeData.goodsIntroduce)
      const { data: res } = await this.$http.put('/products/updateContents', {
        goodsId: Number(this.iframeData.goodsId),
        goodsIntroduce: this.iframeData.goodsIntroduce
      })
      // console.log(res)
      // // // 或者
      // // this.lydata.iframeData = Object.assign(this.lydata.iframeData, this.form)
      if (res.code !== '20000') {
        this.$layer.msg(res.message)
      } else {
        this.$layer.msg(res.message)
        this.$layer.close(this.layerid)
      }
      // console.log(document.querySelector('div.ql-editor').innerHTML)
    },
    getMsgFormTest(data) {
      console.log('我是EDIT组件', data)
      if (this.pos == null) {
        this.$layer.msg('获取不到光标位置')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (data != '') {
        this.dialogVisible = false
        // 获取文本编辑器
        const quill = this.$refs.myQuillEditor.quill
        console.log(quill)
        // 插入图片到光标位置
        quill.insertEmbed(this.pos, 'image', data)

        this.$layer.msg('插入路线图成功')
      }
    },
    onEditorReady(quill) {
      setTimeout(() => {
        var revoke = document.querySelector('div.ql-toolbar') // 获取元素
        var Front = document.createElement('span') // 创建元素
        Front.className = 'fa fa-th-large' // 新增class属性
        revoke.appendChild(Front) // 追加到元素中
        Front.addEventListener('click', () => {
          this.dialogVisible = true
          // this.$layer.iframe({
          //   content: {
          //     content: Test,
          //     parent: this,
          //     data: { iframeData: {} }
          //   },
          //   area: ['1300px', '700px'],
          //   title: '修改详情内容',
          //   maxmin: true,
          //   shade: false,
          //   shadeClose: false,
          //   cancel: () => {
          //     // console.log(this.$store.loc)
          //     // this.editFrom.departurePlace =
          //     //   this.$store.loc.lng + ',' + this.$store.loc.lat
          //     // 关闭弹窗事件
          //     // alert('关闭iframe')
          //   }
          // })
        })
        document.querySelector(
          'div.ql-editor'
        ).innerHTML = this.iframeData.goodsIntroduce
      }, 1000)
    },
    onEditorFocus(ev) {
      // 获取文本编辑器
      const quill = this.$refs.myQuillEditor.quill
      // 获取光标位置
      const pos = quill.getSelection().index
      this.pos = pos
      console.log(pos)
    },
    test() {
      console.log(this.iframeData)
    },
    quxiao() {
      // this.$layer.close(this.layerid)
      this.$emit('funcclose', '')
    },
    onEditorBlur(ev) {
      // console.log(ev)
    },
    // 处理移除图片的操作
    uploadImg(response) {
      if (response.code === '20000') {
        this.params.img = response.data.path
        this.$message.success('图片插入成功')
      }
    },
    handleCommand(command) {
      this.params.id = Number(command)
      this.params.img = ''
      this.params.content = ''
    },
    // 图片上传成功时的钩子函数
    handleSuccess(response) {
      // 1.先拼接得到一个图片信息对象
      // const picInfo = { pic: response.data.path }
      // 2.push到pics数组中
      // console.log(response)
      // this.pics.push(response.data.path)
      if (response) {
        // 获取文本编辑器
        const quill = this.$refs.myQuillEditor.quill
        // 获取光标位置
        const pos = quill.getSelection().index
        // 插入图片到光标位置
        quill.insertEmbed(pos, 'image', response.data.path)
      } else {
        this.$message.error('图片插入失败')
      }
    }
  },
  components: {
    Test
  }
}
</script>

<style lang="less" scoped>
.el-form-item__content {
  margin-left: 20px !important;
}
.content {
  margin: 20px;
  width: 100%;
}
.el-form {
  margin: 50px 20%;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-icon-plus {
  border: 1px dashed #d9d9d9 !important;
  margin-bottom: 10px !important;
  margin-left: 70px !important;
}
</style>
