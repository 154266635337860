<template>
  <a-spin :spinning="spinning" :tip="tips">
    <a-alert
      message="提示: 如果节点名称较长，可以先把名称修改完成之后，再进行连线操作！"
      banner
      closable
      type="info"
    />
    <div class="flow_region">
      <div style="padding-left: 6px;">
        <br />
        <a-button type="primary" size="small" @click="saveImage">
          保存截图
        </a-button>
        <br />
        <br />
        <a-switch
          @change="onChange"
          checked-children="开启背景"
          un-checked-children="关闭背景"
          default-checked
        />
        <br />
        <br />
        变更背景<br />
        <a-radio-group v-model="value" @change="onChangeRadio">
          <a-radio :value="1"> 背景A </a-radio><br />
          <a-radio :value="2"> 背景B </a-radio><br />
          <a-radio :value="3"> 背景C </a-radio><br />
          <a-radio :value="4"> 背景D </a-radio><br />
          <a-radio :value="5"> 背景E </a-radio><br />
          <a-radio :value="6"> 默认 </a-radio><br />
        </a-radio-group>
      </div>

      <div class="nodes-wrap">
        <div
          v-for="item in nodeTypeList"
          :key="item.type"
          class="node"
          draggable="true"
          @dragstart="drag($event, item)"
        >
          <div class="log">
            <img :src="item.logImg" alt="" />
          </div>
          <div class="name">{{ item.typeName }}</div>
        </div>
      </div>

      <div
        id="flowWrap"
        ref="flowWrap"
        :class="flowCss"
        @drop="drop($event)"
        @dragover="allowDrop($event)"
      >
        <div id="flow">
          <div
            v-show="auxiliaryLine.isShowXLine"
            class="auxiliary-line-x"
            :style="{
              width: auxiliaryLinePos.width,
              top: auxiliaryLinePos.y + 'px',
              left: auxiliaryLinePos.offsetX + 'px'
            }"
          ></div>
          <div
            v-show="auxiliaryLine.isShowYLine"
            class="auxiliary-line-y"
            :style="{
              height: auxiliaryLinePos.height,
              left: auxiliaryLinePos.x + 'px',
              top: auxiliaryLinePos.offsetY + 'px'
            }"
          ></div>
          <flowNode
            v-for="item in data.nodeList"
            :id="item.id"
            :key="item.id"
            :node="item"
            @setNodeName="setNodeName"
            @deleteNode="deleteNode"
            @changeLineState="changeLineState"
          ></flowNode>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { jsPlumb } from 'jsplumb'
import { nodeTypeList } from './config/init'
import {
  jsplumbSetting,
  jsplumbConnectOptions,
  jsplumbSourceOptions,
  jsplumbTargetOptions
} from './config/commonConfig'
import methods from './config/methods'
import data from './config/data.json'
import flowNode from './components/node-item'
import html2canvas from 'html2canvas'
import $ from 'jquery'
export default {
  name: 'Test',
  components: {
    flowNode
  },
  data() {
    return {
      spinning: false, // 加载中....
      tips: '正在渲染上传图片...',
      screenshotStatus: false,
      flowCss: 'flow-wrap-active',
      jsPlumb: null,
      currentItem: null,
      nodeTypeList: nodeTypeList,
      nodeTypeObj: {},
      data: {
        nodeList: [],
        lineList: []
      },
      selectedList: [],
      jsplumbSetting: jsplumbSetting,
      jsplumbConnectOptions: jsplumbConnectOptions,
      jsplumbSourceOptions: jsplumbSourceOptions,
      jsplumbTargetOptions: jsplumbTargetOptions,
      auxiliaryLine: { isShowXLine: false, isShowYLine: false }, // 对齐辅助线是否显示
      auxiliaryLinePos: {
        width: '100%',
        height: '100%',
        offsetX: 0,
        offsetY: 0,
        x: 20,
        y: 20
      },
      commonGrid: [5, 5], // 节点移动最小距离
      selectModuleFlag: false, // 多选标识
      rectAngle: {
        px: '', // 多选框绘制时的起始点横坐标
        py: '', // 多选框绘制时的起始点纵坐标
        left: 0,
        top: 0,
        height: 0,
        width: 0
      }
    }
  },
  mounted() {
    this.jsPlumb = jsPlumb.getInstance()
    this.initNodeTypeObj()
    this.initNode()
    this.fixNodesPosition()
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    ...methods,
    initNodeTypeObj() {
      nodeTypeList.map(v => {
        this.nodeTypeObj[v.type] = v
      })
    },
    onChangeRadio(e) {
      // eslint-disable-next-line eqeqeq
      if (e.target.value == '1') {
        this.flowCss = 'flow-wrap-active1'
        // eslint-disable-next-line eqeqeq
      } else if (e.target.value == '2') {
        this.flowCss = 'flow-wrap-active2'
        // eslint-disable-next-line eqeqeq
      } else if (e.target.value == '3') {
        this.flowCss = 'flow-wrap-active3'
        // eslint-disable-next-line eqeqeq
      } else if (e.target.value == '4') {
        this.flowCss = 'flow-wrap-active4'
        // eslint-disable-next-line eqeqeq
      } else if (e.target.value == '5') {
        this.flowCss = 'flow-wrap-active5'
      } else {
        this.flowCss = 'flow-wrap-active'
      }
    },
    async saveImage() {
      // this.spinning = true
      scrollTo(0, 0)
      if (typeof html2canvas !== 'undefined') {
        // 以下是对svg的处理
        var nodesToRecover = []
        var nodesToRemove = []
        var svgElem = $('#flowWrap').find('svg') // divReport为需要截取成图片的dom的id
        svgElem.each(function(index, node) {
          var parentNode = node.parentNode
          var svg = node.outerHTML.trim()

          var canvas = document.createElement('canvas')
          // eslint-disable-next-line no-undef
          canvg(canvas, svg)
          if (node.style.position) {
            canvas.style.position += node.style.position
            canvas.style.left += node.style.left
            canvas.style.top += node.style.top
          }

          nodesToRecover.push({
            parent: parentNode,
            child: node
          })
          parentNode.removeChild(node)

          nodesToRemove.push({
            parent: parentNode,
            child: canvas
          })

          parentNode.appendChild(canvas)
        })
      }
      // this.screenshotStatus = true
      // eslint-disable-next-line prefer-const
      // let content = '@万斗网络科技'
      // const ImageDiv = document.querySelector('#flowWrap')
      console.log('---------保存图片-------------')
      // // // 使用html2canvas把界面内容生成图片

      // eslint-disable-next-line prefer-const
      let content = '我就是一个水印'
      // eslint-disable-next-line prefer-const
      // let imageCon = this.$refs.imageCon // 获取图片
      const ImageDiv = document.querySelector('#flowWrap')
      // eslint-disable-next-line prefer-const
      let width = ImageDiv.width // 设置canvas容器宽度
      // eslint-disable-next-line prefer-const
      let height = ImageDiv.height // 设置canvas容器高度
      // eslint-disable-next-line prefer-const
      await html2canvas(ImageDiv, { useCORS: true }).then(res => {
        var dataUrl = res.toDataURL('image/jpeg', 1.0) // 图片转为下载路径
        // imageCon.src = dataUrl
        this.upload(this.base64toFile(dataUrl), width, height)
        // console.log('-------------------2')
        // console.log(dataUrl)
      })
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    upload(param, width, height) {
      const formData = new FormData()
      formData.append('file', param)
      const url = 'https://api.shunhou.wandoukeji.com/api/v1/uploadImage'
      this.$http
        .post(url, formData)
        .then(res => {
          console.log(res)
          this.$layer.msg('上传图片成功')
          this.spinning = false
          this.$emit('func', res.data.data.path)
          console.log(res)
        })
        .catch(response => {
          this.$layer.msg('上传图片失败')
          this.spinning = false
        })
    },
    base64toFile(dataurl, filename = 'file') {
      // eslint-disable-next-line prefer-const
      let arr = dataurl.split(',')
      // eslint-disable-next-line prefer-const
      let mime = arr[0].match(/:(.*?);/)[1]
      // eslint-disable-next-line prefer-const
      let suffix = mime.split('/')[1]
      // eslint-disable-next-line prefer-const
      let bstr = atob(arr[1])
      let n = bstr.length
      // eslint-disable-next-line prefer-const
      let u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime
      })
    },
    onChange(checked) {
      if (checked) {
        this.flowCss = 'flow-wrap-active'
      } else {
        this.flowCss = 'flow-wrap'
      }
    },
    initNode() {
      this.data.lineList = data.lineList
      data.nodeList.map(v => {
        v.logImg = this.nodeTypeObj[v.type].logImg
        v.log_bg_color = this.nodeTypeObj[v.type].log_bg_color
        this.data.nodeList.push(v)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.flow_region {
  display: flex;
  width: 92%;
  height: 600px;
  margin: 20px auto;
  border: 1px solid #ccc;
  .nodes-wrap {
    width: 150px;
    height: 100%;
    border-right: 1px solid #ccc;
    .node {
      display: flex;
      height: 40px;
      width: 80%;
      margin: 5px auto;
      border: 1px solid #ccc;
      line-height: 40px;
      &:hover {
        cursor: grab;
      }
      &:active {
        cursor: grabbing;
      }
      .log {
        width: 40px;
        height: 40px;
      }
      .name {
        width: 0;
        flex-grow: 1;
      }
    }
  }
  .flow-wrap-active {
    height: 100%;
    position: relative;
    overflow: hidden;
    outline: none !important;
    flex-grow: 1;
    background-image: url('../assets/point.png');
    #flow {
      position: relative;
      width: 100%;
      height: 100%;
      .auxiliary-line-x {
        position: absolute;
        border: 0.5px dashed #2ab1e8;
        z-index: 9999;
      }
      .auxiliary-line-y {
        position: absolute;
        border: 0.5px dashed #2ab1e8;
        z-index: 9999;
      }
    }
  }
  .flow-wrap {
    height: 100%;
    position: relative;
    overflow: hidden;
    outline: none !important;
    flex-grow: 1;
    #flow {
      position: relative;
      width: 100%;
      height: 100%;
      .auxiliary-line-x {
        position: absolute;
        border: 0.5px dashed #2ab1e8;
        z-index: 9999;
      }
      .auxiliary-line-y {
        position: absolute;
        border: 0.5px dashed #2ab1e8;
        z-index: 9999;
      }
    }
  }
  .flow-wrap-active2 {
    height: 100%;
    position: relative;
    overflow: hidden;
    outline: none !important;
    flex-grow: 1;
    background-image: url('../assets/themeA.png');
    #flow {
      position: relative;
      width: 100%;
      height: 100%;
      .auxiliary-line-x {
        position: absolute;
        border: 0.5px dashed #2ab1e8;
        z-index: 9999;
      }
      .auxiliary-line-y {
        position: absolute;
        border: 0.5px dashed #2ab1e8;
        z-index: 9999;
      }
    }
  }
  .flow-wrap-active1 {
    height: 100%;
    position: relative;
    overflow: hidden;
    outline: none !important;
    flex-grow: 1;
    background-image: url('../assets/themeB.png');
    #flow {
      position: relative;
      width: 100%;
      height: 100%;
      .auxiliary-line-x {
        position: absolute;
        border: 0.5px dashed #2ab1e8;
        z-index: 9999;
      }
      .auxiliary-line-y {
        position: absolute;
        border: 0.5px dashed #2ab1e8;
        z-index: 9999;
      }
    }
  }
  .flow-wrap-active3 {
    height: 100%;
    position: relative;
    overflow: hidden;
    outline: none !important;
    flex-grow: 1;
    background-image: url('../assets/themeC.png');
    #flow {
      position: relative;
      width: 100%;
      height: 100%;
      .auxiliary-line-x {
        position: absolute;
        border: 0.5px dashed #2ab1e8;
        z-index: 9999;
      }
      .auxiliary-line-y {
        position: absolute;
        border: 0.5px dashed #2ab1e8;
        z-index: 9999;
      }
    }
  }
  .flow-wrap-active4 {
    height: 100%;
    position: relative;
    overflow: hidden;
    color: wheat !important;
    outline: none !important;
    flex-grow: 1;
    background-image: url('../assets/themeD.png');
    #flow {
      position: relative;
      width: 100%;
      height: 100%;
      .auxiliary-line-x {
        position: absolute;
        border: 0.5px dashed #2ab1e8;
        z-index: 9999;
      }
      .auxiliary-line-y {
        position: absolute;
        border: 0.5px dashed #2ab1e8;
        z-index: 9999;
      }
    }
  }
  .flow-wrap-active5 {
    height: 100%;
    position: relative;
    overflow: hidden;
    outline: none !important;
    flex-grow: 1;
    background-image: url('../assets/themeE.jpg');
    #flow {
      position: relative;
      width: 100%;
      height: 100%;
      .auxiliary-line-x {
        position: absolute;
        border: 0.5px dashed #2ab1e8;
        z-index: 9999;
      }
      .auxiliary-line-y {
        position: absolute;
        border: 0.5px dashed #2ab1e8;
        z-index: 9999;
      }
    }
  }
}
</style>

<style lang="less">
.jtk-connector.active {
  z-index: 9999;
  path {
    stroke: #58ea9e;
    stroke-width: 1.5;
    animation: ring;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    stroke-dasharray: 5;
  }
}
@keyframes ring {
  from {
    stroke-dashoffset: 50;
  }
  to {
    stroke-dashoffset: 0;
  }
}
</style>
