<template>
  <div sty>
    <!-- <h3>Welcome to KX manager system!</h3> -->
    <ConsoleCard style="margin-top:18px;" v-if="isShow"></ConsoleCard>
    <!-- <a-card
      style="width: 100%;margin-top:12px;"
      :bordered="false"
      :hoverable="true"
    >
      <p>Card content</p>
      <p>Card content</p>
      <p>Card content</p>
    </a-card> -->
    <a-card
      class="step_7"
      style="width:100%;margin-top:12px;"
      :bordered="false"
      :hoverable="true"
      :tab-list="tabListNoTitle"
      :active-tab-key="noTitleKey"
      @tabChange="key => onTabChange(key, 'noTitleKey')"
    >
      <p v-if="noTitleKey === 'flow'">
        <Count></Count>
      </p>
      <p v-else-if="noTitleKey === 'visits'">
        <CountLine />
        <!-- <a-empty /> -->
      </p>
      <p v-else-if="noTitleKey === 'osInfo'" class="osInfo">
        <a-progress
          type="dashboard"
          :stroke-color="{
            '0%': '#FF5500',
            '100%': '#108EE9'
          }"
          :percent="cpuInfo.idle | format_osInfo"
          ><template #format="percent">
            <span style="color: #515A6E;font-size:18px;">{{
              percent + '%'
            }}</span
            ><br /><br />
            <span style="color: #515A6E;font-size:14px;">CPU</span>
          </template></a-progress
        >
        <a-progress
          type="dashboard"
          :stroke-color="{
            '0%': '#F5222D',
            '100%': '#52C41A'
          }"
          :percent="memInfo.usageRate | format_osInfo"
          ><template #format="percent">
            <span style="color: #515A6E;font-size:18px;">{{
              percent + '%'
            }}</span
            ><br /><br />
            <span style="color: #515A6E;font-size:14px;">内存</span>
          </template></a-progress
        >
      </p>
    </a-card>
    <a-row :gutter="10">
      <a-col :span="17">
        <a-card
          style="width: 100%;height:24em;margin-top:12px;"
          :bordered="false"
          :hoverable="true"
        >
          <a-card
            class="card-top"
            style="width: 100%;height:20%;padding:0!important;margin:0;"
            :bordered="false"
          >
            <div class="name-info">
              <el-avatar :size="32" :src="circleUrl"></el-avatar>
              <span class="name">
                {{ 'Hi,' + name }}
              </span>
              <span class="intro-info"
                >欢迎您回到顺猴出行管理后台,对新版有什么体验（改善）的问题，您可以
                <a-button class="btn" type="link" @click="showWork">
                  提交工单
                </a-button></span
              >
            </div>
            <div>
              <span class="login-ip">
                上次登录信息（仅供参考） 地区：{{
                  ipInfo.region + '\xa0\xa0'
                }}
                ip：{{ ipInfo.ip + '\xa0\xa0' }} browser：{{
                  ipInfo.browser + '\xa0\xa0'
                }}
                os：{{ ipInfo.os + '\xa0\xa0' }} 登录方式：{{
                  ipInfo.type + '\xa0\xa0'
                }}&nbsp;&nbsp;&nbsp;&nbsp;时间：{{ ipInfo.time }}
                <a-button class="btn" type="link" @click="showLoginModal">
                  登录日志
                </a-button>
                <a-button class="btn" type="link" @click="showOptionalModal">
                  操作日志
                </a-button>
              </span>
            </div>
          </a-card>
          <a-card
            style="width: 100%;height:80%;border-top:1px solid rgba(157,157,157,0.3);"
            :bordered="false"
          >
            <a-row :gutter="10">
              <a-col :span="12">
                <div class="card-bottom">
                  <span
                    ><span style="font-size: 13px;color: #cecece;"
                      >提现总金额<i
                        style="margin-left:5px;"
                        class="fa fa-eye"
                        aria-hidden="true"
                        @click="see()"
                      ></i></span
                    ><br /><strong v-if="isEye" style="font-weight: bolder;"
                      >******</strong
                    ><strong v-else class="money">￥0.00</strong></span
                  >
                  <a-button class="btn1" type="link" size="small">
                    查看提现记录
                  </a-button>
                </div>
              </a-col>
              <a-col :span="12">
                <div>
                  <img src="../assets/gzh1.png" alt="" style="height:8em;" />
                </div>
              </a-col>
            </a-row>
          </a-card>
        </a-card>
        <a-row :gutter="10">
          <a-col :span="12">
            <a-card
              style="width: 100%;height:27em;margin-top:12px;padding:8px;"
              :bordered="false"
              :hoverable="true"
            >
              <CountZX></CountZX>
            </a-card>
          </a-col>
          <a-col :span="12">
            <a-card
              style="width: 100%;height:27em;margin-top:12px;"
              :bordered="false"
              :hoverable="true"
            >
              <relationGraph></relationGraph>
            </a-card>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="7">
        <a-card
          title="系统公告"
          style="width: 100%;height:20em;margin-top:12px;"
          :bordered="false"
          :hoverable="true"
          :headStyle="tstyle"
        >
          <!-- <img
            slot="cover"
            alt="example"
            src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
          /> -->
          <!-- <a slot="extra" href="#">more</a> -->
          <el-popover
            slot="extra"
            placement="left-start"
            title="发布公告"
            trigger="hover"
            style="margin-right:20px;"
            v-if="isShow"
          >
            <div style="width:320px;padding:20px;">
              <el-input
                onkeyup="return submit(event)"
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 6 }"
                placeholder="请输入公告内容"
                v-model="textarea"
              >
              </el-input>
              <el-button
                type="primary"
                size="mini"
                style="margin-top:10px;"
                @click="saveGg"
                >保存</el-button
              >
            </div>
            <el-button slot="reference" size="mini" plain
              >发布更新公告</el-button
            >
          </el-popover>
          <el-tooltip placement="top" slot="extra" effect="light">
            <div slot="content">
              这里是系统公告信息<br />最新公告信息可在此处查看
            </div>
            <i class="fa fa-info-circle" aria-hidden="true"></i>
          </el-tooltip>
          <p>
            <i
              style="margin-right:5px;"
              class="fa fa-paper-plane-o"
              aria-hidden="true"
            ></i
            >{{ this.textarea }}
          </p>
        </a-card>
        <a-card
          title="系统更新日志"
          style="width: 100%;height:26em;margin-top:12px;"
          :bordered="false"
          :hoverable="true"
          :headStyle="tstyle1"
        >
          <!-- <img
            slot="cover"
            alt="example"
            src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
          /> -->
          <!-- <a slot="extra" href="#">more</a> -->
          <el-tooltip placement="top" slot="extra" effect="light">
            <div slot="content">
              系统最新更新会在此处显示
            </div>
            <i class="fa fa-info-circle" aria-hidden="true"></i>
          </el-tooltip>
          <a-timeline>
            <a-timeline-item color="green">2022-02 V2迭代完成</a-timeline-item>
            <a-timeline-item color="green"
              >系统V1完成 2021-01-01</a-timeline-item
            >
            <a-timeline-item color="green"
              >可行性分析 2021-09-15</a-timeline-item
            >
            <a-timeline-item color="green">需求分析 2021-9-01</a-timeline-item>
          </a-timeline>
        </a-card>
      </a-col>
    </a-row>
    <!-- <p>
      下面为该账户的登录记录(仅显示最近5条,且只有用户名密码登录模式会被记录)
    </p>
    <div class="con">
      <Calendar
        class="con_1"
        v-on:choseDay="clickDay"
        v-on:changeMonth="changeDate"
      ></Calendar>
    </div> -->
    <!-- 查看登录日志对话框 -->
    <a-modal
      width="60%"
      v-drag-modal
      v-model="visible"
      title="登陆日志"
      @ok="handleOk"
    >
      <template slot="footer">
        <a-button
          style="background-color: #58d1ff;border:none;"
          type="primary"
          @click="handleOk"
        >
          关闭
        </a-button>
      </template>
      <a-alert type="info" message="注意：这里只显示最近登录5条" banner />
      <el-table :data="tableData" stripe border>
        <el-table-column type="index" width="50" label="#"> </el-table-column>
        <el-table-column prop="updateTime" label="登录时间"> </el-table-column>
        <el-table-column prop="username" label="登录用户"> </el-table-column>
        <el-table-column prop="ipAddress" label="ip地址"> </el-table-column>
        <el-table-column prop="browser" label="浏览器"> </el-table-column>
        <el-table-column prop="os" label="操作系统"> </el-table-column>
        <el-table-column prop="type" label="登录方式"> </el-table-column>
      </el-table>
    </a-modal>
    <!-- 提交工单对话框 -->
    <a-modal
      width="24%"
      v-drag-modal
      v-model="workVisible"
      title="提交工单"
      @ok="handleOk1"
    >
      <template slot="footer">
        <a-button
          style="background-color: #58d1ff;border:none;"
          type="primary"
          @click="handleOk1"
        >
          关闭
        </a-button>
      </template>
      <div style="width:380px;padding:20px;">
        <div style="display:flex;">
          <span>标题：</span
          ><el-input
            style="width:240px;"
            size="mini"
            type="text"
            placeholder=""
            v-model="workOrder.title"
            maxlength="30"
            show-word-limit
          >
          </el-input>
        </div>
        <div style="display:flex;">
          <span>内容：</span
          ><el-input
            style="width:240px;margin-top:12px;"
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 6 }"
            placeholder="请描述你的问题，管理会在当天处理回复"
            v-model="workOrder.content"
            maxlength="200"
            show-word-limit
          >
          </el-input>
        </div>
        <el-button
          type="primary"
          size="mini"
          style="margin-top:10px;"
          @click="saveWork"
          >提交</el-button
        >
      </div>
    </a-modal>
  </div>
</template>

<script>
import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
// import Calendar from 'vue-calendar-component'
import ConsoleCard from '@/components/home/ConsoleCard'
import Count from '@/components/count/Count'
import CountLine from '@/components/count/CountLine'
import CountZX from '@/components/count/CountZX'
import relationGraph from '@/components/count/relationGraph'
export default {
  name: 'Welcome',
  data() {
    return {
      workVisible: false,
      workOrder: {
        title: '',
        content: ''
      },
      textarea: '',
      isEye: true,
      visible: false, // 登陆日志对话框显示
      tableData: [],
      isShow: false,
      tabListNoTitle: [
        {
          key: 'flow',
          tab: '访问量'
        },
        {
          key: 'visits',
          tab: '流量趋势'
        },
        {
          key: 'osInfo',
          tab: '性能监控'
        }
      ],
      // key: 'tab1',
      noTitleKey: 'flow',
      circleUrl: '', // 头像url
      name: '',
      ipInfo: {
        region: '',
        ip: '',
        time: '',
        browser: '',
        os: '',
        type: ''
      },
      tstyle: { color: '#58d1ff' },
      tstyle1: { color: '#58d1ff' },
      cpuInfo: {},
      memInfo: {}
    }
  },
  methods: {
    showWork() {
      this.workVisible = true
    },
    async saveWork() {
      if (this.workOrder.title === '' || this.workOrder.content === '') {
        return this.$antMessage.info('提交标题内容不能为空')
      }
      const { data: res } = await this.$http.post('work/submit', this.workOrder)
      if (res.code !== '20000') {
        return this.$antMessage.error(res.message)
      }
      this.$antMessage.success('提交成功')
      this.workVisible = false
    },
    async getGg() {
      const { data: res } = await this.$http.get(`/xkeys/web/getGg`)
      console.log('------------')
      console.log(res)
      if (res.code !== '20000') {
        this.$message.error({
          showClose: true,
          message: '获取公告失败'
        })
        return
      }
      this.textarea = JSON.parse(res.data.msg).msg
      this.$message.success({
        showClose: true,
        message: '获取公告成功'
      })
    },
    async saveGg() {
      if (this.textarea.length === 0) {
        this.$message.info({
          showClose: true,
          message: '公告不能为空'
        })
        return
      }
      const { data: res } = await this.$http.put(`/xkeys/web/setGg`, {
        msg: this.textarea
      })
      console.log('------------')
      console.log(res)
      if (res.code !== '20000') {
        this.$message.error({
          showClose: true,
          message: '保存公告失败'
        })
        return
      }
      this.$message.success({
        showClose: true,
        message: '保存公告成功'
      })
    },
    websocketRun() {
      var token = window.sessionStorage.getItem('token')
      const sock = new SockJS(
        'https://api.shunhou.wandoukeji.com/tmax/ws?token=' + token
      )
      const client = Stomp.over(sock)
      client.debug = () => {}
      const that = this
      client.connect({ token: token }, () => {
        console.log('连接websocket成功111')
        // 连接成功后订阅消息接口
        // 订阅个人消息
        client.subscribe('/topic/monitoring', function(res) {
          const data = JSON.parse(res.body)
          // console.log('获取数据')
          that.memInfo = data.memInfo
          that.cpuInfo = data.cpuInfo
          // console.log('===================================')
          // console.log(that.circleUrl)
          // console.log(data.memInfo)
          // console.log(data.cpuInfo)
        })
      })
      setTimeout(() => {
        client.send('/app/top', {}, Math.random())
      }, 1000)
      this.timer = setInterval(() => {
        client.send('/app/top', {}, Math.random())
      }, 5000)
    },
    see() {
      this.isEye = !this.isEye
    },
    showLoginModal() {
      this.visible = true
    },
    showOptionalModal() {
      if (this.isShow) {
        this.$layer.msg('只有非最高管理员才有操作日志')
      } else {
      }
    },
    // 对话框关闭调用事件
    handleOk(e) {
      console.log(e)
      this.visible = false
    },
    handleOk1(e) {
      console.log(e)
      this.workVisible = false
    },
    onTabChange(key, type) {
      // eslint-disable-next-line eqeqeq
      if (key == 'osInfo') {
        this.websocketRun()
      } else {
        if (this.timer) {
          clearInterval(this.timer) // 在Vue实例销毁前，清除我们的定时器
        }
      }
      console.log(key, type)
      this[type] = key
    },
    async getAdminIp() {
      var name = window.sessionStorage.getItem('username')
      this.name = name
      const { data: res } = await this.$http.get(`/getAdminIpInfo/${name}`)
      console.log('------------')
      console.log(res)
      if (res.code !== '20000') {
        this.$message.error({
          showClose: true,
          message: '获取登录信息失败'
        })
        return
      }
      this.$message.success({
        showClose: true,
        message: '获取登录信息成功'
      })
      this.tableData = res.data.list
      try {
        var tmp = this.tableData[1] ? this.tableData[1] : this.tableData[0]
        var regrion = tmp.ipAddress.split('(')[1]
        var ip = tmp.ipAddress.split('(')[0]
        regrion = regrion.replace(')', '')
        this.ipInfo = {
          region: regrion,
          ip: ip,
          time: tmp.updateTime,
          browser: tmp.browser,
          os: tmp.os,
          type: tmp.type
        }
      } catch (err) {
        console.log(err)
      }
      const { data: res1 } = await this.$http.get(`/getAdminAvatar/${name}`)
      // eslint-disable-next-line eqeqeq
      if (res1.code == '20000') {
        this.circleUrl = res1.data.avatar
        // eslint-disable-next-line eqeqeq
        if (res1.data.rid == 1) {
          this.isShow = true
        }
      }
      // this.open()
    },
    open() {
      this.$notify({
        title: '🍄Tips',
        message: '💗欢迎使用本系统💗',
        position: 'bottom-right'
      })
    }
  },
  created() {
    this.getAdminIp()
    this.getGg()
  },
  mounted() {
    // this.websocketRun()
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer) // 在Vue实例销毁前，清除我们的定时器
    }
  },
  components: {
    // Calendar,
    ConsoleCard,
    Count,
    CountLine,
    CountZX,
    relationGraph
  },
  watch: {
    // 监听路由变化自动dispatch一个action
    $route() {
      if (this.timer) {
        clearInterval(this.timer) // 在Vue实例销毁前，清除我们的定时器
      }
    }
  },
  filters: {
    format_osInfo(value) {
      value = value + ''
      return parseFloat(value.replace('%', ''))
    }
  }
}
</script>

<style lang="less" scoped>
.con {
  display: flex;
}
.con_1 {
  margin-left: 18px;
}
.name-info {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}
.name {
  font-size: 24px;
  font-weight: bold;
  margin-left: 5px;
}
.intro-info {
  font-size: 14px;
  color: rgb(143, 134, 134);
  padding-top: 5px;
}
.btn {
  margin: 0px;
  color: #58d1ff;
  font-size: 13px;
  font-weight: bold;
}
.login-ip {
  height: 28px;
  font-size: 13px;
  color: #cecece;
  margin-top: 12px !important;
}
.card-bottom {
  height: 8em !important;
  display: flex;
  align-content: center !important;
  align-items: center !important;
  span {
    flex: 1;
  }
  .btn1 {
    font-size: 13px;
    color: #58d1ff;
    font-weight: bolder;
    flex: 1;
    width: 5em !important;
  }
}
.money {
  font-size: 32px;
  font-weight: bold;
}
.osInfo {
  padding-left: 10em;
  padding-right: 10em;
  display: flex;
  align-content: space-around;
  align-items: center;
  justify-content: space-around;
}
</style>
