<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>反馈信息</el-breadcrumb-item>
      <el-breadcrumb-item>用户反馈</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区 -->
    <el-card class="box-card">
      <!-- 搜索与添加区域 -->
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input
            placeholder="试试搜索 内容关键字"
            v-model="query"
            clearable
            @clear="getFeedBackList()"
            @keyup.enter.native="getFeedBackList()"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              :style="active"
              @mouseover.native="mouseOver()"
              @mouseout.native="mouseLeave()"
              @click="getFeedBackList()"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <!-- <el-button type="primary" @click="addDialogVisible = true"
            >添加用户</el-button
          > -->
        </el-col>
      </el-row>
      <el-divider><i class="fa fa-commenting-o"></i></el-divider>
      <div class="test test-1">
        <div
          class="InfoCard"
          v-for="k in lists"
          :key="k.id"
          @click="updateInfoStatus(k.id)"
        >
          <el-tooltip
            effect="light"
            content="点我查看所有图片"
            placement="top"
            :enterable="false"
          >
            <el-badge
              :value="k.status ? '已读' : '未读'"
              class="item"
              :type="k.status ? 'primary' : 'error'"
            >
              <el-image
                style="width: 168px; height: 168px;box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.7);"
                :src="k.pics | aa"
                :preview-src-list="k.pics | kk"
              >
              </el-image>
            </el-badge>
          </el-tooltip>
          <!-- <el-button style="float: left; font-size: 10px; padding-top: 3px;" type="text"
            >查看详情</el-button> -->
          <p style="font-size: 8px;" class="p_content">
            {{ k.content }}
          </p>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'Feedback',
  data() {
    return {
      query: '',
      active: '',
      lists: [],
      url:
        'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      srcList: [
        'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
        'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
      ]
    }
  },
  filters: {
    kk: function(data) {
      console.log(data)
      // eslint-disable-next-line eqeqeq
      if (data == '' || data == undefined || data == null) {
        return data
      }
      var newList = data.split(',')
      return newList.map(function(ele) {
        ele = 'https://api.shunhou.wandoukeji.com' + ele
        return ele
      })
    },
    aa: function(data) {
      console.log(data)
      // eslint-disable-next-line eqeqeq
      if (data == '' || data == undefined || data == null) {
        return data
      }
      var newList = data.split(',')
      var list = newList.map(function(ele) {
        ele = 'https://api.shunhou.wandoukeji.com' + ele
        return ele
      })
      return list[0]
    }
  },
  created() {
    this.getFeedBackList()
  },
  methods: {
    async updateInfoStatus(id) {
      const { data: res } = await this.$http.put(`getCount/${id}`)
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      this.getFeedBackList()
      // this.$message.info('设置已读')
    },
    mouseOver() {
      this.active = 'background-color: #ccc;'
      //   console.log('鼠标移动事件')
    },
    mouseLeave() {
      this.active = ''
    },
    async getFeedBackList() {
      const { data: res } = await this.$http.get('feedbackInfo', {
        params: {
          query: this.query
        }
      })
      if (res.code !== '20000') {
        return this.$message.error('获取用户反馈信息失败')
      }
      console.log(res)
      // this.userlist = res.data.lists
      // this.total = Number(res.data.total)
      this.lists = res.data.lists
      this.$message.success('获取info信息成功')
    }
  }
}
</script>

<style lang="less" scoped>
.InfoCard {
  border: 2px solid #ebeef5;
  padding: 6px;
  width: 168px;
  height: 246px;
  margin: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  transform: scale(1, 1);
  transition: all 500ms ease;
  background-image: url(../../assets/bk_f.jpg);
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
  .p_content {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
  }
}

.InfoCard:hover {
  transform: scale(1.1, 1.1);
  z-index: 3;
}

.test {
  height: 500px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  margin: 2px;
  border: none;
  float: left;
}
</style>
