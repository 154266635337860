<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统设置</el-breadcrumb-item>
      <el-breadcrumb-item>常规设置</el-breadcrumb-item>
    </el-breadcrumb>

    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="登录设置" name="first">
        <span class="ziti">登录背景图修改</span>
        <el-upload
          class="upload-demo"
          drag
          :action="uploadURL"
          :on-success="handleSuccess"
          :on-remove="handleRemove"
          :headers="headersObj"
          multiple
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传jpg/png文件，且不超过5M
          </div>
        </el-upload>
        <img v-show="src" :src="src" class="bkimg" />
        <br />
        <el-button type="primary" @click="save" style="margin-top: 10px;"
          >保存修改</el-button
        >
        <br />
        <br />
        <span class="ziti">登录logo修改</span>
        <el-upload
          class="upload-demo"
          drag
          :action="uploadURL"
          :on-success="handleSuccess_1"
          :on-remove="handleRemove_1"
          :headers="headersObj"
          multiple
          :before-upload="beforeAvatarUpload"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传jpg/png文件，且不超过1M
          </div>
        </el-upload>
        <img v-show="src_1" :src="src_1" class="bkimg" />
        <br />
        <el-button type="primary" @click="save_1" style="margin-top: 10px;"
          >保存修改</el-button
        >
      </el-tab-pane>
      <el-tab-pane label="安全设置" name="second">
        <!-- 提示区域 -->
        <el-alert
          title="开启登录的验证方式，支持同时开启"
          type="info"
          center
          show-icon
          :closable="false"
          style="margin-bottom: 10px;"
        >
        </el-alert>
        <el-switch
          v-model="value1"
          inactive-text="用户名密码登录"
          style="margin-bottom: 20px;"
          @change="handlevalue1"
        >
        </el-switch>
        <el-switch
          style="display: block;margin-bottom: 20px;"
          v-model="value2"
          inactive-text="微信扫码登录"
          @change="handlevalue2"
        >
        </el-switch>
      </el-tab-pane>
      <el-tab-pane label="其他管理" name="third">角色管理</el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Qs from 'qs'
export default {
  data() {
    return {
      value1: true,
      value2: true,
      src: '',
      src_1: '',
      uploadURL: 'https://api.shunhou.wandoukeji.com/api/v1/uploadImage',
      headersObj: {
        token: window.sessionStorage.getItem('token')
      },
      activeName: 'first'
    }
  },
  methods: {
    async handlGet() {
      const { data: res } = await this.$http.get(`/xkeys/login/get/1`)
      if (res.code !== '20000') {
        this.$message.error(res.message)
      } else {
        // eslint-disable-next-line eqeqeq
        if (res.data.value1 == 1) {
          this.value1 = true
        } else {
          this.value1 = false
        }
      }
      const { data: res1 } = await this.$http.get(`/xkeys/login/get/2`)
      if (res1.code !== '20000') {
        this.$message.error(res.message)
      } else {
        // eslint-disable-next-line eqeqeq
        if (res1.data.value2 == 1) {
          this.value2 = true
        } else {
          this.value2 = false
        }
      }
    },
    async handlevalue1() {
      const { data: res } = await this.$http.post(
        `/xkeys/login/1/${this.value1}`
      )
      if (res.code === '20000') {
        this.$message.success(res.message)
      } else {
        this.$message.error(res.message)
      }
    },
    async handlevalue2() {
      const { data: res } = await this.$http.post(
        `/xkeys/login/2/${this.value2}`
      )
      if (res.code === '20000') {
        this.$message.success(res.message)
      } else {
        this.$message.error(res.message)
      }
    },
    handleSuccess(response) {
      // 1.先拼接得到一个图片信息对象
      if (response.data.path !== '') {
        this.src = response.data.path
      }
    },
    handleRemove(file) {
      this.src = ''
    },
    handleSuccess_1(response) {
      // 1.先拼接得到一个图片信息对象
      if (response.data.path !== '') {
        this.src_1 = response.data.path
      }
    },
    handleRemove_1(file) {
      this.src_1 = ''
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 1

      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!')
      // }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isLt2M
    },
    async save() {
      // eslint-disable-next-line eqeqeq
      if (this.src == '') {
        this.$message.info('请先上传图片')
        return
      }
      const { data: res } = await this.$http.put(
        'bkImg',
        Qs.stringify({
          src: this.src
        })
      )
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      this.$message.success(res.message)
    },
    async save_1() {
      // eslint-disable-next-line eqeqeq
      if (this.src_1 == '') {
        this.$message.info('请先上传图片')
        return
      }
      const { data: res } = await this.$http.put(
        'bkLogo',
        Qs.stringify({
          src: this.src_1
        })
      )
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      this.$message.success(res.message)
    },
    handleClick(tab, event) {
      console.log(tab.name)
    }
  },
  mounted() {
    this.handlGet()
  }
}
</script>

<style lang="less" scoped>
.ziti {
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
    'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
  margin: 8px !important;
  font-weight: bold;
}
.upload-demo {
  margin-top: 5px;
}

.bkimg {
  height: 180px;
  width: 360px;
}
</style>
