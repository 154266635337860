/* eslint-disable no-undef */ /* eslint-disable no-use-before-define */ /*
eslint-disable no-use-before-define */
<template>
  <el-container class="home-container" id="step_0">
    <!-- 侧边栏 -->
    <div v-if="!showHome">
      <el-aside
        id="step_1"
        v-if="!switchAside"
        :width="isCollapse ? '60px' : '200px'"
      >
        <div>
          <img
            src="../assets/shlogo.png"
            alt=""
            style="height: 60px;margin-left:9px;"
          />
        </div>
        <!-- <div class="toggle-button" @click="toggleCollapse">S
        <div v-if="hindActive">☘️👈☘️</div>
        <div v-else>☘️👉☘️</div>
      </div>
      -->
        <!-- 侧边栏菜单区域 -->
        <el-menu
          background-color="#fafafa"
          text-color="#000000"
          active-text-color="#0066EF"
          unique-opened
          :collapse="isCollapse"
          :collapse-transition="false"
          router
          :default-active="activePath"
        >
          <!-- 一级菜单 -->
          <el-submenu
            :index="item.id + ''"
            v-for="item in menulist"
            :key="item.id"
          >
            <!-- 一级菜单的模板区域 -->
            <template slot="title">
              <i :class="iconsObj[item.id]"></i>
              <span>
                <el-badge
                  :value="item.id == 15 ? feedBackCount : null"
                  class="item"
                >
                  {{ item.name }}
                </el-badge></span
              >
            </template>

            <!-- 二级菜单 -->
            <el-menu-item
              :index="'/' + subItem.path"
              v-for="subItem in item.children"
              :key="subItem.id"
              @click="saveNavState('/' + subItem.path)"
            >
              <i :class="subItem.icon"></i>
              <span>{{ subItem.name }}</span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
    </div>

    <!-- 页面主体区域 -->
    <el-container v-if="!showHome">
      <!-- 头部区域布局1-->
      <el-header>
        <!-- <div class="header-btn_2">{{ dateFormat(date) }}</div> -->
        <span class="header-btn_1" @click="toggleCollapse">
          <i :class="outdent"
            ><font style="font-size:14px;">&nbsp;v2.0.0</font></i
          >
        </span>
        <div class="head-tabr" v-if="isShow">
          <span
            v-for="item in menulists"
            :key="item.id"
            :class="item.path"
            @click="handleMenu(item.id)"
            ><i :class="item.icon + ' ' + item.level" aria-hidden="true">{{
              item.name
            }}</i></span
          >
          <!-- <span
            ><i class="fa fa-heartbeat" aria-hidden="true">系统监控</i></span
          >
          <span
            ><i class="fa fa-eercast" aria-hidden="true">小程序管理</i></span
          >
          <span
            ><i class="fa fa-line-chart" aria-hidden="true">统计报表</i></span
          > -->
        </div>

        <div class="head_search">
          <!-- <a-input-search
            id="step_2"
            class="h_search"
            placeholder="搜索API"
            allowClear
            style="width: 200px;"
            @search="onSearch"
          /> -->
          <a-select
            class=""
            id="step_2"
            show-search
            placeholder="搜索API"
            option-filter-prop="children"
            style="width: 200px"
            allowClear
            :filter-option="filterOption2"
            @focus="handleFocus2"
            @blur="handleBlur2"
            @change="handleChange2"
          >
            <a-icon slot="suffixIcon" type="search" />

            <a-select-option
              v-for="item in searchList"
              :key="item.id"
              :value="item.path"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <!--全屏显示-->
        <div class="head_middle" id="step_3" v-if="isShow">
          <workOrder :isShow="isShow"></workOrder>
          <span class="header-wo">
            <i class="fa fa-bell-o"></i>
            <font>&nbsp;发布消息</font>
          </span>
          <span class="header-wo" @click="handleFullScreen">
            <i class="fa fa-arrows-alt" v-if="!fullscreen"></i>
            <i class="fa fa-times" v-else></i>
          </span>
        </div>
        <div class="head_middle" id="step_3" v-else>
          <workOrder :isShow="isShow"></workOrder>
          <span class="header-wo" @click="websocketStart">
            <i class="fa fa-bell-o"></i>
            <font>&nbsp;消息通知</font>
          </span>
          <span class="header-wo" @click="handleFullScreen">
            <i class="fa fa-arrows-alt" v-if="!fullscreen"></i>
            <i class="fa fa-times" v-else></i>
          </span>
        </div>
        <div class="head_right" id="step_4">
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              <el-avatar :size="50" :src="circleUrl"></el-avatar>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="0"
                ><i class="el-icon-lock"></i>锁定屏幕</el-dropdown-item
              >
              <el-dropdown-item command="1"
                ><i class="el-icon-edit"></i>编辑资料</el-dropdown-item
              >
              <el-dropdown-item command="2"
                ><i class="el-icon-setting"></i>安全选项</el-dropdown-item
              >
              <el-dropdown-item command="3"
                ><i class="el-icon-switch-button"></i>退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <!-- 右侧内容主体 -->
      <el-main id="loadmore">
        <div class="fixBar step_6">
          <ToolBar @childEvent="parentEvent" :class="aaa"></ToolBar>
        </div>

        <a-spin :spinning="spinning" :tip="tips">
          <keep-alive :include="cachedViews">
            <router-view
              :class="ccc"
              style="padding-top:10px;padding-left:20px;padding-right:20px;"
            />
          </keep-alive>
        </a-spin>
      </el-main>
    </el-container>

    <el-aside v-if="switchAside" :width="isCollapse ? '60px' : '200px'">
      <div>
        <img
          src="../assets/WD_4.png"
          alt=""
          style="height: 60px;margin-left:9px;"
        />
      </div>
      <!-- <div class="toggle-button" @click="toggleCollapse">S
        <div v-if="hindActive">☘️👈☘️</div>
        <div v-else>☘️👉☘️</div>
      </div>
      -->
      <!-- 侧边栏菜单区域 -->
      <el-menu
        background-color="#fafafa"
        text-color="#000000"
        active-text-color="#0066EF"
        unique-opened
        :collapse="isCollapse"
        :collapse-transition="false"
        router
        :default-active="activePath"
      >
        <!-- 一级菜单 -->
        <el-submenu
          :index="item.id + ''"
          v-for="item in menulist"
          :key="item.id"
        >
          <!-- 一级菜单的模板区域 -->
          <template slot="title">
            <i :class="iconsObj[item.id]"></i>
            <span>
              <el-badge
                :value="item.id == 15 ? feedBackCount : null"
                class="item"
              >
                {{ item.name }}
              </el-badge></span
            >
          </template>

          <!-- 二级菜单 -->
          <el-menu-item
            :index="'/' + subItem.path"
            v-for="subItem in item.children"
            :key="subItem.id"
            @click="saveNavState('/' + subItem.path)"
          >
            <i :class="subItem.icon"></i>
            <span>{{ subItem.name }}</span>
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </el-aside>
    <float-icons
      v-if="!showHome"
      scoller="loadmore"
      padding="10 10 60 10"
      class="icons-warp step_5"
    >
      <a-tooltip id="qr" placement="left">
        <template slot="title">
          <div class="qr">绑定微信登录</div>
        </template>
        <div class="float-icon-item" @click="bindWx()">
          <img src="../assets/images/qr.png" alt="" />
        </div>
      </a-tooltip>
      <!-- -->
      <a-tooltip id="qr" placement="left">
        <template slot="title">
          <span>打开设置面板</span>
        </template>
        <div class="float-icon-item">
          <img
            src="../assets/images/setting.png"
            alt=""
            @click="handleIcons('cart')"
          />
        </div>
      </a-tooltip>
      <div
        id="qr"
        class="float-icon-item go-top"
        v-if="btnFlag"
        @click="backTop"
      >
        <img src="../assets/images/top.png" alt="" @click="backTop()" />
      </div>
    </float-icons>
    <el-drawer
      title="设置面板"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      :size="'20%'"
    >
      <div style="margin-left:30px;">
        <span style="margin-bottom:8px;">固定标签页(ToolBar)</span><br />
        <el-switch
          v-model="fixToolbar"
          active-text="固定"
          inactive-text="不固定"
          @change="fixTab"
        >
        </el-switch
        ><br /><br />
        <span style="margin-bottom:8px;">夜间模式</span><br />
        <el-switch v-model="showModal" active-text="开启" inactive-text="关闭">
        </el-switch>
        <br /><br />
        <span style="margin-bottom:8px;">侧边栏位置</span><br />
        <el-switch v-model="switchAside" active-text="右" inactive-text="左">
        </el-switch>
        <br /><br />
        <span style="margin-bottom:8px;">开启网页水印</span><br />
        <el-switch
          v-model="switchWaterMark"
          active-text="开启"
          inactive-text="关闭"
          @change="setWaterMark"
        >
        </el-switch>
      </div>
    </el-drawer>
    <!-- 夜间模式遮罩层 -->
    <div v-if="showModal" class="mask">
      <!-- <span class="mask-text">处理中，请等待...</span> -->
    </div>
    <!-- 锁屏遮罩层 -->
    <div v-if="showHome" class="lockScreen">
      <p style="color:red;">{{ lockMsg }}</p>
      <a-input-search
        type="password"
        placeholder="请输入密码解锁屏幕"
        size="large"
        @search="unlockscreen"
        class="unlockscr"
      >
        <a-button slot="enterButton" type="primary">
          <a-icon type="unlock" />
        </a-button>
      </a-input-search>
    </div>
    <!-- 旋转图片验证对话框 -->
    <el-dialog
      :visible.sync="rotateImg"
      width="20%"
      :before-close="rotateImgClose"
    >
      <dragVerifyImgRotate
        ref="validImg"
        :imgsrc="imgs"
        :isPassing.sync="isPassing"
        :showTips="true"
        handlerBg="#58D1FF"
        text="请按住滑块拖动"
        successText="验证通过"
        handlerIcon="el-icon-d-arrow-right"
        successIcon="el-icon-circle-check"
        @refresh="reimg"
        @passcallback="pass"
        @passfail="fail"
      >
      </dragVerifyImgRotate>
    </el-dialog>
    <!-- 绑定微信登录对话框 -->
    <a-modal
      v-drag-modal
      v-model="bindWxLoginModal"
      title="微信登录绑定"
      okText="激活登录"
      cancelText="取消激活"
      @ok="handleWxLoginOk"
    >
      <a-alert
        message="微信扫码成功手机点击确认后，账户绑定手机号会收到验证码，使用验证码（5分钟有效期）激活微信登录，否侧后台仍然无法使用微信扫码登录"
        type="info"
      />
      <div id="wxlogin" :class="wxloginstyle"></div>
      <a-input allowClear placeholder="请输入激活码" v-model="verifyWxCode" />
    </a-modal>
  </el-container>
</template>

<script>
import Push from 'push.js'
import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
import Cookies from 'js-cookie'
import formComp from '@/components/user/AdminInfo'
import formComp1 from '@/components/user/AdminUpdatePwd'
import ToolBar from '@/components/ToolBar'
import workOrder from '@/components/common/workOrder'
import FloatIcons from '@/components/s-icons'
import waterMark from '../plugins/waterMark'
import dragVerifyImgRotate from 'vue-drag-verify-img-rotate'
// import darkmodejs from '@assortment/darkmodejs'
// import Darkmode from 'darkmode-js'
import $ from 'jquery'
import introJs from 'intro.js'
// eslint-disable-next-line object-curly-spacing
import { setTheme, defaultTheme } from '@/assets/theme/setTheme.js'
export default {
  name: 'Home',
  data() {
    return {
      isShow: false,
      lockMsg: '',
      showHome: false,
      // 左侧菜单数据
      menulist: [],
      menulists: [],
      iconsObj: {
        1: 'fa fa-user fa-lg',
        2: 'fa fa-cube fa-lg',
        3: 'fa fa-shopping-bag fa-lg',
        4: 'fa fa-wrench fa-lg',
        5: 'fa fa-cog fa-lg',
        6: 'fa fa-bar-chart-o fa-lg',
        15: 'fa fa-bell fa-lg',
        17: 'fa fa-file-text-o fa-lg',
        19: 'fa fa-clock-o fa-lg',
        22: 'fa fa-dashboard fa-lg',
        32: 'fa fa-gg-circle fa-lg',
        34: 'fa fa-file-o fa-lg',
        40: 'fa fa-send-o fa-lg'
      },
      isCollapse: false,
      activePath: '',
      name: '',
      hindActive: true,
      date: new Date(),
      circleUrl: '',
      feedBackCount: 0,
      fullscreen: false,
      outdent: 'el-icon-s-fold',
      btnFlag: false,
      spinning: false, // 加载中
      tips: '加载中...',
      drawer: false,
      direction: 'ltr',
      fixToolbar: false,
      showModal: false,
      aaa: 'bbb', // 固定toolbar设置
      ccc: '', // 固定toolbar设置
      switchAside: false,
      switchWaterMark: false,
      searchList: [], // 搜索存储放入value
      rotateImg: false,
      isPassing: false,
      bindWxLoginModal: false,
      verifyWxCode: '',
      wxloginstyle: '',
      imgs:
        'https://api.shunhou.wandoukeji.com/upimg/images/verify/' +
        Math.floor(Math.random() * (308 - 1) + 1) +
        '.jpg',
      wxStatus: false
    }
  },
  async created() {
    this.activePath = window.sessionStorage.getItem('activePath')
    var name = window.sessionStorage.getItem('username')
    this.name = name
    await this.getIsShowHome(name)
    this.getMenuList()
    // this.getUserName()
    this.getFeedBackCount()
    const { data: res } = await this.$http.get(`/getAdminAvatar/${name}`)
    if (res.code !== '20000') {
      return
    }
    this.circleUrl = res.data.avatar
  },
  computed: {
    cachedViews() {
      return this.$store.state.cacheView
    }
  },
  mounted() {
    var cook = Cookies.get('guide')
    // eslint-disable-next-line eqeqeq
    if (cook == 'yes') {
    } else {
      Cookies.set('guide', 'yes', { expires: 3 })
      this.setGuide()
    }

    const sc = document.querySelector('#loadmore')
    sc.addEventListener('scroll', this.scrollToTop)
    const _this = this // 声明一个变量指向Vue实例this，保证作用域一致
    // this.timer = setInterval(() => {
    //   _this.date = new Date() // 修改数据date
    // }, 1000)
    this.spinning = !this.spinning
    setTimeout(() => {
      this.spinning = !this.spinning
    }, 600)
    // 初始化微信js
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js'
    document.body.appendChild(script)
    if (this.client === null || this.client === undefined) {
      console.log('初始化ws')
      // ws 初始化
      this.websocketStart()
    }
  },
  beforeDestroy() {
    console.log('销毁---')
    if (this.client !== null) {
      // ws 初始化
      this.client.disconnect(() => {
        console.log('断开ws')
      })
    }
    if (this.timer) {
      clearInterval(this.timer) // 在Vue实例销毁前，清除我们的定时器
    }
    const sc = document.querySelector('#loadmore')
    if (sc) {
      sc.removeEventListener('scroll', this.scrollToTop)
    }
  },
  components: {
    ToolBar,
    'float-icons': FloatIcons,
    dragVerifyImgRotate,
    workOrder
  },
  // beforeDestroy() {
  //   bus.$off('name')
  // },
  methods: {
    async handleWxLoginOk(e) {
      console.log('------------------', this.verifyWxCode)
      if (this.verifyWxCode === '') {
        return this.$antMessage.info('激活码不能为空')
      }
      const { data: res } = await this.$http.get(
        `/admins/verifyWxBindCode/${this.name}/${this.verifyWxCode}`
      )
      // eslint-disable-next-line eqeqeq
      if (res.code == '20000') {
        this.visible = false
        return this.$antMessage.success(res.message)
      } else {
        this.visible = false
        return this.$antMessage.error(res.message)
      }
    },
    reimg(value) {
      console.log('这里是刷新', value)
    },
    bindWx() {
      if (this.wxStatus) {
        return this.$antMessage.info('你已经绑定过了，不能再次操作')
      }
      this.rotateImg = true
      // eslint-disable-next-line eqeqeq
      if (this.$refs.validImg != undefined) {
        // this.$refs.validImg.reimg()
        this.imgs =
          'https://api.shunhou.wandoukeji.com/upimg/images/verify/' +
          Math.floor(Math.random() * (308 - 1) + 1) +
          '.jpg'
        // this.$refs.validImg.reset()
      }
    },
    async pass() {
      console.log('验证通过', this.isPassing)
      this.rotateImg = false
      this.bindWxLoginModal = true
      // 微信扫码绑定
      const { data: res } = await this.$http.get('wd/getLoginParam')
      console.log(res)
      // eslint-disable-next-line eqeqeq
      if (res.code == '20000') {
        // eslint-disable-next-line no-undef
        var obj = new WxLogin({
          self_redirect: true,
          id: 'wxlogin', // 需要显示的容器id
          appid: res.data.appid,
          scope: res.data.scope, // 网页默认即可
          redirect_uri: res.data.redirectUri, // 授权成功后回调的url
          state: this.name, // 可设置为简单的随机数加session用来校验
          style: 'black', // 提供"black"、"white"可选。二维码的样式
          // href: 'https://api.shunhou.wandoukeji.com/qr.css' // 外部css文件url，需要https
          href: 'https://api.shunhou.wandoukeji.com/qr1.css' // 外部css文件url，需要https
        })
      }
    },
    fail() {
      if (!this.isPassing) {
        this.$antMessage.error('图片验证失败')
        this.rotateImg = false
      }
    },
    rotateImgClose(done) {
      done()
    },
    // 搜索相关开始------------------------------------
    handleChange2(value) {
      console.log(`selected ${value}`)
      this.$router.push(value)
    },
    handleBlur2() {
      console.log('blur')
    },
    handleFocus2() {
      console.log('focus')
    },
    filterOption2(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    // 搜索相关结束------------------------------------
    handleMenu(id) {
      console.log(id)
      this.menulists.forEach(tmp => {
        // eslint-disable-next-line eqeqeq
        if (tmp.id == id) {
          this.menulist = tmp.children
          tmp.path = 'head-tabr-active'
          tmp.level = ' i-active'
        } else {
          tmp.path = ''
          tmp.level = ''
        }
      })
    },
    async getIsShowHome(name) {
      const { data: res1 } = await this.$http.get(`/getAdminAvatar/${name}`)
      // eslint-disable-next-line eqeqeq
      if (res1.code == '20000') {
        this.circleUrl = res1.data.avatar
        this.wxStatus = res1.data.wxStatus
        // eslint-disable-next-line eqeqeq
        if (res1.data.rid == 1) {
          this.isShow = true
        }
      }
      const { data: res } = await this.$http.get(
        `/admins/getLockScreen/${name}`
      )
      console.log('------------', res)
      if (res.code !== '20000') {
        return true
      } else {
        this.showHome = true
        return false
      }
    },
    async unlockscreen(value) {
      const { data: res } = await this.$http.put(
        `/admins/verifyLockScreen/${this.name}/${value}`
      )
      if (res.code !== '20000') {
        this.lockMsg = res.message
        return false
      } else {
        this.showHome = false
        this.$message({
          type: 'success',
          message: res.message
        })
      }
    },
    setGuide() {
      // eslint-disable-next-line prefer-const
      let data = [
        {
          element: '#step_0',
          intro: '📢欢迎体验<font color="#0066EF">顺猴出行后台管理系统</font>💗'
          // position: 'right'
        },
        {
          element: '#step_1',
          intro:
            '这里是侧边栏<font color="#0066EF">菜单</font>区域，您可以在这里选择操作界面',
          position: 'right'
        },
        {
          element: '#step_2',
          intro:
            '这里是搜索，<font color="#0066EF">您可以在这里搜索菜单项</font>'
          // position: 'left'
        },
        {
          element: '#step_3',
          intro:
            '您可以在这里<font color="#0066EF">查看（操作）工单、消息以及开启（关闭）全屏</font>相关功能'
          // position: 'left'
        },
        {
          element: '#step_4',
          intro:
            '个人中心在这里，您可以在这里<font color="#0066EF">修改头像、密码以及设置锁屏</font>等'
          // position: 'left'
        },
        {
          element: '.step_5',
          intro:
            '您可以在这里<font color="#0066EF">扫码绑定微信登录</font>，默认只能通过用户名账号登录<br/>您也可以在这里<font color="#0066EF">设置布局、水印、开（关）夜间模式以及水印</font>等相关功能'
          // position: 'left'
        },
        {
          element: '.step_6',
          intro:
            '<font color="#0066EF">这里是标签页操作，您可以拖拽或是右键关闭一侧（或所有）。</font><br/><font size="3" color="#F1195C">开始您的体验吧！</font>'
          // position: 'left'
        },
        {
          element: '.step_7',
          intro: '<font color="#0066EF">立即开始体验吧！</font>'
          // position: 'left'
        }
      ]
      this.$intro()
        .setOptions({
          prevLabel: '上一步',
          nextLabel: '下一步',
          skipLabel: '跳过',
          doneLabel: '完成',
          // eslint-disable-next-line no-undef
          steps: data,
          exitOnEsc: true,
          scrollToElement: true /* 是否滑动到高亮的区域 */,
          showStepNumbers: false /* 是否显示说明的数据步骤 */,
          showProgress: false /* 是否显示进度条 */
        })
        .oncomplete(() => {
          // 点击结束按钮后执行的事件
          console.log('完成------------------')
        })
        .onexit(() => {
          console.log('跳过------------------')
          // 点击跳过按钮后执行的事件
        })
        .start()
    },
    onSearch(value) {
      console.log(value)
    },

    fixTab() {
      if (this.fixToolbar) {
        this.aaa = 'aaa'
        this.ccc = 'ccc'
      } else {
        this.aaa = 'bbb'
        this.ccc = ''
      }
    },
    setWaterMark() {
      if (this.switchWaterMark) {
        waterMark.set('顺猴出行')
      } else {
        waterMark.set('')
      }
    },
    handleClose(done) {
      done()
      this.$antMessage.info('已经退出设置')
      // this.$confirm('退出设置？')
      //   .then(_ => {
      //     done()
      //   })
      //   .catch(_ => {})
    },
    // 点击图片回到顶部方法，加计时器是为了过渡顺滑
    backTop() {
      const that = this
      const timer = setInterval(() => {
        const ispeed = Math.floor(-that.scrollTop / 5)
        const sc = document.querySelector('#loadmore')
        document.documentElement.scrollTop = document.body.scrollTop = sc.scrollTop =
          that.scrollTop + ispeed
        if (that.scrollTop === 0) {
          clearInterval(timer)
        }
      }, 16)
    },

    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop() {
      const that = this
      const sc = document.querySelector('#loadmore')
      const scrollTop = sc.scrollTop || 0
      // window.pageYOffset ||
      // document.documentElement.scrollTop ||
      // document.body.scrollTop
      that.scrollTop = scrollTop
      if (that.scrollTop > 0) {
        that.btnFlag = true
      } else {
        that.btnFlag = false
      }
    },
    handleIcons(params) {
      // this.showModal = !this.showModal
      this.drawer = true
    },
    // getUserName() {
    //   var self = this
    //   this.name = self.$parent.TestData
    //   this.$bus.on('name1', data => {
    //     self.$parent.TestData = data
    //     console.log('---------', data)
    //   })
    //   console.log(this.name, '========')
    // },
    logout() {
      window.sessionStorage.clear()
      this.$router.push('/login')
    },
    handleTheme() {
      console.log('================')
      $('.el-main').css({
        backgroundColor: '#303133'
      })
    },
    handleFullScreen() {
      const element = document.documentElement
      setTheme('gray')
      // 判断是否已经是全屏
      // 如果是全屏，退出
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
        console.log('已还原！')
      } else {
        // 否则，进入全屏
        if (element.requestFullscreen) {
          element.requestFullscreen()
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen()
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen()
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen()
        }
        console.log('已全屏！')
      }
      // 改变当前全屏状态
      this.fullscreen = !this.fullscreen
    },
    parentEvent(data) {
      // console.log(data)
      this.activePath = data
    },
    // 获取所有的菜单
    async getMenuList() {
      var name = window.sessionStorage.getItem('username')
      this.name = name
      const { data: res } = await this.$http.get(`menus/${name}`)
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      // eslint-disable-next-line eqeqeq
      if (res.code == '6001') {
        this.$router.push('/login')
        return
      }
      this.menulists = res.data.menus
      if (res.data.menus.length > 1) {
        this.menulist = res.data.menus[0].children
        res.data.menus[0].path = 'head-tabr-active'
        res.data.menus[0].level = 'i-active'
      } else {
        this.menulist = res.data.menus
      }
      this.getSearchList(res.data.menus)
      // console.log('==========<<<<', this.searchList)
    },
    getSearchList(obj) {
      obj.forEach(x => {
        if (
          // eslint-disable-next-line eqeqeq
          x.children.length != 0
        ) {
          this.getSearchList(x.children)
        } else {
          this.searchList.push(x)
        }
      })
    },
    async getFeedBackCount() {
      const { data: res } = await this.$http.get('getCount')
      if (res.code !== '20000') {
        return this.$message.error(res.message)
      }
      this.feedBackCount = res.data.count
    },
    // 点击按钮，折叠与展开
    toggleCollapse() {
      if (this.isCollapse) {
        this.outdent = 'el-icon-s-fold'
      } else {
        this.outdent = 'el-icon-s-unfold'
      }
      this.isCollapse = !this.isCollapse
      this.hindActive = !this.hindActive
    },
    // 保存连接的激活状态
    saveNavState(activePath) {
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
    },
    async handleCommand(command) {
      if (command === '3') {
        // 弹窗展示是否删除数据
        const confirmResult = await this.$confirm(
          '您确定要退出登录？',
          '提示',
          {
            confirmButtonText: '嗯',
            cancelButtonText: '算了',
            type: 'warning'
          }
        ).catch(err => err)

        if (confirmResult !== 'confirm') {
          return this.$message.info('已取消~')
        }
        window.sessionStorage.clear()
        this.$router.push('/login')
        // bus.$off('name1')
      } else if (command === '1') {
        this.$layer.iframe({
          content: {
            content: formComp,
            parent: this,
            data: { iframeData: this.iframeData }
          },
          area: ['900px', '600px'],
          title: '编辑资料',
          maxmin: true,
          shade: false,
          shadeClose: false,
          cancel: () => {
            // 关闭弹窗事件
            // alert('关闭iframe')
          }
        })
      } else if (command === '2') {
        this.$layer.iframe({
          content: {
            content: formComp1,
            parent: this,
            data: { iframeData: this.iframeData }
          },
          area: ['900px', '600px'],
          title: '修改密码',
          maxmin: true,
          shade: false,
          shadeClose: false,
          cancel: () => {
            // 关闭弹窗事件
            // alert('关闭iframe')
          }
        })
      } else if (command === '0') {
        this.$prompt('请设置锁屏密码', '设置锁屏密码', {
          confirmButtonText: '保存',
          cancelButtonText: '取消',
          inputPattern: /^[0-9]\d{3}$/,
          inputErrorMessage: '密码格式必须是4位纯数字'
        })
          .then(async ({ value }) => {
            const { data: res } = await this.$http.put(
              `/admins/setLockScreen/${this.name}/${value}`
            )
            if (res.code !== '20000') {
              this.$message({
                type: 'error',
                message: res.message
              })
              return false
            } else {
              this.showHome = true
              this.$message({
                type: 'success',
                message: res.message
              })
              return false
            }
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '你取消了设置锁屏操作'
            })
          })
        // this.showHome = true
      }
    },
    dateFormat(time) {
      var date = new Date(time)
      var year = date.getFullYear()
      /* 在日期格式中，月份是从0开始的，因此要加0
       * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
       * */
      var month =
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1
      var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      var minutes =
        date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      var seconds =
        date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      // 拼接
      return (
        year +
        '-' +
        month +
        '-' +
        day +
        ' ' +
        hours +
        ':' +
        minutes +
        ':' +
        seconds
      )
    },
    // websocket
    websocketStart() {
      var token = window.sessionStorage.getItem('token')
      var username = window.sessionStorage.getItem('username')
      // const sock = new SockJS('http://127.0.0.1:8081/notify/ws?token=' + token)
      const sock = new SockJS('https://api.shunhou.wandoukeji.com/notify/ws?token=' + token)
      var client = Stomp.over(sock)
      this.client = client
      client.debug = () => {}
      const that = this
      client.connect({ token: token }, () => {
        console.log('连接websocket成功')
        // 连接成功后订阅消息接口
        // 订阅个人消息
        client.subscribe('/user/queue/message', function(res) {
          const data = JSON.parse(res.body)
          console.log('获取数据' + data.code)
          // eslint-disable-next-line eqeqeq
          if (data.code == 200) {
            Push.Permission.request()
            // eslint-disable-next-line eqeqeq
            console.log(data.data.type == 'offline')
            console.log(data.data.type)
            // eslint-disable-next-line eqeqeq
            if (data.data.type == 'offline') {
              Push.create('顺猴出行下线通知', {
                // body 选项是通知的内容
                body: '你被管理员强制下线！',
                // icon 选项是通知的图片
                icon:
                  'http://api.shunhou.wandoukeji.com/upimg/images/f02b8248e08b47ab85cef75c2df3dd8c.jpg',
                data: '123',
                // timeout 选项是通知停留时间
                timeout: 5000
              })
              that.logout()
              // eslint-disable-next-line eqeqeq
            } else if (data.data.type == 'msg') {
              Push.create('顺猴出行通知', {
                // body 选项是通知的内容
                body: data.message,
                // icon 选项是通知的图片
                icon:
                  'http://api.shunhou.wandoukeji.com/upimg/images/f02b8248e08b47ab85cef75c2df3dd8c.jpg',
                data: '123',
                // timeout 选项是通知停留时间
                timeout: 8000
              })

              that.$notify({
                title: '🍄管理员通知',
                message: '💗' + data.message + '💗',
                position: 'bottom-right'
              })
            }
          }
          // console.log('===================================')
          // console.log(that.circleUrl)
          // console.log(data.memInfo)
          // console.log(data.cpuInfo)
        })
      })
      // setTimeout(() => {
      //   client.send('/app/top', {}, Math.random())
      // }, 1000)
      // this.timer = setInterval(() => {
      //   client.send('/app/top', {}, Math.random())
      // }, 5000)
    }
  },
  watch: {
    // 监听路由变化自动dispatch一个action
    $route() {
      // eslint-disable-next-line dot-notation
      const componentName = this.$route.matched[1]['components']['default'][
        // eslint-disable-next-line dot-notation
        'name'
      ]
      // eslint-disable-next-line dot-notation
      const name = this.$route.meta['title']
      this.tips = name + '正在加载...'
      this.spinning = !this.spinning
      setTimeout(() => {
        this.spinning = !this.spinning
      }, 600)
    }
  }
}
</script>

<style lang="less" scoped>
.item {
  line-height: 24px;
}
.home-container {
  height: 100%;
}

.header-btn_2 {
  position: absolute;
  right: calc(50% + 200px);
  font-weight: normal;
  font-size: 16px;
  cursor: pointer;
}
.header-btn_3 {
  position: absolute;
  right: 70px;
  cursor: pointer;
}
.el-header {
  border-left: 1px solid #ccc;
  background-image: linear-gradient(to right, #ffffff, #fcfcfc);
  display: flex;
  position: relative;
  padding-left: 5px;
  align-items: center;
  color: #000;
  font-size: 20px;
}

.el-aside {
  background-image: linear-gradient(to bottom, #ffffff, #fffdfd);
  .el-menu {
    border-right: none;
    font-weight: bold;
  }
}

.el-main {
  background-color: #f7f9ff;
  padding-top: 1px;
  padding-left: 0px;
  padding-right: 0px;
  position: relative;
}
.aaa {
  background-color: #f7f9ff !important;
  margin-bottom: 3px;
  margin-right: 0px !important;
  width: 100%;
  position: fixed;
  z-index: 999;
}
.bbb {
  margin-bottom: 3px;
  margin-right: 0px !important;
}
.ccc {
  padding-top: 42px !important;
}

.fa {
  margin-right: 10px;
}

.toggle-button {
  background-color: #e8e2e2;
  color: #fff;
  font-size: 10px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}
.el-submenu {
  margin-right: 0px;
}
.head_right {
  display: flex;
  width: 70px;
  align-content: center;
  justify-content: center;
  position: absolute;
  right: 5px;
}
.head_middle {
  width: 266px;
  border-left: 2px solid rgba(194, 190, 190, 0.5);
  border-right: 2px solid rgba(194, 190, 190, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  right: 80px;
  span {
    padding-left: 0px;
  }
  img {
    /*border-radius: 50%;*/
  }
}
.head_search {
  width: 240px;

  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  right: 346px;
  span {
    padding-left: 0px;
  }
}
.ant-input-search .ant-input-affix-wrapper {
  radius: 50% !important;
}
.header-btn {
  margin-left: 5px;
  cursor: pointer;
  color: rgba(83, 81, 81, 0.8);
}
.header-btn_1 {
  margin-left: 5px;
  cursor: pointer;
  color: rgba(83, 81, 81, 0.8);
}
.header-wo {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  cursor: pointer;
  color: rgba(83, 81, 81, 0.8);
  vertical-align: middle;
  font-size: 15px;
}
.icons-warp {
  background: transparent;
  border-radius: 25px;
  .float-icon-item {
    box-shadow: 0px 2px 10px 0px rgba(198, 198, 198, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 50px;
    height: 50px;
    img {
      width: 30px;
      height: 30px;
      margin-bottom: 4px;
    }
    span {
      font-size: 9px;
      color: #666666;
    }
  }
}
.float-icon-item {
  cursor: pointer;
  background-color: #e4e4e4;
  border-radius: 25px;
  margin-top: 6px;
  span {
  }
}
.mask {
  pointer-events: none;
  background-color: rgb(0, 0, 0);
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
#qr {
  background-color: #666666;
}
.qr {
  /*width: 120px !important;
  height: 120px !important;*/
}
.lockScreen {
  background-color: rgba(234, 150, 206, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-image: url(../assets/bg-01.jpg) !important;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.unlockscr {
  width: 20%;
}
.unlockIcon {
  background-color: #58d1ff;
}
.head-tabr {
  margin-left: 20px;
  height: 60px;
  span {
    text-align: center;
    cursor: pointer;
    margin-right: 6px;
    font-size: 15px;
    color: #bebebe;
    line-height: 60px;
    padding-bottom: 0.6em;
  }
}
.head-tabr-active {
  border-bottom: 3px solid rgba(0, 102, 239, 0.7);
}
.i-active {
  color: rgba(0, 102, 239, 0.7);
}
.el-dialog__body {
  background-color: #3a414a !important;
}
#wxlogin {
  height: 320px;
}
.wxloginstyle {
  display: none !important;
}
.login_container {
  display: none !important;
}
</style>
